import enUs from './strings-en-US.json';
import deDe from './strings-de-DE.json';
import enGb from './strings-en-GB.json';
import enIn from './strings-en-IN.json';
import esEs from './strings-es-ES.json';
import esMx from './strings-es-MX.json';
import frCa from './strings-fr-CA.json';
import frFr from './strings-fr-FR.json';
import itIt from './strings-it-IT.json';
import jaJp from './strings-ja-JP.json';
import koKr from './strings-ko-KR.json';
import nlNl from './strings-nl-NL.json';
import ptBr from './strings-pt-BR.json';
import trTr from './strings-tr-TR.json';
import zhCn from './strings-zh-CN.json';
import arAE from './strings-ar-AE.json';
import csCZ from './strings-cs-CZ.json';
import enCA from './strings-en-CA.json';
import plPL from './strings-pl-PL.json';
import zhTW from './strings-zh-TW.json';
import i18next from '../../../i18n';

export const loadLocale = () => {
    i18next.addResources('en-US', 'translation', enUs);
    i18next.addResources('de-DE', 'translation', deDe);
    i18next.addResources('en-GB', 'translation', enGb);
    i18next.addResources('en-IN', 'translation', enIn);
    i18next.addResources('es-ES', 'translation', esEs);
    i18next.addResources('es-MX', 'translation', esMx);
    i18next.addResources('fr-CA', 'translation', frCa);
    i18next.addResources('fr-FR', 'translation', frFr);
    i18next.addResources('it-IT', 'translation', itIt);
    i18next.addResources('ja-JP', 'translation', jaJp);
    i18next.addResources('ko-KR', 'translation', koKr);
    i18next.addResources('nl-NL', 'translation', nlNl);
    i18next.addResources('pt-BR', 'translation', ptBr);
    i18next.addResources('tr-TR', 'translation', trTr);
    i18next.addResources('zh-CN', 'translation', zhCn);
    i18next.addResources('ar-AE', 'translation', arAE);
    i18next.addResources('cs-CZ', 'translation', csCZ);
    i18next.addResources('en-CA', 'translation', enCA);
    i18next.addResources('pl-PL', 'translation', plPL);
    i18next.addResources('zh-TW', 'translation', zhTW);
};
