const useLocal = typeof LOCAL !== 'undefined' && LOCAL;

const affinityGroupsChapterProfile = false;

/** @description Flag to allow member creation once */
export const memberCreated = { created: false };

/** @description Flag to allow direct creating a member on the backend */
export const isCreatingMemberOnBackend = () => {
    return useLocal ? affinityGroupsChapterProfile : true;
};

/** @description Flag to allow direct updateing preferred email on the backend */
export const isUpdatingPreferredEmailOnBackend = () => {
    return useLocal ? affinityGroupsChapterProfile : true;
};

/** @description Flag to allow direct updateing chapter interests on the backend */
export const isUpdatingChaptersInterestOnBackend = () => {
    return useLocal ? affinityGroupsChapterProfile : true;
};

/** @description Flag to allow direct updateing chapter on the backend */
export const isUpdatingChaptersOnBackend = () => {
    return useLocal ? affinityGroupsChapterProfile : true;
};

/** @description Flag to allow direct getting chapter for site from the backend */

/** @description Flag to allow direct getting affinity groups from the backend */
export const isGettingContentromBackend = () => {
    return useLocal ? false : true;
};

export const isGettingChapterForSiteFromBackend = () => {
    return useLocal ? affinityGroupsChapterProfile : true;
};

/** @description Flag to allow direct getting affinity groups from the backend */
export const isGettingAffinityGroupsFromBackend = () => {
    return useLocal ? affinityGroupsChapterProfile : true;
};

/** @description Flag to allow direct getting member data from the backend */
export const isGettingMemberFromBackend = () => {
    return useLocal ? affinityGroupsChapterProfile : true;
};

/** @description Flag to allow direct getting papi employee info from the backend */
export const isGettingPapiEmployeeInfoFromBackend = () => {
    return useLocal ? affinityGroupsChapterProfile : true;
};

/** @description Flag to allow direct sendig feedback to the backend */
export const isSendingFeedbackToBackend = () => {
    return useLocal ? false : true;
};

/** @description Flag to allow a direct submit survey to the backend */
export const isSubmittingSurveyToBackend = () => {
    return useLocal ? false : true;
};
