import React, { useEffect } from 'react';
import { SearchResults } from './search-results.view';
import { getNotSupportedLanguageDetected } from '../../shared/functions/getNotSupportedLanguageDetected';
import { useTranslation } from 'react-i18next';
import { loadLocale } from '../../assets/locales/json/loadLocale';

type SearchResultsContainerProps = {
    initialQuery: string;
};

export const SearchResultsContainer = ({ initialQuery }: SearchResultsContainerProps) => {
    const { i18n } = useTranslation();

    useEffect(() => {
        loadLocale();
        getNotSupportedLanguageDetected(i18n);
    }, []);

    return <SearchResults initialQuery={initialQuery} />;
};
