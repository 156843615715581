import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PageContainer } from '../../shared/components/page-container';
import { Flex } from '@amzn/stencil-react-components/layout';
import {
    FeedbackInput,
    FeedbackType,
    FeedbackValue,
    ContentType,
    ContentBlock,
} from '../../types/graphql';
import { ContentElement } from './components/content-element';
import { RatingThumbComponent } from './components/rating-thumb-component';
import { loadFeedbackV1, sendFeedbackV1, deleteFeedbackV1 } from './slices/content.slice';
import { PageNames, WebAnalytics } from 'src/util/WebAnalytics';
import { logger } from '../../util/logger';
import { METRIC_NAME, PAGE_NAME } from 'src/util/types';

type ContentProps = {
    id: string;
    title?: string;
    blocks: ContentBlock[];
    feedbackBatch: FeedbackInput[];
};

export const Content = ({ id, title, blocks, feedbackBatch }: ContentProps) => {
    const dispatch = useDispatch();

    const c = [...blocks].sort((a, b) => a.order - b.order);
    const isArticle =
        c.filter((element) => element.type !== ContentType.Video).length > 0 ? true : false;

    const videoIds = c.filter((item) => item.type === ContentType.Video).map((item2) => item2.id);

    const feedbackIds = id ? [id, ...videoIds] : [''];

    useEffect(() => {
        dispatch(loadFeedbackV1(feedbackIds));
    }, [id]);

    const handleArticleRate = (
        Id: string,
        feedbackType: FeedbackType,
        feedbackValue: FeedbackValue
    ) => {
        dispatch(
            sendFeedbackV1({
                contentId: Id,
                feedbackType,
                feedbackValue,
            })
        );
    };

    const handleArticleRateDelete = (Id: string) => dispatch(deleteFeedbackV1(Id));

    WebAnalytics.registerPageView(PageNames.CONTENT_PAGE);

    return (
        <PageContainer contentTitle={title}>
            <Flex
                className="video-page"
                flexDirection="column"
                rowGap="S300"
                backgroundColor={isArticle ? 'white' : '#F2F2F2'}
                padding="S300"
                alignItems="center"
            >
                {isArticle && (
                    <Flex flexDirection="column" alignItems="left" width="100%">
                        <h1 style={{ fontSize: '28px' }}>{title}</h1>
                    </Flex>
                )}

                {c.map((contentBlock) => (
                    <ContentElement
                        contentId={contentBlock.id}
                        type={contentBlock.type}
                        text={contentBlock.altText || ''}
                        url={contentBlock.url || ''}
                        description={contentBlock.text}
                        transcription={contentBlock.transcription || ''}
                        key={contentBlock.order}
                        feedback={
                            (feedbackBatch.length &&
                                feedbackBatch.find((item) => item.contentId === contentBlock.id)) ||
                            {}
                        }
                        rateHandler={handleArticleRate}
                        rateDeleteHandler={handleArticleRateDelete}
                    />
                ))}
                {isArticle && (
                    <RatingThumbComponent
                        contentId={id}
                        rateHandler={handleArticleRate}
                        rateDeleteHandler={handleArticleRateDelete}
                        feedback={feedbackBatch.find((item) => item.contentId === id) || {}}
                    />
                )}
            </Flex>
        </PageContainer>
    );
};
