import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Expander, ExpanderTitleRendererParams } from '@amzn/stencil-react-components/expander';
import { Hr, Row, View } from '@amzn/stencil-react-components/layout';
import { AffinityGroup } from '../../../types/graphql';
import { H3, Text } from '@amzn/stencil-react-components/text';
import { styledWithTheme } from '@amzn/stencil-react-components/context';
import { ConfirmModal } from './confirm-modal';
import { AFFINITY_GROUP_IMAGES } from '../../../../configuration/affinityGroupImages';
import { DeleteConfirmationModal } from './delete-confirmation-modal';

type AffinityGroupDropdownProps = {
    title: string;
    itemsArray: AffinityGroup[];
    onSubmit: (group: AffinityGroup) => void;
};

const ClickableH3 = styledWithTheme(H3)(({ theme }) => ({
    cursor: 'pointer',
    color: theme.selectors.color('neutral100'),
    ':hover': {
        color: theme.selectors.color('neutral100'),
    },
}));

const renderTitle = ({ titleText, toggle }: ExpanderTitleRendererParams) => (
    <ClickableH3
        data-test="profileDropdown"
        onClick={toggle}
        style={{ fontSize: '16px', lineHeight: '24px', fontWeight: '500' }}
    >
        {titleText}
    </ClickableH3>
);

export const AffinityGroupDropdown = ({
    title,
    itemsArray,
    onSubmit,
}: AffinityGroupDropdownProps): JSX.Element => {
    const [showAgLeaveModal, setShowAgLeaveModal] = useState(false);
    const [showAgLeaveConfirmationModal, setShowAgLeaveConfirmationModal] = useState(false);
    const [selectedAg, setSelectedAg] = useState<AffinityGroup | undefined>(undefined);
    const { t } = useTranslation();

    const leaveAgModalTitle = t('encompass_profile_text6');
    const leaveAgModalContent = t('encompass_profile_text7');
    const removeBtnLabel = t('encompass_profile_text10');
    const removalConfirmationTitle = t('encompass_profile_text11');
    const removalConfirmationContent = t('encompass_profile_text12');
    const titleWithSize = `${title} (${itemsArray.length})`;

    function handleSubmit() {
        selectedAg && onSubmit(selectedAg);
    }

    return (
        <div data-test="affinityGroupDropdown">
            <View>
                <Expander contentSpacing="S100" renderTitle={renderTitle} titleText={titleWithSize}>
                    {itemsArray.map((item) => (
                        <React.Fragment key={item.id}>
                            <Row display="flex" justifyContent="space-between">
                                <View key={item.id} margin="S300" style={{ flexBasis: '100px' }}>
                                    <img
                                        src={AFFINITY_GROUP_IMAGES[item.logo]}
                                        alt={item.logoAltText}
                                        style={{ maxHeight: '60px' }}
                                    />
                                </View>
                                <View display="flex" alignItems="center" margin="S300">
                                    <button
                                        data-test="leaveButton"
                                        onClick={() => {
                                            setShowAgLeaveModal(true);
                                            setSelectedAg(item);
                                        }}
                                        style={{ background: 'transparent', border: 'none' }}
                                        aria-label={`Remove ${item.name} affinity group`}
                                    >
                                        <Text
                                            color="red"
                                            style={{
                                                cursor: 'pointer',
                                                fontSize: '16px',
                                                lineHeight: '24px',
                                                fontWeight: '400',
                                            }}
                                        >
                                            {removeBtnLabel}
                                        </Text>
                                    </button>
                                </View>
                            </Row>
                            <Hr />
                        </React.Fragment>
                    ))}
                </Expander>
            </View>

            {showAgLeaveModal && (
                <ConfirmModal
                    titleText={`${leaveAgModalTitle + selectedAg?.name} group ?`}
                    content={leaveAgModalContent}
                    leaveAg={true}
                    confirmHandler={setShowAgLeaveConfirmationModal}
                    open={true}
                    closeHandler={setShowAgLeaveModal}
                />
            )}
            {showAgLeaveConfirmationModal && (
                <DeleteConfirmationModal
                    titleText={removalConfirmationTitle}
                    content={selectedAg?.name + removalConfirmationContent}
                    open={true}
                    closeHandler={setShowAgLeaveConfirmationModal}
                    confirmHandler={handleSubmit}
                />
            )}
        </div>
    );
};
