import { Stage } from "../../../configuration/stage.enum";
import { STAGE_URL } from "../../../configuration/stageUrl";

interface GetStageDetected {
  (origin: string, isBetaDirectClient: boolean): Stage;
}

/** @description Function to return stage/ env in exchange for location.origin */
export const getStageDetected: GetStageDetected = (
  origin,
  isBetaDirectClient
) => {
  let stage: Stage = Stage.prod;

  if (STAGE_URL.prod.app.includes(origin)) {
    stage = Stage.prod;
  } else if (STAGE_URL.beta.app.includes(origin)) {
    stage = Stage.beta;
  } else if (STAGE_URL.gamma.app.includes(origin)) {
    stage = Stage.gamma;
  } else if (isBetaDirectClient) {
    stage = Stage.beta;
  } else if (STAGE_URL.dev.app.includes(origin)) {
    stage = Stage.dev;
  }

  return stage;
};
