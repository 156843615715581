import React, { useState } from 'react';
import { Modal, ModalContent } from '@amzn/stencil-react-components/modal';
import { Button } from '@amzn/stencil-react-components/button';
import { Text } from '@amzn/stencil-react-components/text';
import { useTranslation } from 'react-i18next';

interface DeleteConfirmationModalProps {
    titleText: string;
    content: string;
    open: boolean;
    confirmHandler: () => void;
    closeHandler: (e: boolean) => void;
}
export const DeleteConfirmationModal = ({
    titleText,
    content,
    open,
    confirmHandler,
    closeHandler,
}: DeleteConfirmationModalProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState(open);
    const { t } = useTranslation();

    const closeModalBtnLabel = t('navigation_close');

    const closeModal = () => {
        setIsOpen(false);
        closeHandler(false);
    };

    const close = () => {
        confirmHandler();
        setIsOpen(false);
        closeHandler(false);
    };

    return (
        <Modal isOpen={isOpen} close={closeModal}>
            <div data-test="deleteConfirmationModal" className="custom-modal">
                <ModalContent
                    titleText={titleText}
                    buttons={[
                        <Button
                            key="close"
                            data-test="Close"
                            onClick={close}
                            aria-label="Close"
                            style={{ fontSize: '16px', lineHeight: '24px', fontWeight: '500' }}
                        >
                            {closeModalBtnLabel}
                        </Button>,
                    ]}
                >
                    <Text
                        style={{
                            padding: '0px',
                            fontSize: '16px',
                            lineHeight: '24px',
                            fontWeight: '400',
                        }}
                    >
                        {content}
                    </Text>
                </ModalContent>
            </div>
        </Modal>
    );
};
