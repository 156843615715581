import React, { Context } from 'react';
import { PopStarFeatures } from '../feature-gates';

export type PopStarContextValues = {
    popStarFeatures: PopStarFeatures;
};

export const PopStarContext: Context<PopStarContextValues> =
    React.createContext<PopStarContextValues>({
        popStarFeatures: new PopStarFeatures(''),
    });
