declare global {
    interface Window {
        pendo: any;
    }
}

export type Metadata = {
    employeeId: string;
    workLocation: string;
};

export interface GetMetadata {
    (matadata: Metadata, pendo?: any): Metadata;
}

export const getMetadata: GetMetadata = (metadataDefault, pendo = window.pendo) => {
    let employeeId: string = metadataDefault.employeeId;
    let workLocation: string = metadataDefault.workLocation;

    if (pendo && pendo?.getSerializedMetadata) {
        const metadata2 = pendo.getSerializedMetadata();
        employeeId = metadata2?.visitor?.id ? metadata2?.visitor?.id : 'unknown';
        workLocation = metadata2?.visitor?.building_location?.split(', ')
            ? metadata2?.visitor?.building_location?.split(', ')[1]
            : 'unknown';
    }

    return {
        employeeId,
        workLocation,
    };
};
