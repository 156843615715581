import React from 'react';
import { Row } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import { IconHeart, IconHeartFill } from '@amzn/stencil-react-components/icons';
import { useTranslation } from 'react-i18next';
import { FeedbackInput, FeedbackType, FeedbackValue } from 'src/types/graphql';

type RatingComponentType = {
    contentId: string | undefined;
    feedback: FeedbackInput;
    rateHandler: (
        articleId: string,
        feedbackType: FeedbackType,
        feedbackValue: FeedbackValue
    ) => void;
    rateDeleteHandler: (articleId: string) => void;
};

export const RatingHeartComponent = ({
    contentId,
    feedback,
    rateHandler,
    rateDeleteHandler,
}: RatingComponentType) => {
    const { feedbackValue } = feedback;

    const { t } = useTranslation();
    const title = t('encompass_article_text2');

    function handleRate(contentId: string | undefined) {
        if (!contentId) return;

        if (feedbackValue === 'enable') {
            rateDeleteHandler(contentId);
        } else {
            rateHandler(contentId, FeedbackType.Heart, FeedbackValue.Enable);
        }
    }

    const getHeartWithColor = (feedbackValue: FeedbackValue | null | undefined) => {
        return feedbackValue === 'enable' ? (
            <IconHeartFill title="enable" />
        ) : (
            <IconHeart title="disable" />
        );
    };

    return (
        <Row display="flex" justifyContent="flex-start" style={{ cursor: 'pointer' }}>
            <button
                style={{
                    display: 'flex',
                    background: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                    padding: '0',
                }}
                onClick={() => handleRate(contentId)}
                aria-label="I like this video"
            >
                {getHeartWithColor(feedbackValue)}
                <Text
                    style={{
                        display: 'flex',
                        paddingLeft: '0.4rem',
                        alignSelf: 'center',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '22px',
                    }}
                >
                    {title}
                </Text>
            </button>
        </Row>
    );
};
