export interface AffinityGroupsAdditional {
    id: string;
  }
  
  export const AFFINITY_GROUPS_ADDITIONAL: AffinityGroupsAdditional[] = [
    {
      id: "WiFi",
    },
    {
      id: "AWE",
    },
  ];
  
  export const AFFINITY_GROUPS_ADDITIONAL_PATH: string = "additional-groups";