import React from 'react';
import { Flex, View } from '@amzn/stencil-react-components/layout';
import { Hr } from '@amzn/stencil-react-components/layout';
import { SectionTitle } from '../../features/affinity-groups/components/section-title';
import { Category } from '../../features/homepage/components/category';

type NavListProps = {
    title?: string;
    cards: { id: string; link: string; title: string; content: string }[];
};

export const NavList = ({ title, cards }: NavListProps) => {
    return (
        <Flex data-test="navList" flexDirection="column" backgroundColor="white" width="100%">
            {title && (
                <>
                    <SectionTitle title={title} />
                    <Hr />
                </>
            )}
            {cards.map(({ id, link, title, content }) => (
                <React.Fragment key={id}>
                    <Category key={id} id={id} link={link} title={title} content={content} />
                    {/* the margin ensures that outline is not covered by the separator line on a focused category*/}
                    <View margin={[2, 0, 0, 0]}>
                        <Hr />
                    </View>
                </React.Fragment>
            ))}
        </Flex>
    );
};
