import React from 'react';
import { View } from '@amzn/stencil-react-components/layout';
import { Button } from '@amzn/stencil-react-components/button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IconThumbUpFill } from '@amzn/stencil-react-components/icons';
import { Text } from '@amzn/stencil-react-components/text';

export const ShareFeedbackButton = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const label = t('encompass_home_text25');

    function handleClick() {
        navigate('/survey/1');
    }

    return (
        <View
            data-test="ShareFeedbackButton"
            padding="S300"
            display="flex"
            justifyContent="center"
            minWidth="200px"
        >
            <Button
                onClick={handleClick}
                aria-label="Share your feedback"
                style={{ maxWidth: '300px', whiteSpace: 'normal' }}
            >
                <IconThumbUpFill title="thumb" />
                <Text style={{ fontSize: '16px', lineHeight: '24px', fontWeight: '500' }}>
                    {label}
                </Text>
            </Button>
        </View>
    );
};
