export class PopStarFeatures {
    private readonly features: string | undefined;

    constructor(features: string | undefined) {
        this.features = features;
    }

    public isSearchVisible() {
        return this.features && this.features.includes('encompass_search');
    }
}
