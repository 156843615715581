import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Hr } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import { Pagination } from '@amzn/stencil-react-components/pagination';
import { PageContainer } from '@amzn/stencil-react-components/page';
import { SearchInput } from '../../shared/components/search-input';
import { NavList } from '../../shared/components/nav-list';
import { ResultsNotFound } from '../search-results/components/results-not-found';
import { useGetContentByTermAndLangQuery, SearchResultContent } from '../../types/graphql';
import { Spinner } from 'src/shared/components/spinner';
import { PageNames, WebAnalytics } from 'src/util/WebAnalytics';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import { getLanguage } from '../../shared/functions/getLanguage';

const INITIAL_PAGE_SIZE = 20;

type SearchResultsProps = {
    initialQuery: string;
};

export const SearchResults = ({ initialQuery }: SearchResultsProps) => {
    const [query, setQuery] = useState<string>(initialQuery);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(INITIAL_PAGE_SIZE);
    const employeeId = useSelector((state: RootState) => state.profile.userMember?.employeeId || 0);

    const { loading, data } = useGetContentByTermAndLangQuery({
        variables: {
            language: getLanguage(),
            term: query || '',
            from: ((currentPage - 1) * pageSize).toString(),
            size: pageSize.toString(),
        },
        skip: !query,
    });

    const { content, total } = data?.getContentByTermAndLang ?? {};
    const searchResults = (content ?? [])
        .filter((obj): obj is SearchResultContent => Boolean(obj))
        .map((sr) => ({
            id: sr.id,
            link: `${sr.publishLocation.url.split('/encompass')[1]}/${sr.id}`,
            title: sr.title,
            content: sr.description,
        }));
    const numberOfPages = Math.ceil((total ?? 0) / pageSize);

    const { t } = useTranslation();
    const placeholder = t('search_by_title_or_keyboard');
    const totalItems = t('number_of_pages', { count: numberOfPages });
    const title = t('encompass_home_text15');

    useEffect(() => {
        searchResults.length > 0
            ? WebAnalytics.registerSearchKeywords(employeeId, query)
            : WebAnalytics.registerSearchKeywordsNotFound(employeeId, query);
    }, [searchResults]);

    function handleSubmit(queryIn: string) {
        if (typeof queryIn === 'string' && queryIn.length > 1) {
            setCurrentPage(1);
            setQuery(queryIn);
        }
    }

    WebAnalytics.registerPageView(PageNames.SEARCH_RESULTS_PAGE);

    return (
        <PageContainer>
            <Flex flexDirection="row" justifyContent="center">
                <Text fontSize="T600" textAlign="center">
                    {title}
                </Text>
            </Flex>
            <SearchInput
                placeholder={placeholder}
                onSubmitFunction={handleSubmit}
                value={typeof query === 'string' ? query : ''}
            />
            <Hr size="wide" />
            <Flex flexDirection="column" rowGap="S200" margin={['S300', 0, 0, 0]}>
                {useMemo(() => {
                    if (loading) {
                        return <Spinner />;
                    }
                    return searchResults.length > 0 ? (
                        <NavList cards={searchResults} />
                    ) : (
                        <ResultsNotFound />
                    );
                }, [searchResults, loading])}
            </Flex>
            {searchResults.length > 0 && (
                <Flex flexDirection="column" alignItems="center" rowGap="S200">
                    <Text textAlign="center" margin={['S300', 0, 0, 0]} fontSize="16px">
                        {totalItems}
                    </Text>
                    <Pagination
                        numberOfPages={numberOfPages}
                        selectedPage={currentPage}
                        onPageSelect={setCurrentPage}
                    />
                </Flex>
            )}
        </PageContainer>
    );
};
