import { Language } from '../../types/graphql';

/**
 * @description Function to return a schema language code
 *              based on the broser language
 */
export const getLanguage = (
    language: string = document.querySelector('html')?.getAttribute('lang') || 'en-US'
): Language => {
    const languageKey = language.toLowerCase().slice(0, 2) || 'en';
    return (
        {
            en: Language.En,
            es: Language.Es,
        }[languageKey] || Language.En
    );
};
