import React, { useEffect, useMemo, useState } from 'react';
import { Breadcrumbs, CrumbLink } from '@amzn/stencil-react-components/breadcrumbs';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Text } from '@amzn/stencil-react-components/text';

type Crumb = {
    title: string;
    url: string;
};

type BreadcrumbsProps = {
    contentTitle?: string;
};

const affinityGroups = 'Affinity Groups ';
const inclusion = 'Inclusion';
const resources = 'Resources';
const ourWorkplace = 'Our Workplace ';
const diversityCalendar = 'Diversity Calendar ';
const survey = 'Survey';
const agFaqs = 'Affinity Group Faqs ';
const profile = 'Profile';

export const BreadCrumbs = ({ contentTitle }: BreadcrumbsProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [crumbs, setCrumbs] = useState<Crumb[]>([
        {
            title: 'AtoZ Encompass',
            url: '/',
        },
    ]);

    useEffect(() => {
        const pathname = window.location.pathname;
        const crumbIndex = crumbs.findIndex((el) => el.url === pathname);

        if (crumbIndex > -1) {
            setCrumbs(crumbs.slice(0, crumbIndex + 1));
        } else {
            const pathnameArr = pathname.split('/');
            pathnameArr.shift();
            pathnameArr.shift();

            const newCrumbsArr = [];
            let newPathUrl = '';

            for (const pathArrElement of pathnameArr) {
                let newCrumbTitle = '';
                if (pathArrElement.length) {
                    if (
                        ['1', '2', '3'].includes(pathArrElement) ||
                        crumbs.some((e) => e.title === 'Survey')
                    ) {
                        break;
                    }

                    newPathUrl += `/${pathArrElement}`;
                    if (pathArrElement.includes('-') && pathArrElement.length <= 30) {
                        const crumbTitleArr = pathArrElement.split('-');
                        for (const element of crumbTitleArr) {
                            newCrumbTitle += `${element[0].toUpperCase() + element.substring(1)} `;
                        }
                    } else if (pathArrElement.length > 30) {
                        newCrumbTitle += contentTitle;
                    } else {
                        newCrumbTitle =
                            pathArrElement[0].toUpperCase() + pathArrElement.substring(1);
                    }

                    if (newCrumbTitle.includes('@')) {
                        newCrumbTitle = newCrumbTitle.replace('@', '');
                    }

                    if (newCrumbTitle === affinityGroups) {
                        newCrumbTitle = t('encompass_home_text5');
                    } else if (newCrumbTitle === inclusion) {
                        newCrumbTitle = t('encompass_home_text7');
                    } else if (newCrumbTitle === resources) {
                        newCrumbTitle = t('encompass_home_text9');
                    } else if (newCrumbTitle === ourWorkplace) {
                        newCrumbTitle = t('encompass_home_text11');
                    } else if (newCrumbTitle === diversityCalendar) {
                        newCrumbTitle = t('encompass_home_text13');
                    } else if (newCrumbTitle === survey) {
                        newCrumbTitle = t('encompass_survey_text104');
                    } else if (newCrumbTitle === agFaqs) {
                        newCrumbTitle = t('encompass_affinity_groups_text4');
                    } else if (newCrumbTitle === profile) {
                        newCrumbTitle = t('encompass_profile_text0');
                    }
                    newCrumbsArr.push({ title: newCrumbTitle, url: newPathUrl });
                }
            }

            setCrumbs([...crumbs, ...newCrumbsArr]);
        }
    }, [window.location.pathname]);

    useEffect(() => {
        if (crumbs.length <= 0) {
            return;
        }

        const title = [...crumbs]
            .reverse()
            .map((crumb) => crumb.title)
            .join(' - ');

        const titleEl = document.querySelector('#app-title') as HTMLElement;
        if (titleEl) {
            titleEl.innerText = title;
        }
    }, [t, crumbs]);

    return (
        <Breadcrumbs
            onCrumbClick={(e, crumb) => {
                e.preventDefault();
                crumb.url && navigate(crumb.url);
            }}
            crumbs={crumbs}
            renderCrumbLink={(crumb) => (
                <NavLink to={crumb.url || ''}>
                    <Text style={{ fontSize: '14px', fontWeight: '500', lineHeight: '22px' }}>
                        {crumb.title}
                    </Text>
                </NavLink>
            )}
        />
    );
};
