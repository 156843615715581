import React from 'react';
import { View } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import { Logo } from '../../../shared/components/logo';
import { Link } from 'react-router-dom';
import { AFFINITY_GROUP_IMAGES } from '../../../../configuration/affinityGroupImages';

type AffinityGroupProps = {
    link: string;
    logo: any;
    logoAltText: string;
    name: string;
};

export const AffinityGroup = ({
    link,
    logo,
    logoAltText,
    name,
}: AffinityGroupProps): JSX.Element => {
    const logoNext = AFFINITY_GROUP_IMAGES[logo];

    return (
        <Link style={{ width: '100%' }} to={link}>
            <View
                display="flex"
                flexDirection="column"
                border="none!important"
                style={{
                    boxShadow: '0 0 1px gray',
                    padding: '16px',
                    margin: '8px 16px 8px 16px',
                }}
                backgroundColor="white"
                justifyContent="center"
                alignItems="center"
            >
                <View
                    display="flex"
                    justifyContent="center"
                    padding="S100"
                    width="210px"
                    height="80px"
                    alignSelf="center"
                >
                    <img
                        src={logoNext}
                        alt=""
                        style={{ height: '100%', width: '100%', margin: 'auto', display: 'block' }}
                    />
                </View>
                <View>
                    <Text
                        color="black"
                        textAlign="center"
                        style={{
                            wordBreak: 'break-all',
                            fontSize: '14px',
                            lineHeight: '22px',
                            fontWeight: '400',
                        }}
                    >
                        {name}
                    </Text>
                </View>
            </View>
        </Link>
    );
};
