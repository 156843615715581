import React, { useState } from 'react';
import { Modal, ModalContent } from '@amzn/stencil-react-components/modal';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { Text } from '@amzn/stencil-react-components/text';
import { useTranslation } from 'react-i18next';

interface ConfirmModal {
    titleText: string;
    content: string;
    leaveAg?: boolean;
    confirmHandler: (e: boolean) => void;
    open: boolean;
    closeHandler: (e: boolean) => void;
}

export const ConfirmModal = ({
    titleText,
    content,
    leaveAg,
    confirmHandler,
    open,
    closeHandler,
}: ConfirmModal): JSX.Element => {
    const [isOpen, setIsOpen] = useState(open);
    const { t } = useTranslation();
    const cancelBtnLabel = t('encompass_profile_text4');
    const confirmBtnLabel = t('encompass_profile_text5');

    const close = () => {
        setIsOpen(false);
        closeHandler(false);
    };

    return (
        <Modal isOpen={isOpen} close={close}>
            <div data-test="confirmModal" className="custom-modal">
                <ModalContent
                    titleText={titleText}
                    buttons={[
                        <Button
                            onClick={close}
                            variant={ButtonVariant.Tertiary}
                            aria-label="Cancel"
                            key="button-1"
                            style={{ fontSize: '16px', lineHeight: '24px', fontWeight: '500' }}
                        >
                            {cancelBtnLabel}
                        </Button>,
                        <Button
                            data-test="confirmRemoval"
                            isDestructive={leaveAg ? true : false}
                            onClick={() => {
                                close();
                                confirmHandler(true);
                            }}
                            variant={ButtonVariant.Primary}
                            aria-label="Confirm"
                            key="button-2"
                            style={{ fontSize: '16px', lineHeight: '24px', fontWeight: '500' }}
                        >
                            {confirmBtnLabel}
                        </Button>,
                    ]}
                >
                    <Text style={{ fontSize: '16px', lineHeight: '24px', fontWeight: '400' }}>
                        {content}
                    </Text>
                </ModalContent>
            </div>
        </Modal>
    );
};
