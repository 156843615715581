const supportedFlags = [
  'ae', // UAE
  'at', // Austria
  'au', // Australia
  'be', // Belgium
  'bg', // Bulgaria
  'bh', // Bahrain
  'br', // Brazil
  'ca', // Canada
  'ch', // Switzerland
  'cl', // Chile
  'cn', // China
  'co', // Colombia
  'cz', // Czech Republic
  'de', // Germany
  'dk', // Denmark
  'do', // Dominican Republic
  'eg', // Egypt
  'es', // Spain
  'eu', // European Union
  'fi', // Finland
  'fr', // France
  'gb', // Great Britain
  'hk', // Hong Kong
  'hr', // Croatia
  'hu', // Hungary
  'ie', // Ireland
  'il', // Israel
  'in', // India
  'it', // Italy
  'jo', // Jordan
  'jp', // Japan
  'kp', // North Korea
  'kr', // South Korea
  'kw', // Kuwait
  'lb', // Lebanon
  'ma', // Morocco
  'mx', // Mexico
  'my', // Malaysia
  'ng', // Nigeria
  'nl', // Netherlands
  'no', // Norway
  'nz', // New Zealand
  'om', // Oman
  'ph', // Philippines
  'pl', // Polish
  'pt', // Portugal
  'qa', // Qatar
  'ro', // Romania
  'ru', // Russia
  'sa', // Saudi Arabia
  'se', // Sweden
  'sg', // Singapore
  'tr', // Turkey
  'uk', // United Kingdom
  'us', // United States
  'za', // South Africa
];

const NAME_TO_FLAG = {};
supportedFlags.forEach(flag => (NAME_TO_FLAG[`flag-${flag}`] = flag));

// CLOUD_FRONT_URL comes from webpack.DefinePlugin
export const getFlagUrl = (name: string) => {
  const flag = NAME_TO_FLAG[name.toLowerCase()];
  if (flag) {
    return `${CLOUD_FRONT_URL}/flags/${flag}.svg`;
  }

  // This ideally should not happen.
  // Currently, there are no cloudwatch 404 logs that indicate any teams are using unsupported flags.
  // This call is to log a console error as an early warning for parties that may use unsupported flags.
  // TODO - replace it with dev-time logging when https://sim.amazon.com/issues/KDS-2364 is ready
  console.error(`kat-icon: The flag ${name} is not supported.`);
};
