import React from 'react';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Text } from '@amzn/stencil-react-components/text';

type SignUpButtonProps = {
    link: string;
    active: boolean;
};

export const SignUpButton = ({ link, active }: SignUpButtonProps): JSX.Element => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const buttonSignUp = t('encompass_sign_up_affinity_group_text1');

    function handleClick() {
        navigate(`${link}/sign-up`, {
            state: active,
        });
    }

    return (
        <Button
            aria-describedby="sroDialog"
            onClick={handleClick}
            variant={ButtonVariant.Primary}
            style={{ whiteSpace: 'normal', textAlign: 'center', width: '100%' }}
            data-test="signupButton"
            aria-label="Sign Up"
        >
            {' '}
            <Text style={{ fontSize: '16px', lineHeight: '24px', fontWeight: '500' }}>
                {buttonSignUp}
            </Text>
        </Button>
    );
};
