import img_220720_PWD_lockup_Squid_Aurora_CMYK from "../src/images/img_220720_PWD_lockup_Squid_Aurora_CMYK.svg"; // "../src/images/img_220720_PWD_lockup_Squid_Aurora_CMYK.svg";
import img_220720_Families_at_Amazon_main_h_fullcolor_rgb from "../src/images/img_220720_Families_at_Amazon_main_h_fullcolor_rgb.svg";
import img_220720_MHW_main_h_fullcolor_rgb from "../src/images/img_220720_MHW_main_h_fullcolor_rgb.svg";
import img_220720_AWE_main_s_fullcolor_rgb from "../src/images/img_220720_AWE_main_s_fullcolor_rgb.svg";
import img_220720_Latinos_at_Amazon_main_h_fullcolor_rgb from "../src/images/img_220720_Latinos_at_Amazon_main_h_fullcolor_rgb.svg";
import img_220720_Asians_at_Amazon_main_h_fullcolor_rgb_over_light from "../src/images/img_220720_Asians_at_Amazon_main_h_fullcolor_rgb_over_light.svg";
import img_220720_Glamazon_main_h_fullcolor_rgb from "../src/images/img_220720_Glamazon_main_h_fullcolor_rgb.svg";
import img_220720_Body_Positive_Peers_main_sq_fullcolor_rgb from "../src/images/img_220720_Body_Positive_Peers_main_sq_fullcolor_rgb.svg";
import img_220720_Women_in_Finance_main_s_fullcolor_rgb from "../src/images/img_220720_Women_in_Finance_main_s_fullcolor_rgb.svg";
import img_220720_Warriors_at_Amazon_main_h_fullcolor_rgb_over_light from "../src/images/img_220720_Warriors_at_Amazon_main_h_fullcolor_rgb_over_light.svg";
import img_220720_Women_at_Amazon_main_h_fullcolor_rgb from "../src/images/img_220720_Women_at_Amazon_main_h_fullcolor_rgb.svg";
import img_220720_Indigenous_at_logo_color from "../src/images/img_220720_Indigenous_at_logo_color.svg";
import img_220720_BEN_Lockup_H_1_RGB_d from "../src/images/img_220720_BEN_Lockup_H_1_RGB_d.svg";

export const AFFINITY_GROUP_IMAGES: any = {
  img_220720_PWD_lockup_Squid_Aurora_CMYK,
  img_220720_Families_at_Amazon_main_h_fullcolor_rgb,
  img_220720_MHW_main_h_fullcolor_rgb,
  img_220720_AWE_main_s_fullcolor_rgb,
  img_220720_Latinos_at_Amazon_main_h_fullcolor_rgb,
  img_220720_Asians_at_Amazon_main_h_fullcolor_rgb_over_light,
  img_220720_Glamazon_main_h_fullcolor_rgb,
  img_220720_Body_Positive_Peers_main_sq_fullcolor_rgb,
  img_220720_Women_in_Finance_main_s_fullcolor_rgb,
  img_220720_Warriors_at_Amazon_main_h_fullcolor_rgb_over_light,
  img_220720_Women_at_Amazon_main_h_fullcolor_rgb,
  img_220720_Indigenous_at_logo_color,
  img_220720_BEN_Lockup_H_1_RGB_d,
};
