import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import { Content } from './content.view';
import { useLocation } from 'react-router-dom';
import { useGetContentByIdAndLangV1Query } from '../../types/graphql';
import { Spinner } from 'src/shared/components/spinner';
import { getLanguage } from '../../shared/functions/getLanguage';
import { getNotSupportedLanguageDetected } from '../../shared/functions/getNotSupportedLanguageDetected';
import { useTranslation } from 'react-i18next';
import { loadLocale } from '../../assets/locales/json/loadLocale';
import { Text } from '@amzn/stencil-react-components/text';

export const ContentContainer = (): JSX.Element => {
    const { i18n, t } = useTranslation();
    const contentIdNext = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

    const feedbackBatch = useSelector((state: RootState) => {
        const {
            content: { feedbackBatch },
        } = state;
        return feedbackBatch;
    });

    useEffect(() => {
        loadLocale();
        getNotSupportedLanguageDetected(i18n);
    }, []);

    const { data, loading } = useGetContentByIdAndLangV1Query({
        variables: {
            id: contentIdNext,
            language: getLanguage(),
        },
    });

    const content = data?.getContentByIdAndLangV1;
    const noContentFound = t('no_content_found');

    if (loading) {
        return <Spinner />;
    } else if (!loading && content) {
        return (
            <Content
                id={content?.id}
                title={content.title}
                blocks={content.blocks}
                feedbackBatch={feedbackBatch}
            />
        );
    } else {
        return (
            <Text style={{ fontSize: '16px', fontWeight: '400', lineHeight: '24px' }}>
                {noContentFound}
            </Text>
        );
    }
};
