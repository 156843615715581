export type AdobeOmnitureModel = {
    pageName: string; // Page Name
    eVar42: string; // Page Name
    eVar43: string; // Page Name
    eVar44: string; // Sub Page Name
    eVar45: string; // Page Action
    eVar46: string; // Page Const
    eVar47: string; // Hit Type
    prop31: string; // Ref tag
    referrer: string; // referrer
};

interface AdobeOmniture extends AdobeOmnitureModel {
    t: (pageInfo: AdobeOmnitureModel) => void;
}

export class AdobeOmnitureBuilder {
    public static DEFAULT_VALUE = '';
    private readonly pageName: string;
    private subPageName: string;
    private hitType: string;
    private pageAction: string;
    private pageConst: string;
    private refTag: string;
    private referrer: string;

    public constructor(pageName: string) {
        this.pageName = pageName;
        this.hitType = AdobeOmnitureBuilder.DEFAULT_VALUE;
        this.pageAction = AdobeOmnitureBuilder.DEFAULT_VALUE;
        this.pageConst = AdobeOmnitureBuilder.DEFAULT_VALUE;
        this.subPageName = AdobeOmnitureBuilder.DEFAULT_VALUE;
        this.refTag = AdobeOmnitureBuilder.DEFAULT_VALUE;
        this.referrer = AdobeOmnitureBuilder.DEFAULT_VALUE;
    }

    public withSubPageName(subPageName: string): AdobeOmnitureBuilder {
        this.subPageName = subPageName;
        return this;
    }

    public withHitType(hitType: string): AdobeOmnitureBuilder {
        this.hitType = hitType;
        return this;
    }

    public withPageAction(pageAction: string): AdobeOmnitureBuilder {
        this.pageAction = pageAction;
        return this;
    }

    public withPageConst(pageConst: string): AdobeOmnitureBuilder {
        this.pageConst = pageConst;
        return this;
    }

    public withRefTag(refTag: string): AdobeOmnitureBuilder {
        this.refTag = refTag;
        return this;
    }

    public withReferrer(referrer: string): AdobeOmnitureBuilder {
        this.referrer = referrer;
        return this;
    }

    public build(): AdobeOmnitureModel {
        return {
            pageName: this.pageName,
            eVar42: this.pageName,
            eVar43: this.pageName,
            eVar44: this.subPageName,
            eVar45: this.pageAction,
            eVar46: this.pageConst,
            eVar47: this.hitType,
            prop31: this.refTag,
            referrer: this.referrer
        };
    }
}

export class OmnitureHelper {
    /**
     * Call Omniture to update the metrics for page information
     * @param pageInfo - omniture model
     */
    static setOmniturePageInfo(pageInfo: AdobeOmnitureModel) {
        if (OmnitureHelper.hasOmniture(window)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const omniture: AdobeOmniture = (window as any).s;
            omniture.t(pageInfo); // This function-call publishes your metrics via Adobe Omniture.
        }
    }

    /**
     * Determine if the current window has omniture js available
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static hasOmniture(window: any): window is { s: AdobeOmniture } {
        return window.s != null;
    }
}