import React, { useEffect } from 'react';
import { PageContainer } from '../../shared/components/page-container';
import { NavList } from '../../shared/components/nav-list';
import { Flex } from '@amzn/stencil-react-components/layout';
import { Content } from '../../types/graphql';
import { logger } from '../../util/logger';
import { METRIC_NAME, PAGE_NAME } from 'src/util/types';

export type ContentListProps = {
    cards: Content[] | any[];
};

export const ContentList = ({ cards }: ContentListProps): JSX.Element => {
    const cardsToPass = cards?.map((card) => {
        const linkNext = card.publishLocation.url.split('/encompass')[1];

        return {
            id: card.id,
            link: `${linkNext}/${card.id}`,
            title: card.title,
            content: card.description,
        };
    });

    return (
        <PageContainer>
            {cards && (
                <Flex flexDirection="column" style={{ padding: '16px' }} width="100%">
                    <NavList cards={cardsToPass} />
                </Flex>
            )}
        </PageContainer>
    );
};
