import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { HomePage, HomePageProps } from './home-page.view';

export const HomePageContainer = () => {
    const {
        profile,
        affinityGroups: { affinityGroups },
    } = useSelector((state: RootState) => state);
    const interestList = profile.userMember?.interestList;
    const chapterList = profile.userMember?.chapterList;
    const email = profile.userMember?.preferredEmail;

    const homePageProps: HomePageProps = {
        chapterList,
        interestList,
        affinityGroups,
        email,
    };

    return <HomePage {...homePageProps} />;
};
