import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { Profile } from './profile.view';
import { getFilteredArraySrcByArrayWithIds } from '../../shared/functions/getFilteredArraySrcByArrayWithIds';
import {
    updateChaptersV1,
    updateChaptersInterestV1,
    updatePreferredEmailV1,
} from './slices/profile.slice';
import {
    Maybe,
    MemberChapterAssoc,
    ChapterInterest,
    AffinityGroup,
    UpdateChaptersInterestV1MutationVariables,
    UpdateChaptersV1MutationVariables,
    UpdatePreferredEmailV1MutationVariables,
} from '../../types/graphql';
import { getArrObjectsNoTypeName } from '../../shared/functions/getArrObjectsNoTypeName';
import { getNotSupportedLanguageDetected } from '../../shared/functions/getNotSupportedLanguageDetected';
import { loadLocale } from '../../assets/locales/json/loadLocale';
import { useTranslation } from 'react-i18next';

export const handleEmailChangeConfirmationFn =
    (dispatch: any, updatePreferredEmailInV1: any, prefferedEmail: any) =>
    (preferredEmailIn: string) => {
        if (preferredEmailIn !== prefferedEmail) {
            const variables: UpdatePreferredEmailV1MutationVariables = {
                input: { preferredEmail: preferredEmailIn },
            };
            dispatch(updatePreferredEmailInV1(variables));
        }
    };

export const handleChaptersLeaveConfirmationFn =
    (
        dispatch: any,
        updateChaptersInV1: any,
        chapterList: Maybe<Array<Maybe<MemberChapterAssoc>>>
    ) =>
    (affinityGroup: AffinityGroup) => {
        let chapterListNext =
            chapterList && chapterList.filter((item) => item?.affinityGroupId !== affinityGroup.id);
        chapterListNext = getArrObjectsNoTypeName(chapterListNext);

        const variables: UpdateChaptersV1MutationVariables = {
            input: {
                chapterList: chapterListNext,
            },
        };

        dispatch(updateChaptersInV1(variables));
    };

export const handleChaptersInterestLeaveConfirmationFn =
    (
        dispatch: any,
        updateChaptersInterestInV1: any,
        interestList: Maybe<Array<Maybe<ChapterInterest>>>
    ) =>
    (affinityGroup: AffinityGroup) => {
        let interestListtNext =
            interestList &&
            interestList.filter((item) => item?.affinityGroupId !== affinityGroup.id);
        interestListtNext = getArrObjectsNoTypeName(interestListtNext);

        const variables: UpdateChaptersInterestV1MutationVariables = {
            input: {
                chapterInterestList: interestListtNext,
            },
        };

        dispatch(updateChaptersInterestInV1(variables));
    };

export const ProfileContainer = ({
    handleEmailChangeConfirmation = handleEmailChangeConfirmationFn,
    handleChaptersLeaveConfirmation = handleChaptersLeaveConfirmationFn,
    handleChaptersInterestLeaveConfirmation = handleChaptersInterestLeaveConfirmationFn,
}) => {
    const dispatch: typeof useDispatch = useDispatch();
    const {
        profile: { userMember, loading },
        affinityGroups: { affinityGroups },
    } = useSelector((state: RootState) => state);
    const interestList = userMember?.interestList || [];
    const chapterList = userMember?.chapterList || [];
    const preferredEmail = userMember?.preferredEmail || '';
    const { i18n } = useTranslation();

    useEffect(() => {
        loadLocale();
        getNotSupportedLanguageDetected(i18n);
    }, []);

    const userAffinityGroups: AffinityGroup[] = getFilteredArraySrcByArrayWithIds({
        arrSrc: affinityGroups,
        arrWithIds: chapterList || [],
        nameId: 'affinityGroupId',
    });

    const userInterestLists: AffinityGroup[] = getFilteredArraySrcByArrayWithIds({
        arrSrc: affinityGroups,
        arrWithIds: interestList || [],
        nameId: 'affinityGroupId',
    });

    const profileProps = {
        handleChaptersInterestLeaveConfirmation: handleChaptersInterestLeaveConfirmation(
            dispatch,
            updateChaptersInterestV1,
            interestList
        ),
        handleChaptersLeaveConfirmation: handleChaptersLeaveConfirmation(
            dispatch,
            updateChaptersV1,
            chapterList
        ),
        handleEmailChangeConfirmation: handleEmailChangeConfirmation(
            dispatch,
            updatePreferredEmailV1,
            preferredEmail
        ),
        loading,
        preferredEmail,
        userAffinityGroups,
        userInterestLists,
    };
    return <Profile {...profileProps} />;
};
