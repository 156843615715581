import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getApolloClient } from '../../../apollo';
import {
    FeedbackInput,
    CreateFeedbackV1Document,
    DeleteFeedbackV1Document,
    BatchGetFeedbackV1Document
} from '../../../types/graphql';
import { isSendingFeedbackToBackend } from '../../../flags/index';

type ContentState = {
    loading: boolean;
    feedbackBatch: FeedbackInput[];
};

const initialState: ContentState = {
    loading: false,
    feedbackBatch: [],
};

export const changeFeedback = createAction<FeedbackInput>('content/changeFeedback');

export const loadFeedbackV1 = createAsyncThunk(
    'content/loadFeedback',
    async (feedbackIds: string[]) => {
        const apolloClient = getApolloClient({
            isBetaDirectClient: isSendingFeedbackToBackend(),
        });

        try {
            const { data } = await apolloClient.query({
                query: BatchGetFeedbackV1Document,
                variables: { ids: feedbackIds },
            });

            return Promise.resolve(data.batchGetFeedbackV1);
        } catch (error) {
            console.error('Error retrieving data', error);
        }
    }
);

export const sendFeedbackV1 = createAsyncThunk(
    'content/sendFeedback',
    async (feedback: FeedbackInput) => {
        const apolloClient = getApolloClient({
            isBetaDirectClient: isSendingFeedbackToBackend(),
        });

        try {
            const { data } = await apolloClient.mutate({
                mutation: CreateFeedbackV1Document,
                variables: { feedback },
            });

            return Promise.resolve(data.createFeedbackV1);
        } catch (error) {
            console.error('Error retrieving data', error);
        }
    }
);

export const deleteFeedbackV1 = createAsyncThunk(
    'content/deleteFeedback',
    async (articleId: string) => {
        const apolloClient = getApolloClient({
            isBetaDirectClient: isSendingFeedbackToBackend(),
        });

        try {
            const { data } = await apolloClient.mutate({
                mutation: DeleteFeedbackV1Document,
                variables: { contentId: articleId },
            });

            return Promise.resolve(data.deleteFeedbackV1);
        } catch (error) {
            console.error('Error retrieving data', error);
        }
    }
);

export const contentSlice = createSlice({
    name: 'content',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loadFeedbackV1.pending, (state) => {
                state.loading = true;
            })
            .addCase(loadFeedbackV1.fulfilled, (state, action) => {
                state.loading = false;
                const { feedbackBatch } = state;
                const { payload } = action;

                const feedbackPayload = payload.filter((item: FeedbackInput) => item !== null);

                const feedbackBatchPrev = feedbackBatch.filter(
                    (item) => item.contentId !== feedbackPayload.contentId
                );

                state.feedbackBatch = [...feedbackBatchPrev, ...feedbackPayload];
            })
            .addCase(sendFeedbackV1.pending, (state) => {
                state.loading = true;
            })
            .addCase(sendFeedbackV1.fulfilled, (state, action) => {
                state.loading = false;
                const { feedbackBatch } = state;
                const payloadNext = action.payload ? action.payload : {};

                const { __typename, ...feedbackPayload } = payloadNext;

                const feedbackBatchPrev = feedbackBatch.filter(
                    (item) => item.contentId !== feedbackPayload.contentId
                );

                state.feedbackBatch = [...feedbackBatchPrev, feedbackPayload];
            })
            .addCase(deleteFeedbackV1.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteFeedbackV1.fulfilled, (state, action) => {
                state.loading = false;
                const { feedbackBatch } = state;
                const {
                    meta: { arg },
                    payload: { __typename, ...feedbackPayload },
                } = action;

                const feedbackBatchNext = feedbackBatch.filter((item) => item.contentId !== arg);

                if (feedbackPayload.mutationStatus === 'OK')
                    state.feedbackBatch = feedbackBatchNext;
            });
    },
});

export const contentReducer = contentSlice.reducer;
