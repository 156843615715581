import React from 'react';
import { Text, H3 } from '@amzn/stencil-react-components/text';
import { View } from '@amzn/stencil-react-components/layout';

type SectionTitleProps = {
    title: string;
};

export const SectionTitle = ({ title }: SectionTitleProps): JSX.Element => {
    return (
        <View padding="S300">
            <H3 style={{ fontSize: '18px', lineHeight: '28px', fontWeight: '700' }}>{title}</H3>
        </View>
    );
};
