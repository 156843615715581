interface GetUniqArrBy {
    (props: string[], arrInp: any[]): any[];
}

/** @description Function to get unique array of object by an array of props */
export const getUniqArrBy: GetUniqArrBy = (props = [], arrInp = []) => {
    const objKey: any = {};
    return arrInp.reduce((res, item) => {
        const valStr: string = props.reduce((res2: string, prop) => `${res2}${item[prop]}`, '');
        if (objKey[valStr]) return res;
        objKey[valStr] = item;
        return [...res, item];
    }, []);
};
