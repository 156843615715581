import React from 'react';
import { Row, Col, Spacer } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import {
    IconThumbUp,
    IconThumbDown,
    IconThumbUpFill,
    IconThumbDownFill,
} from '@amzn/stencil-react-components/icons';
import { MessageBanner } from '@amzn/stencil-react-components/message-banner';
import { useTranslation } from 'react-i18next';
import { FeedbackInput, FeedbackType, FeedbackValue } from 'src/types/graphql';
import { styles } from './styles';

type RatingComponentType = {
    contentId: string | undefined;
    rateHandler: (
        contentId: string,
        feedbackType: FeedbackType,
        feedbackValue: FeedbackValue
    ) => void;
    rateDeleteHandler: (contentId: string) => void;
    feedback: FeedbackInput;
};

export const RatingThumbComponent = ({
    contentId,
    rateHandler,
    rateDeleteHandler,
    feedback,
}: RatingComponentType) => {
    const [bannerProps, setBannerProps] = React.useState<any>({
        state: '',
        visible: false,
    });

    const { feedbackValue } = feedback;

    const { t } = useTranslation();
    const title = t('encompass_article_text1');
    const [upPressed, setUpPressed] = React.useState<boolean>(false);
    const [downPressed, setDownPressed] = React.useState<boolean>(false);

    function onDismiss() {
        setBannerProps({
            state: '',
            visible: false,
        });
        setUpPressed(false);
        setDownPressed(false);
    }

    function handleRate(
        contentIdIn: string | undefined,
        feedbackValueNext: FeedbackValue,
        setUpDownPressed: React.Dispatch<any>
    ) {
        setUpDownPressed(true);
        if (!contentIdIn) return;
        if (feedbackValue === feedbackValueNext) {
            rateDeleteHandler(contentIdIn);
        } else {
            if (feedbackValueNext === FeedbackValue.Like) {
                setBannerProps({
                    state: FeedbackValue.Like,
                    visible: true,
                    text: 'you disliked this',
                });
            }
            if (feedbackValueNext === FeedbackValue.Dislike) {
                setBannerProps({
                    state: FeedbackValue.Dislike,
                    visible: true,
                    text: 'you disliked this',
                });
            }
            rateHandler(contentIdIn, FeedbackType.Thumb, feedbackValueNext);
        }
    }

    const getThumbUpDownWithColor = (feedbackValue: FeedbackValue | null | undefined) => {
        let thumbUp = <IconThumbUp title="like" />;
        let thumbDown = <IconThumbDown title="dislike" />;

        if (!feedbackValue) {
            return {
                thumbUp,
                thumbDown,
            };
        }

        if (feedbackValue === FeedbackValue.Like) {
            thumbUp = <IconThumbUpFill data-test="iconThumbUpFill" title="like" />;
        }

        if (feedbackValue === FeedbackValue.Dislike) {
            thumbDown = <IconThumbDownFill data-test="iconThumbDownFill" title="dislike" />;
        }

        return {
            thumbUp,
            thumbDown,
        };
    };

    return (
        <Row>
            <Col>
                {bannerProps.visible && (
                    <Row>
                        <MessageBanner
                            autoDismissAfter={2000}
                            aria-live="polite"
                            dismissButtonAltText="Dismiss this message"
                            onDismissed={onDismiss}
                        >
                            <Text fontSize="T400">Thanks for your feedback.</Text>
                        </MessageBanner>
                        <Spacer flex="1" height={64} width="" />
                    </Row>
                )}
                <Row
                    padding="S300"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-around"
                    backgroundColor="neutral05"
                    maxWidth="300px"
                    width="100%"
                    style={styles.rowStyle}
                >
                    <Text fontSize="T400">{title}</Text>
                    <button
                        data-test="thumbUp"
                        aria-pressed={upPressed}
                        style={styles.buttonStyle}
                        onClick={() => handleRate(contentId, FeedbackValue.Like, setUpPressed)}
                        aria-label="I found this helpful"
                    >
                        {getThumbUpDownWithColor(feedbackValue).thumbUp}
                    </button>
                    <button
                        data-test="thumbDown"
                        aria-pressed={downPressed}
                        style={styles.buttonStyle}
                        onClick={() => handleRate(contentId, FeedbackValue.Dislike, setDownPressed)}
                        aria-label="I didn't find this helpful"
                    >
                        {getThumbUpDownWithColor(feedbackValue).thumbDown}
                    </button>
                </Row>
            </Col>
        </Row>
    );
};
