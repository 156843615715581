import React from 'react';
import { Spinner as StencilSpinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';

export const Spinner = () => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: '100%',
            }}
        >
            <StencilSpinner size={SpinnerSize.Medium} />
        </div>
    );
};
