import { AffinityGroup } from '../../types/graphql';

interface GetFilteredArraySrcByArrayWithIds {
    ({ arrSrc, arrWithIds, nameId }: { arrSrc: any[]; arrWithIds: any[]; nameId: string }): any[];
}

/**
 * @description Function to filter arrSrc by ids from arrWithIds
 */
export const getFilteredArraySrcByArrayWithIds: GetFilteredArraySrcByArrayWithIds = ({
    arrSrc,
    arrWithIds,
    nameId,
}) => {
    if (!arrSrc || !arrSrc.length || !arrWithIds || !arrWithIds.length) return [];

    return arrSrc.filter((itemSrc: any) => {
        return (
            itemSrc &&
            arrWithIds.find((itemWithId: any) => itemWithId && itemWithId[nameId] === itemSrc.id)
        );
    });
};
