import React from 'react';
import { View } from '@amzn/stencil-react-components/layout';

type LogoProps = {
    logo: any;
    logoAltText: string;
};

export const Logo = ({ logo, logoAltText }: LogoProps): JSX.Element => {
    return (
        <View
            display="flex"
            justifyContent="center"
            padding="S100"
            width="280px"
            height="100px"
            alignSelf="center"
        >
            <img
                src={logo}
                alt={logoAltText}
                style={{ height: '100%', width: '100%', margin: 'auto', display: 'block' }}
            />
        </View>
    );
};
