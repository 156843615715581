import { ApolloClient } from '@apollo/client';

import { ClientApollo } from './ClientApollo';
import { getStageDetected } from '../shared/functions/getStageDetected';
import { getMetadata } from '../shared/functions/getMetadata';
import { METADATA_DEFAULT } from '../../configuration/metadataDefault';

const apolloClient: any = null;
let isCheckDevDirectClient: boolean = false;
let currentEmployeeId: string | null | undefined = null;

export function getApolloClient({
    isBetaDirectClient = false,
    employeeId,
}: {
    isBetaDirectClient?: boolean;
    employeeId?: string;
}): ApolloClient<any> {
    if (apolloClient && isCheckDevDirectClient === isBetaDirectClient) {
        return apolloClient;
    }

    const stage = getStageDetected(location.origin, isBetaDirectClient);

    if (employeeId) {
        currentEmployeeId = employeeId;
    }

    if (!currentEmployeeId) {
        currentEmployeeId = getMetadata(METADATA_DEFAULT, window.pendo).employeeId;
    }

    const clientApollo = new ClientApollo({
        stage,
        employeeId: currentEmployeeId,
        clientId: 'ATOZ_ENCOMPASS',
    });
    const client = clientApollo.createApolloClient();

    isCheckDevDirectClient = isBetaDirectClient;

    return client;
}
