import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { AffinityGroup, AffinityGroupProps } from './affinity-group.view';
import { Spinner } from '../../shared/components/spinner';
import {
    getGroupWithExtraProps,
    AffinityGroupExtended,
} from '../../shared/functions/getGroupWithExtraProps';
import { useGetContentByPublishLocationAndLangV1Query } from '../../types/graphql';
import { loadLocale } from '../../assets/locales/json/loadLocale';
import { getLanguage } from '../../shared/functions/getLanguage';
import { getNotSupportedLanguageDetected } from '../../shared/functions/getNotSupportedLanguageDetected';
import { useTranslation } from 'react-i18next';
import { Text } from '@amzn/stencil-react-components/text';
import { fetchOrCreateMemberV1, getPapiEmployeeInfo } from '../profile/slices/profile.slice';

interface AffinityGroupContainerInterface {
    ({
        groupId,
        isActionButton,
    }: {
        groupId: string | undefined;
        isActionButton: boolean;
    }): JSX.Element;
}

export const AffinityGroupContainer: AffinityGroupContainerInterface = ({
    groupId,
    isActionButton,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        profile: { userMember, loading, isSiteAllowed },
        affinityGroups: { affinityGroups },
    } = useSelector((state: RootState) => state);
    const chapterList = userMember?.chapterList;
    const interestList = userMember?.interestList || [];
    const { i18n } = useTranslation();
    const noAffinityGroupFound = t('affinity_group_not_found');
    const affinityGroup: AffinityGroupExtended = getGroupWithExtraProps({
        groups: affinityGroups,
        id: groupId,
        list: chapterList,
        listIdName: 'affinityGroupId',
        chaptersForSite: [],
    });

    const data = useGetContentByPublishLocationAndLangV1Query({
        variables: {
            publishLocation: window.location.pathname,
            language: getLanguage(),
        },
    })?.data;

    useEffect(() => {
        loadLocale();
        getNotSupportedLanguageDetected(i18n);
        dispatch(fetchOrCreateMemberV1());
    }, []);

    const affinityGroupProps: AffinityGroupProps | null = affinityGroup
        ? {
              active: affinityGroup.active,
              logoAltTextTranslationKey: affinityGroup.logoAltTextTranslationKey,
              descriptionTranslationKey: affinityGroup.descriptionTranslationKey,
              hasJoined: affinityGroup.hasJoined,
              isInterested: interestList.some((e) => e?.affinityGroupId === affinityGroup.id),
              link: affinityGroup.link,
              logo: affinityGroup.logo,
              name: affinityGroup.name,
              cards:
                  data?.getContentByPublishLocationAndLangV1?.sort(
                      (a, b) => a.contentOrder! - b.contentOrder!
                  ) || [],
              isActionButton,
              isSiteAllowed: userMember?.isSiteAllowed ?? false,
          }
        : null;

    if (loading || data === undefined) {
        return <Spinner />;
    } else if (!loading && affinityGroup && affinityGroupProps) {
        return <AffinityGroup {...affinityGroupProps} />;
    } else {
        return (
            <Text style={{ fontSize: '16px', fontWeight: '400', lineHeight: '24px' }}>
                {noAffinityGroupFound}
            </Text>
        );
    }
};
