import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Label, Text } from '@amzn/stencil-react-components/text';
import { Col, Row, Spacer, View } from '@amzn/stencil-react-components/layout';
import { useTranslation } from 'react-i18next';
import { Radio } from '@amzn/stencil-react-components/form';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { changeAnswer, AnswerState } from '../slices/survey.slice';
import { Question } from 'src/types/graphql';
import { useDispatch } from 'react-redux';
import { styledWithTheme } from '@amzn/stencil-react-components/context';

const StyledCheckboxDiv = styledWithTheme('div')(() => ({
    width: '24px',
    height: '24px',
    position: 'relative',
    display: 'flex',
    flexShrink: 0,
}));

const StyledCheckbox = styledWithTheme('input')(() => ({
    position: 'relative',
    top: '0px',
    left: '0px',
    display: 'block !important',
    margin: '0px',
    width: '24px',
    height: '24px',
    cursor: 'pointer',
    zIndex: 1,
}));

type SurveyPageProps = {
    question: Question;
    answer?: AnswerState;
    submitHandler: () => void;
    buttonLabel: string;
};

export const SurveyPage = ({
    question,
    answer: userAnswers,
    submitHandler,
    buttonLabel,
}: SurveyPageProps) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [radioChecked, setRadioChecked] = useState('');
    const [radioError, setRadioError] = useState(false);

    const legend = useRef<HTMLLegendElement>(null);
    const form = useRef<HTMLFormElement>(null);

    const multiselectTitle = t('encompass_survey_text102');
    const answerRequired = t('encompass_survey_text103');

    const setFocusOnLegend = () => {
        if (legend.current != null) {
            console.log('set focus');
            legend.current.focus();
        }
        return undefined;
    };

    const resetFocusOnFirstField = () => {
        const input = form.current?.querySelector('input');
        if (input != undefined) {
            input.focus();
        }
    }

    const onSubmit = () => {
        if (radioChecked) {
            submitHandler();
            setFocusOnLegend();
            setRadioError(false);
        } else {
            resetFocusOnFirstField();
            setRadioError(true);
        }
    };

    let checkboxArr: string[] = [];

    useEffect(() => {
        setRadioError(false);
        if (userAnswers !== undefined) {
            checkboxArr = userAnswers?.answer;
        }
    }, [radioChecked, userAnswers]);

    useEffect(() => {
        setFocusOnLegend();
    }, []);

    return (
        <Col padding="S200">
            <fieldset style={{ border: '0px' }}>
                <legend tabIndex={-1} ref={legend} className="survey-legend">
                    <Text style={{ fontSize: '22px', fontWeight: '500', lineHeight: '32px' }}>
                        {t(question.description)}
                    </Text>
                </legend>
                {question.multiselect && (
                    <Text style={{ fontSize: '16px', fontWeight: '400', lineHeight: '24px' }}>
                        {multiselectTitle}
                    </Text>
                )}
                <Col gridGap="S200">
                    <form onSubmit={handleSubmit(onSubmit)} ref={form}>
                        {question.answers.map((answer, idx) => {
                            if (question.multiselect) {
                                const answerArr = userAnswers?.answer || [];
                                let changeAnswerPayload = {
                                    description: t(question.description),
                                    questionId: question.questionId,
                                    answer: answerArr.filter((a: string) => a !== t(answer)),
                                };

                                if (!answerArr.includes(t(answer))) {
                                    changeAnswerPayload = {
                                        description: t(question.description),
                                        questionId: question.questionId,
                                        answer: [...answerArr, t(answer)],
                                    };
                                }

                                const validateFunc = () => {
                                    setValue('checkbox', undefined);
                                    if (checkboxArr.length > 0 || answerArr.length > 0) {
                                        return true;
                                    } else {
                                        return 'Please tell me if this is too hard.';
                                    }
                                };

                                return (
                                    <Row
                                        alignItems="center"
                                        padding="S200"
                                        gridGap="S200"
                                        key={answer}
                                    >
                                        <StyledCheckboxDiv>
                                            <StyledCheckbox
                                                type="checkbox"
                                                {...register('checkbox', {
                                                    validate: () => validateFunc(),
                                                })}
                                                checked={answerArr?.includes(t(answer))}
                                                name="checkbox"
                                                value={t(answer)}
                                                autoFocus={idx === 0 ? true : false}
                                                id={answer}
                                                onChange={() => {
                                                    delete errors.checkbox;
                                                    dispatch(changeAnswer(changeAnswerPayload));
                                                }}
                                            />
                                        </StyledCheckboxDiv>
                                        <Label
                                            htmlFor={answer}
                                            style={{
                                                fontSize: '16px',
                                                fontWeight: '400',
                                                lineHeight: '24px',
                                            }}
                                        >
                                            {t(answer)}
                                        </Label>
                                    </Row>
                                );
                            } else {
                                const answerArr: undefined | string | string[] =
                                    userAnswers?.answer;
                                return (
                                    <Row
                                        alignItems="center"
                                        padding="S200"
                                        gridGap="S200"
                                        key={answer}
                                    >
                                        <Radio
                                            data-test="surveyOption"
                                            {...register('radio')}
                                            name="radio"
                                            checked={answerArr?.includes(t(answer))}
                                            // value={answerArr}
                                            value={t(answer)}
                                            autoFocus={idx === 0 ? true : false}
                                            onChange={() => {
                                                setRadioChecked(t(answer));
                                                dispatch(
                                                    changeAnswer({
                                                        description: t(question.description),
                                                        questionId: question.questionId,
                                                        answer: [t(answer)],
                                                    })
                                                );
                                            }}
                                            id={answer}
                                        />
                                        <Label
                                            htmlFor={answer}
                                            style={{
                                                fontSize: '16px',
                                                fontWeight: '400',
                                                lineHeight: '24px',
                                            }}
                                        >
                                            {t(answer)}
                                        </Label>
                                    </Row>
                                );
                            }
                        })}
                        {(errors.checkbox || errors.radio) && (
                            <span
                                aria-live="polite"
                                className="survey-error-message"
                                style={{ fontSize: '16px', fontWeight: '400', lineHeight: '24px' }}
                            >
                                {answerRequired}
                            </span>
                        )}
                        {radioError && (
                            <span
                                data-test="answerRequriedError"
                                style={{ fontSize: '16px', fontWeight: '400', lineHeight: '24px' }}
                            >
                                {answerRequired}
                            </span>
                        )}
                        <View style={{ marginTop: '16px' }} display="flex" justifyContent="center">
                            <Button
                                data-test="surveyBtn"
                                variant={ButtonVariant.Primary}
                                style={{ width: '100%', textAlign: 'center' }}
                                type="submit"
                                aria-label="Submit answer"
                            >
                                <Text
                                    style={{
                                        fontSize: '16px',
                                        fontWeight: '500',
                                        lineHeight: '24px',
                                    }}
                                >
                                    {buttonLabel}
                                </Text>
                            </Button>
                        </View>
                    </form>
                </Col>
            </fieldset>
        </Col>
    );
};
