import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

/**
 * Automatically scrolls to the top of the page when the location changes.
 *
 * From https://reactrouter.com/web/guides/scroll-restoration
 */
export default function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}
