import { Metric } from 'web-vitals';

/**
 * Enum that has valid CloudWatch Units which we use in our app
 * More info: https://docs.aws.amazon.com/AmazonCloudWatch/latest/APIReference/API_MetricDatum.html
 */
export enum METRIC_UNIT {
    COUNT = 'Count',
    MILLISECONDS = 'Milliseconds',
    NONE = 'None',
}

export enum METRIC_NAME {
    APP_MOUNT = 'app.react.mount',
    APP_LATENCY_CF = 'app.latency.cf',
    APP_ERROR_UNKNOWN = 'app.error.unknown',
    APP_DURATION = 'app.duration',
}

export enum PAGE_NAME {
    ENCOMPASS_PAGE = 'EncompassPage',
    PROFILE_PAGE = 'ProfilePage',
    SURVEY_PAGE = 'SurveyPage',
    SEARCH_RESULTS_PAGE = 'SearchResultsPage',
    AFFINITY_GROUPS_PAGE = 'AffinityGroupsPage',
    CONTENT_PAGE = 'ContentPage',
    AFFINITY_GROUP_PAGE = 'AffinityGroupPage',
    CONTENT_LIST_PAGE = 'ContentListPage',
    SIGN_UP_PAGE = 'SignUpPage',
}

export enum DIMENSION_NAME {
    PAGE_NAME = 'PageName',
    DURATION = 'duration',
}

export type DIMENSION_VALUES = {
    [key in DIMENSION_NAME]?: any;
};

export type EMFMetric = {
    _aws: {
        Timestamp: number;
        CloudWatchMetrics: {
            Namespace: string;
            Metrics: { Name: string; Unit?: string }[];
        }[];
    };
};

export type MetaDataMetric = {
    pageName?: string;
    metricName: METRIC_NAME | Metric['name'];
    metricValue: number;
    metricUnit: METRIC_UNIT;
    dimensions?: DIMENSION_VALUES;
    namespace?: string;
};
