import React, { useRef, useEffect } from 'react';
import { Text } from '@amzn/stencil-react-components/text';
import { Flex, View } from '@amzn/stencil-react-components/layout';

type DescriptionProps = {
    title: string;
    content: string;
    headingFocus?: boolean;
};

export const Description = ({ title, content, headingFocus }: DescriptionProps): JSX.Element => {
    const heading = useRef<HTMLHeadingElement>(null);

    useEffect(() => {
        if (heading.current != null) {
            heading.current.focus();
        }
    }, []);

    return (
        <Flex flexDirection="column" padding="S100" rowGap="S200">
            <h2
                ref={heading}
                tabIndex={-1}
                data-test="submitPageTile"
                style={{ fontSize: '22px', fontWeight: '500', lineHeight: '32px' }}
            >
                {title}
            </h2>
            <View>
                <Text style={{ fontSize: '16px', fontWeight: '400', lineHeight: '24px' }}>
                    {content}
                </Text>
            </View>
        </Flex>
    );
};
