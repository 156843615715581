import React, { useState } from 'react';
import { EmailInput } from 'src/shared/components/email-input';
import { useTranslation } from 'react-i18next';

import { HandlerToAffinityGroupArg } from '../sign-up.container';

type EmailInputSectionProps = {
    handlerToAffinityGroupArg: HandlerToAffinityGroupArg;
    handlerToAffinityGroup: (handlerToAffinityGroupArg: HandlerToAffinityGroupArg) => void;
    setSigned: (signed: boolean) => void;
    setNewMail: (mail: string) => void;
};

export const EmailInputSection = ({
    handlerToAffinityGroupArg,
    handlerToAffinityGroup,
    setSigned,
    setNewMail,
}: EmailInputSectionProps) => {
    const [mail, setMail] = useState('');
    const { t } = useTranslation();

    const emailInformation = t('encompass_sign_up_affinity_group_text11');
    const emailPlaceholder = t('encompass_sign_up_affinity_group_text3');
    const validationMessage = t('encompass_sign_up_affinity_group_text4');
    const continueBtn = t('encompass_sign_up_affinity_group_text5');

    const submitHandler = (mail: string): void => {
        setMail(mail);
        setNewMail(mail);
        handlerToAffinityGroup({ ...handlerToAffinityGroupArg, email2: mail });
        setSigned(true);
    };

    return (
        <EmailInput
            email={mail}
            submitHandler={submitHandler}
            changeHandler={setMail}
            title={emailInformation}
            placeholder={emailPlaceholder}
            validationMessage={validationMessage}
            btnLabel={continueBtn}
            editOnly
        />
    );
};
