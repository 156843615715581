import React from 'react';
import { Flex, View } from '@amzn/stencil-react-components/layout';
import { Description } from '../../../shared/components/description';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { Text } from '@amzn/stencil-react-components/text';

type SubmitPageProps = {
    title: string;
    content: string;
    btnLabel: string;
    closeHandler: () => void;
};

export const SubmitPage = ({ title, content, btnLabel, closeHandler }: SubmitPageProps) => {
    return (
        <Flex flexDirection="column" backgroundColor="white">
            <Description title={title} content={content} headingFocus={true} />
            <View style={{ marginTop: '16px' }} display="flex" justifyContent="center">
                <Button
                    variant={ButtonVariant.Primary}
                    style={{ width: '100%', whiteSpace: 'normal' }}
                    onClick={closeHandler}
                >
                    <Text style={{ fontSize: '16px', fontWeight: '500', lineHeight: '24px' }}>
                        {btnLabel}
                    </Text>
                </Button>
            </View>
        </Flex>
    );
};
