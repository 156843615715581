import { Question } from '../../types/graphql';
interface QuestionState {
    questionId: string;
    description: string;
    multiselect: boolean;
    answers: string[];
}

export type Questions = Record<string, QuestionState>;

export const getSurveyObjIntoArray = (payload: any): Questions => {
    const arr: Questions = {};

    payload.questions.forEach((question: Question) => {
        arr[question.questionId] = question;
    });
    return arr;
};
