import React from 'react';
import { View, Flex } from '@amzn/stencil-react-components/layout';
import Svg from '../../../images/search-results-not-found.svg';
import { useTranslation } from 'react-i18next';

export const ResultsNotFound = () => {
    const { t } = useTranslation();
    const searchResultsNotFound = t('encompass_home_text23');
    const pleaseTryAgain = t('encompass_home_text17');

    return (
        <View display="flex" justifyContent="center" padding="S500">
            <Flex flexDirection="column" alignItems="center" rowGap="S100">
                <img src={Svg} alt="alt" />
                <h2>{searchResultsNotFound}</h2>
                <h4>{pleaseTryAgain}</h4>
            </Flex>
        </View>
    );
};
