interface GetArrObjectsNoTypeName {
    (arrInput: any[] | null | undefined): any[];
}

/** @description Function to return array of objects with no __typename property */
export const getArrObjectsNoTypeName: GetArrObjectsNoTypeName = (arrInput) => {
    if (arrInput == null || !arrInput || !arrInput.length) return [];
    return arrInput.map((item: any) => {
        let itemNext = item;
        if (item && item.__typename) {
            const { __typename, ...itemNext2 } = item;
            itemNext = itemNext2;
        }
        return itemNext;
    });
};
