import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { PageContainer } from '../../shared/components/page-container';
import { AffinityGroupContainer } from './affinity-group.container';
import { Spinner } from '../../shared/components/spinner';
import { useParams } from 'react-router-dom';
import { AffinityGroup as AffinityGroupType } from '../../types/graphql';
import {
    AFFINITY_GROUPS_ADDITIONAL,
    AffinityGroupsAdditional,
    AFFINITY_GROUPS_ADDITIONAL_PATH,
} from '../../../configuration/affinityGroupsAdditional';
import { AffinityGroup } from 'src/features/affinity-groups/components/affinity-group';

interface GetAffinityGroupSeriesProps {
    groupIdIn: string | undefined;
    affinityGroupsAdditionalIn: AffinityGroupsAdditional[];
}
interface GetAffinityGroupSeries {
    (props: GetAffinityGroupSeriesProps): ReactElement | ReactElement[];
}

export const AffinityGroupSeriesContainer = (): JSX.Element => {
    const groupId = useParams()?.groupId || '';

    const {
        affinityGroups: { loading, affinityGroups },
    } = useSelector((state: RootState) => state);

    const getAffinityGroupSeries: GetAffinityGroupSeries = ({
        groupIdIn,
        affinityGroupsAdditionalIn,
    }) => {
        let output: ReactElement | ReactElement[] = <></>;

        if (location.pathname.includes(AFFINITY_GROUPS_ADDITIONAL_PATH)) {
            const affinityGroupsFiltered = affinityGroups.filter(
                (affinityGroup: AffinityGroupType) => {
                    let isFiltered = false;
                    affinityGroupsAdditionalIn.forEach((item: AffinityGroupsAdditional) => {
                        if (!isFiltered && item.id === affinityGroup.id) isFiltered = true;
                    });
                    return isFiltered;
                }
            );
            output = (
                <PageContainer>
                    {affinityGroupsFiltered.map((affinityGroup: AffinityGroupType, index) => {
                        const { id, link, logo, logoAltText, name } = affinityGroup;
                        return (
                            <AffinityGroup
                                key={id}
                                link={link}
                                logo={logo}
                                logoAltText={logoAltText}
                                name={name}
                            />
                        );
                    })}
                </PageContainer>
            );
        } else {
            output = AFFINITY_GROUPS_ADDITIONAL.find((o) => o.id === groupId) ? (
                <AffinityGroupContainer groupId={groupIdIn} isActionButton={false} />
            ) : (
                <AffinityGroupContainer groupId={groupIdIn} isActionButton={true} />
            );
        }

        return output;
    };

    if (loading) {
        return <Spinner />;
    } else {
        return (
            <>
                {getAffinityGroupSeries({
                    groupIdIn: groupId,
                    affinityGroupsAdditionalIn: AFFINITY_GROUPS_ADDITIONAL,
                })}
            </>
        );
    }
};
