/**
 * @description Function which detects language change to the one that's not supported by panther
 * and changes it to english
 */

export const getNotSupportedLanguageDetected = (i18n: any) => {
    const notSupportedLangsArr = [
        //array needs to be modified when TAS supports more languages (for now it supports spanish and english)
        'ar-SA',
        'ar-AE',
        'cs-CZ',
        'de-DE',
        'fr-CA',
        'fr-FR',
        'it-IT',
        'ja-JP',
        'ko-KR',
        'nl-NL',
        'pl-PL',
        'pt-BR',
        'tr-TR',
        'zh-CN',
        'zh-TW',
        'ne-NP',
        'ro-RO',
        'ru-RU',
        'sk',
        'so-SO',
        'uk-UA',
    ];

    const currentLang = document.querySelector('html')?.getAttribute('lang');
    if (currentLang && notSupportedLangsArr.includes(currentLang)) {
        alert('Chosen language is not supported by AtoZ, switching to English...');
        document.querySelector('html')!.setAttribute('lang', 'en-US');
        i18n.changeLanguage('en-US');
    }
};
