import React, { useEffect } from 'react';
import { Survey } from './survey.view';
import { getNotSupportedLanguageDetected } from '../../shared/functions/getNotSupportedLanguageDetected';
import { useTranslation } from 'react-i18next';
import { loadLocale } from 'src/assets/locales/json/loadLocale';

export const SurveyContainer = () => {
    const { i18n } = useTranslation();

    useEffect(() => {
        loadLocale();
        getNotSupportedLanguageDetected(i18n);
    }, []);

    return <Survey />;
};
