import React, { useContext, useEffect, useState, useRef } from 'react';
import { Flex } from '@amzn/stencil-react-components/layout';
import { Logo } from '../../shared/components/logo';
import { Description } from '../../shared/components/description';
import { PageContainer } from '../../shared/components/page-container';
import { NavList } from '../../shared/components/nav-list';
import { SearchInput } from '../../shared/components/search-input';
import { EncompassProfile } from './components/encompass-profile';
import { SearchResultsModal } from './components/search-results-modal';
import Svg from '../../images/Encompass_Logo.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ShareFeedbackButton } from './components/share-feedback-button';
import { getFilteredArraySrcByArrayWithIds } from '../../shared/functions/getFilteredArraySrcByArrayWithIds';
import { Member, AffinityGroup } from '../../types/graphql';
import { loadLocale } from '../../assets/locales/json/loadLocale';
import { PageNames, WebAnalytics } from 'src/util/WebAnalytics';
import { getNotSupportedLanguageDetected } from '../../shared/functions/getNotSupportedLanguageDetected';
import { logger } from '../../util/logger';
import { METRIC_NAME, PAGE_NAME } from 'src/util/types';
import { PopStarContext } from '../../context';

export type HomePageProps = {
    chapterList: Member['chapterList'];
    interestList: Member['interestList'];
    email: any;
    affinityGroups: AffinityGroup[];
};

export const HomePage = ({ chapterList, interestList, affinityGroups, email }: HomePageProps) => {
    const userAffinityGroups = getFilteredArraySrcByArrayWithIds({
        arrSrc: affinityGroups,
        arrWithIds: chapterList || [],
        nameId: 'affinityGroupId',
    });

    const userInterestLists = getFilteredArraySrcByArrayWithIds({
        arrSrc: affinityGroups,
        arrWithIds: interestList || [],
        nameId: 'affinityGroupId',
    });
    const navigate = useNavigate();

    const { t, i18n } = useTranslation();

    const { popStarFeatures } = useContext(PopStarContext);

    const isSearchVisible = popStarFeatures.isSearchVisible();

    const [isVisibleSearchResults, setIsVisibleSearchResults] = useState(false);
    const initialQuery = useRef('');

    useEffect(() => {
        loadLocale();
        getNotSupportedLanguageDetected(i18n);
    }, []);

    const logoAlternativeText = t('encompass_home_text1');
    const descriptionTitle = t('encompass_home_text2');
    const descriptionContent = t('encompass_home_text3');
    const placeholder = t('search_by_title_or_keyboard');
    const diversityResourcesTitle = t('encompass_home_text21');

    const affinityGroupCardLink = 'affinity-groups';
    const affinityGroupsCardTitle = t('encompass_home_text5');
    const affinityGroupsCardContent = t('encompass_home_text6');

    const inclusionCardLink = 'inclusion';
    const inclusionCardTitle = t('encompass_home_text7');
    const inclusionCardContent = t('encompass_home_text8');

    const resourcesCardLink = 'resources';
    const resourcesCardTitle = t('encompass_home_text9');
    const resourcesCardContent = t('encompass_home_text10');

    const careersCardLink = 'our-workplace';
    const careersCardTitle = t('encompass_home_text11');
    const careersCardContent = t('encompass_home_text12');

    const diversityCalendarCardLink = 'diversity-calendar';
    const diversityCalendarCardTitle = t('encompass_home_text13');
    const diversityCalendarCardContent = t('encompass_home_text14');

    const cards = [
        {
            id: '1',
            link: affinityGroupCardLink,
            title: affinityGroupsCardTitle,
            content: affinityGroupsCardContent,
        },
        {
            id: '2',
            link: inclusionCardLink,
            title: inclusionCardTitle,
            content: inclusionCardContent,
        },
        {
            id: '3',
            link: resourcesCardLink,
            title: resourcesCardTitle,
            content: resourcesCardContent,
        },
        {
            id: '4',
            link: careersCardLink,
            title: careersCardTitle,
            content: careersCardContent,
        },
        {
            id: '5',
            link: diversityCalendarCardLink,
            title: diversityCalendarCardTitle,
            content: diversityCalendarCardContent,
        },
    ];

    const handleSearch = (query: string) => {
        initialQuery.current = query;
        setIsVisibleSearchResults(true);
    };

    const handleCloseSearchResults = () => {
        setIsVisibleSearchResults(false);
    };

    WebAnalytics.registerPageView(PageNames.HOME_PAGE);

    const isProfile =
        (chapterList && Array.isArray(chapterList) && !!chapterList.length) ||
        (interestList && Array.isArray(interestList) && !!interestList.length) ||
        !!email;

    return (
        <PageContainer>
            <Flex flexDirection="column" backgroundColor="white" padding="S300">
                <Logo logo={Svg} logoAltText={logoAlternativeText} />
                <Description title={descriptionTitle} content={descriptionContent} />
                {isSearchVisible && (
                    <SearchInput
                        value={''}
                        placeholder={placeholder}
                        onSubmitFunction={handleSearch}
                    />
                )}
            </Flex>
            {isProfile && (
                <EncompassProfile
                    affinityGroups={userAffinityGroups}
                    interestLists={userInterestLists}
                    email={email}
                />
            )}
            <Flex flexDirection="column">
                <NavList title={diversityResourcesTitle} cards={cards} />
            </Flex>
            <ShareFeedbackButton />
            <SearchResultsModal
                initialQuery={initialQuery.current}
                isOpen={isVisibleSearchResults}
                onClose={handleCloseSearchResults}
            />
        </PageContainer>
    );
};
