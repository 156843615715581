import React from 'react';
import ReactDOM from 'react-dom';
import { KatSpinner } from '@amzn/katal-react';
import { StencilProvider } from '@amzn/stencil-react-components/context';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { I18nextProvider } from 'react-i18next';
import '@amzn/katal-components/styles.css';

import { store } from './app/store';
import i18n from './i18n';
import AppInitWrapper from './app/AppInitWrapper';
import App from './app/App';
import { logger } from './util/logger';
import { METRIC_NAME } from './util/types';
import './i18n';
import './index.scss';
import { PopStarContext, PopStarContextValues } from './context';
import { PopStarFeatures } from './feature-gates';
import { getApolloClient } from './apollo';

// The Suspense component causes React to display the fallback KatSpinner until
// the i18n translation strings have been loaded.

const ATOZ_ENCOMPASS_APP_ROOT = 'atoz-encompass-app-root';
const APP_ROOT = document.getElementById(ATOZ_ENCOMPASS_APP_ROOT);

const createContext = (): PopStarContextValues => ({
    popStarFeatures: new PopStarFeatures(APP_ROOT?.dataset?.popstarFeatures),
});

const context: PopStarContextValues = createContext();

const client = getApolloClient({
    employeeId: APP_ROOT?.dataset?.employeeId,
});

ReactDOM.render(
    <React.StrictMode>
        <React.Suspense fallback={<></>}>
            <Provider store={store}>
                <PopStarContext.Provider value={context}>
                    <ApolloProvider client={client}>
                        <AppInitWrapper>
                            <StencilProvider>
                                <I18nextProvider i18n={i18n}>
                                    <App />
                                </I18nextProvider>
                            </StencilProvider>
                        </AppInitWrapper>
                    </ApolloProvider>
                </PopStarContext.Provider>
            </Provider>
        </React.Suspense>
    </React.StrictMode>,
    APP_ROOT,
    () => {
        logger.emitCountMetric({ metricName: METRIC_NAME.APP_MOUNT });
    }
);

void logger.reportWebVitals('AtoZEncompassPage');
