/* eslint-disable */
//
// -------------------------------------------------------------------------------- 
// Do not edit this File manually. Run "bb codegen" to generate types automatically 
// ================================================================================ 
// 
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AffinityGroup = {
  __typename?: 'AffinityGroup';
  active: Scalars['Boolean'];
  description: Scalars['String'];
  descriptionTranslationKey: Scalars['String'];
  displayOrder: Scalars['Int'];
  id: Scalars['String'];
  link: Scalars['String'];
  logo: Scalars['String'];
  logoAltText: Scalars['String'];
  logoAltTextTranslationKey: Scalars['String'];
  name: Scalars['String'];
  nameTranslationKey: Scalars['String'];
};

export type AffinityGroupInput = {
  active: Scalars['Boolean'];
  id: Scalars['String'];
};

export enum AreaType {
  District = 'DISTRICT',
  Providence = 'PROVIDENCE',
  State = 'STATE'
}

export type Chapter = {
  __typename?: 'Chapter';
  active?: Maybe<Scalars['Boolean']>;
  affinityGroupId: Scalars['String'];
  chapterId: Scalars['String'];
  chapterLocation: ChapterLocation;
  chapterName: Scalars['String'];
  chapterType: ChapterType;
  founders?: Maybe<Array<Maybe<Scalars['String']>>>;
  teamLDAP?: Maybe<Scalars['String']>;
  workLocation: Scalars['String'];
};

export type ChapterInput = {
  affinityGroupId: Scalars['String'];
  id: Scalars['String'];
};

export type ChapterInterest = {
  __typename?: 'ChapterInterest';
  affinityGroupId: Scalars['String'];
};

export type ChapterInterestInput = {
  affinityGroupId: Scalars['String'];
};

export type ChapterLocation = {
  __typename?: 'ChapterLocation';
  area: Scalars['String'];
  areaType?: Maybe<AreaType>;
  country: Scalars['String'];
};

export enum ChapterStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum ChapterType {
  Node = 'NODE',
  Site = 'SITE'
}

export type Content = {
  __typename?: 'Content';
  author: Scalars['String'];
  blocks: Array<ContentBlock>;
  contentOrder?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Float'];
  description: Scalars['String'];
  id: Scalars['String'];
  language: Language;
  publishLocation: PublishLocation;
  status: ContentStatus;
  tags?: Maybe<Array<Tag>>;
  title: Scalars['String'];
  updatedAt: Scalars['Float'];
  version: Scalars['String'];
};

export type ContentBlock = {
  __typename?: 'ContentBlock';
  altText?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  order: Scalars['Int'];
  text: Scalars['String'];
  transcription?: Maybe<Scalars['String']>;
  type: ContentType;
  url?: Maybe<Scalars['String']>;
};

export enum ContentStatus {
  Donottranslate = 'DONOTTRANSLATE',
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Translating = 'TRANSLATING'
}

export enum ContentType {
  Image = 'IMAGE',
  Text = 'TEXT',
  Video = 'VIDEO'
}

export type DeleteResult = {
  __typename?: 'DeleteResult';
  result?: Maybe<Scalars['String']>;
};

export type Feedback = {
  __typename?: 'Feedback';
  contentId?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['String']>;
  feedbackType?: Maybe<FeedbackType>;
  feedbackValue?: Maybe<FeedbackValue>;
  timestamp?: Maybe<Scalars['String']>;
};

export type FeedbackInput = {
  contentId?: InputMaybe<Scalars['String']>;
  feedbackType?: InputMaybe<FeedbackType>;
  feedbackValue?: InputMaybe<FeedbackValue>;
};

export enum FeedbackType {
  Heart = 'heart',
  Thumb = 'thumb'
}

export enum FeedbackValue {
  Dislike = 'dislike',
  Enable = 'enable',
  Like = 'like'
}

export enum Language {
  En = 'EN',
  Es = 'ES'
}

export type Member = {
  __typename?: 'Member';
  chapterList?: Maybe<Array<Maybe<MemberChapterAssoc>>>;
  employeeId: Scalars['Int'];
  interestList?: Maybe<Array<Maybe<ChapterInterest>>>;
  isSiteAllowed?: Maybe<Scalars['Boolean']>;
  preferredEmail: Scalars['String'];
  workLocation?: Maybe<Scalars['String']>;
};

export type MemberChapterAssoc = {
  __typename?: 'MemberChapterAssoc';
  affinityGroupId: Scalars['String'];
  id: Scalars['String'];
};

export type MemberInput = {
  chapterList?: InputMaybe<Array<InputMaybe<ChapterInput>>>;
  interestList?: InputMaybe<Array<InputMaybe<ChapterInterestInput>>>;
  preferredEmail: Scalars['String'];
  workLocation?: InputMaybe<Scalars['String']>;
};

export type MembershipChapterListInput = {
  chapterList?: InputMaybe<Array<InputMaybe<ChapterInput>>>;
};

export type MembershipInterestListInput = {
  chapterInterestList: Array<InputMaybe<ChapterInterestInput>>;
};

export type MembershipPreferredEmailInput = {
  preferredEmail: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createFeedback?: Maybe<Feedback>;
  createFeedbackV1?: Maybe<Feedback>;
  createMember?: Maybe<Member>;
  createMemberV1?: Maybe<Member>;
  createSurvey?: Maybe<MutationResponse>;
  createSurveyV1?: Maybe<MutationResponse>;
  deleteFeedback?: Maybe<MutationResponse>;
  deleteFeedbackV1?: Maybe<MutationResponse>;
  deleteMember?: Maybe<DeleteResult>;
  updateChapters?: Maybe<Member>;
  updateChaptersV1?: Maybe<Member>;
  updateChaptersInterest?: Maybe<Member>;
  updateChaptersInterestV1?: Maybe<Member>;
  updatePreferredEmail?: Maybe<Member>;
  updatePreferredEmailV1?: Maybe<Member>;
};


export type MutationCreateFeedbackArgs = {
  input: FeedbackInput;
};

export type MutationCreateFeedbackV1Args = {
  input: FeedbackInput;
};

export type MutationCreateMemberArgs = {
  input: MemberInput;
};


export type MutationCreateMemberV1Args = {
  input: MemberInput;
};


export type MutationCreateSurveyArgs = {
  input: UserSurveyInput;
};

export type MutationCreateSurveyV1Args = {
  input: UserSurveyInput;
};

export type MutationDeleteFeedbackArgs = {
  contentId: Scalars['String'];
};

export type MutationDeleteFeedbackV1Args = {
  contentId: Scalars['String'];
};

export type MutationUpdateChaptersArgs = {
  input: MembershipChapterListInput;
};

export type MutationUpdateChaptersV1Args = {
  input: MembershipChapterListInput;
};

export type MutationUpdateChaptersInterestArgs = {
  input: MembershipInterestListInput;
};

export type MutationUpdateChaptersInterestV1Args = {
  input: MembershipInterestListInput;
};

export type MutationUpdatePreferredEmailArgs = {
  input: MembershipPreferredEmailInput;
};

export type MutationUpdatePreferredEmailV1Args = {
  input: MembershipPreferredEmailInput;
};

export type MutationResponse = {
  __typename?: 'MutationResponse';
  details?: Maybe<Scalars['String']>;
  mutationStatus: MutationStatus;
};

export enum MutationStatus {
  Error = 'ERROR',
  Ok = 'OK'
}

export type OsSearchResult = {
  __typename?: 'OSSearchResult';
  content: Array<SearchResultContent>;
  total?: Maybe<Scalars['Int']>;
};

export type PublishLocation = {
  __typename?: 'PublishLocation';
  order: Scalars['Int'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  batchGetFeedback?: Maybe<Array<Maybe<Feedback>>>;
  batchGetFeedbackV1?: Maybe<Array<Maybe<Feedback>>>;
  getChapterForSiteV1?: Maybe<Chapter>;
  getContentByIdAndLang: Content;
  getContentByIdAndLangV1: Content;
  getContentByPublishLocationAndLang?: Maybe<Array<Content>>;
  getContentByPublishLocationAndLangV1?: Maybe<Array<Content>>;
  getContentByTermAndLang: OsSearchResult;
  getMember?: Maybe<Member>;
  getMemberV1?: Maybe<Member>;
  getPapiEmployeeInfoV1?: Maybe<Member>;
  getSurvey?: Maybe<Survey>;
  listAffinityGroupsV1: Array<Maybe<AffinityGroup>>;
  fetchOrCreateMemberV1: Maybe<Member>;
};

export type QueryBatchGetFeedbackArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryBatchGetFeedbackV1Args = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetChapterForSiteV1Args = {
  affinityGroupId?: InputMaybe<Scalars['String']>;
  workLocation?: InputMaybe<Scalars['String']>;
};


export type QueryGetContentByIdAndLangArgs = {
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Language>;
};


export type QueryGetContentByIdAndLangV1Args = {
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Language>;
};


export type QueryGetContentByPublishLocationAndLangArgs = {
  language?: InputMaybe<Language>;
  publishLocation?: InputMaybe<Scalars['String']>;
};


export type QueryGetContentByPublishLocationAndLangV1Args = {
  language?: InputMaybe<Language>;
  publishLocation?: InputMaybe<Scalars['String']>;
};


export type QueryGetContentByTermAndLangArgs = {
  from?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Language>;
  size?: InputMaybe<Scalars['String']>;
  term?: InputMaybe<Scalars['String']>;
};

export type Question = {
  __typename?: 'Question';
  answers: Array<Scalars['String']>;
  description: Scalars['String'];
  multiselect: Scalars['Boolean'];
  questionId: Scalars['String'];
};

export type SearchResultContent = {
  __typename?: 'SearchResultContent';
  description: Scalars['String'];
  id: Scalars['String'];
  language?: Maybe<Language>;
  publishLocation: PublishLocation;
  score: Scalars['Float'];
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['Float']>;
};

export type Survey = {
  __typename?: 'Survey';
  questions?: Maybe<Array<Question>>;
  surveyId: Scalars['String'];
};

export type SurveyAnswerInput = {
  questionDescription: Scalars['String'];
  questionID: Scalars['String'];
  userSelection?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Tag = {
  __typename?: 'Tag';
  createdAt?: Maybe<Scalars['Float']>;
  description: Scalars['String'];
  language?: Maybe<Language>;
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['Float']>;
};

export type UserSurvey = {
  __typename?: 'UserSurvey';
  surveyAnswers?: Maybe<Array<UserSurveyAnswer>>;
  surveyId: Scalars['String'];
  timestamp: Scalars['String'];
};

export type UserSurveyAnswer = {
  __typename?: 'UserSurveyAnswer';
  question: Question;
  userSelection?: Maybe<Array<Scalars['String']>>;
};

export type UserSurveyAnswerInputDelete = {
  questionId: Scalars['String'];
  userSelection?: InputMaybe<Array<Scalars['String']>>;
};

export type UserSurveyInput = {
  surveyAnswer?: InputMaybe<Array<InputMaybe<SurveyAnswerInput>>>;
  surveyId: Scalars['String'];
};

export type GetContentByPublishLocationAndLangQueryVariables = Exact<{
  language?: InputMaybe<Language>;
  publishLocation?: InputMaybe<Scalars['String']>;
}>;


export type GetContentByPublishLocationAndLangQuery = { __typename?: 'Query', getContentByPublishLocationAndLang?: Array<{ __typename?: 'Content', id: string, author: string, title: string, description: string, language: Language, status: ContentStatus, version: string, createdAt: number, updatedAt: number, contentOrder?: number | null, blocks: Array<{ __typename?: 'ContentBlock', id: string, order: number, text: string, altText?: string | null, url?: string | null, type: ContentType, transcription?: string | null }>, publishLocation: { __typename?: 'PublishLocation', order: number, title: string, url: string }, tags?: Array<{ __typename?: 'Tag', description: string, language?: Language | null, text: string }> | null }> | null };

export type GetContentByPublishLocationAndLangV1QueryVariables = Exact<{
  language?: InputMaybe<Language>;
  publishLocation?: InputMaybe<Scalars['String']>;
}>;


export type GetContentByPublishLocationAndLangV1Query = { __typename?: 'Query', getContentByPublishLocationAndLangV1?: Array<{ __typename?: 'Content', id: string, author: string, title: string, description: string, language: Language, status: ContentStatus, version: string, createdAt: number, updatedAt: number, contentOrder?: number | null, blocks: Array<{ __typename?: 'ContentBlock', id: string, order: number, text: string, altText?: string | null, url?: string | null, type: ContentType, transcription?: string | null }>, publishLocation: { __typename?: 'PublishLocation', order: number, title: string, url: string }, tags?: Array<{ __typename?: 'Tag', description: string, language?: Language | null, text: string }> | null }> | null };

export type ListAffinityGroupsV1QueryVariables = Exact<{ [key: string]: never; }>;


export type ListAffinityGroupsV1Query = { __typename?: 'Query', listAffinityGroupsV1: Array<{ __typename?: 'AffinityGroup', active: boolean, description: string, descriptionTranslationKey: string, displayOrder: number, id: string, link: string, logo: string, logoAltText: string, logoAltTextTranslationKey: string, name: string, nameTranslationKey: string } | null> };

export type CreateFeedbackMutationVariables = Exact<{
  feedback: FeedbackInput;
}>;

export type CreateFeedbackV1MutationVariables = Exact<{
  feedback: FeedbackInput;
}>;


export type CreateFeedbackMutation = { __typename?: 'Mutation', createFeedback?: { __typename?: 'Feedback', contentId?: string | null, feedbackType?: FeedbackType | null, feedbackValue?: FeedbackValue | null, timestamp?: string | null } | null };
export type CreateFeedbackV1Mutation = { __typename?: 'Mutation', createFeedbackV1?: { __typename?: 'Feedback', contentId?: string | null, feedbackType?: FeedbackType | null, feedbackValue?: FeedbackValue | null, timestamp?: string | null } | null };

export type DeleteFeedbackMutationVariables = Exact<{
  contentId: Scalars['String'];
}>;
export type DeleteFeedbackV1MutationVariables = Exact<{
  contentId: Scalars['String'];
}>;

export type DeleteFeedbackMutation = { __typename?: 'Mutation', deleteFeedback?: { __typename?: 'MutationResponse', details?: string | null, mutationStatus: MutationStatus } | null };
export type DeleteFeedbackV1Mutation = { __typename?: 'Mutation', deleteFeedbackV1?: { __typename?: 'MutationResponse', details?: string | null, mutationStatus: MutationStatus } | null };

export type BatchGetFeedbackQueryVariables = Exact<{
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;

export type BatchGetFeedbackV1QueryVariables = Exact<{
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;

export type BatchGetFeedbackQuery = { __typename?: 'Query', batchGetFeedback?: Array<{ __typename?: 'Feedback', contentId?: string | null, employeeId?: string | null, feedbackType?: FeedbackType | null, feedbackValue?: FeedbackValue | null, timestamp?: string | null } | null> | null };

export type BatchGetFeedbackV1Query = { __typename?: 'Query', batchGetFeedbackV1?: Array<{ __typename?: 'Feedback', contentId?: string | null, employeeId?: string | null, feedbackType?: FeedbackType | null, feedbackValue?: FeedbackValue | null, timestamp?: string | null } | null> | null };

export type GetContentByIdAndLangQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Language>;
}>;


export type GetContentByIdAndLangQuery = { __typename?: 'Query', getContentByIdAndLang: { __typename?: 'Content', id: string, author: string, language: Language, title: string, description: string, contentOrder?: number | null, blocks: Array<{ __typename?: 'ContentBlock', id: string, order: number, text: string, altText?: string | null, url?: string | null, type: ContentType, transcription?: string | null }>, tags?: Array<{ __typename?: 'Tag', description: string, language?: Language | null, text: string }> | null } };

export type GetContentByIdAndLangV1QueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Language>;
}>;


export type GetContentByIdAndLangV1Query = { __typename?: 'Query', getContentByIdAndLangV1: { __typename?: 'Content', id: string, author: string, language: Language, title: string, description: string, contentOrder?: number | null, blocks: Array<{ __typename?: 'ContentBlock', id: string, order: number, text: string, altText?: string | null, url?: string | null, type: ContentType, transcription?: string | null }>, tags?: Array<{ __typename?: 'Tag', description: string, language?: Language | null, text: string }> | null } };

export type CreateMemberMutationVariables = Exact<{
  input: MemberInput;
}>;


export type CreateMemberMutation = { __typename?: 'Mutation', createMember?: { __typename?: 'Member', employeeId: number, preferredEmail: string, workLocation?: string | null, chapterList?: Array<{ __typename?: 'MemberChapterAssoc', affinityGroupId: string, id: string } | null> | null, interestList?: Array<{ __typename?: 'ChapterInterest', affinityGroupId: string } | null> | null } | null };

export type CreateMemberV1MutationVariables = Exact<{
  input: MemberInput;
}>;


export type CreateMemberV1Mutation = { __typename?: 'Mutation', createMemberV1?: { __typename?: 'Member', employeeId: number, preferredEmail: string, workLocation?: string | null, chapterList?: Array<{ __typename?: 'MemberChapterAssoc', affinityGroupId: string, id: string } | null> | null, interestList?: Array<{ __typename?: 'ChapterInterest', affinityGroupId: string } | null> | null } | null };

export type DeleteMemberMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteMemberMutation = { __typename?: 'Mutation', deleteMember?: { __typename?: 'DeleteResult', result?: string | null } | null };

export type UpdatePreferredEmailMutationVariables = Exact<{
  input: MembershipPreferredEmailInput;
}>;

export type UpdatePreferredEmailV1MutationVariables = Exact<{
  input: MembershipPreferredEmailInput;
}>;

export type UpdatePreferredEmailMutation = { __typename?: 'Mutation', updatePreferredEmail?: { __typename?: 'Member', employeeId: number, preferredEmail: string, workLocation?: string | null, chapterList?: Array<{ __typename?: 'MemberChapterAssoc', id: string, affinityGroupId: string } | null> | null, interestList?: Array<{ __typename?: 'ChapterInterest', affinityGroupId: string } | null> | null } | null };
export type UpdatePreferredEmailV1Mutation = { __typename?: 'Mutation', updatePreferredEmailV1?: { __typename?: 'Member', employeeId: number, preferredEmail: string, workLocation?: string | null, chapterList?: Array<{ __typename?: 'MemberChapterAssoc', id: string, affinityGroupId: string } | null> | null, interestList?: Array<{ __typename?: 'ChapterInterest', affinityGroupId: string } | null> | null } | null };


export type UpdateChaptersMutationVariables = Exact<{
  input: MembershipChapterListInput;
}>;

export type UpdateChaptersV1MutationVariables = Exact<{
  input: MembershipChapterListInput;
}>;

export type UpdateChaptersMutation = { __typename?: 'Mutation', updateChapters?: { __typename?: 'Member', employeeId: number, preferredEmail: string, workLocation?: string | null, chapterList?: Array<{ __typename?: 'MemberChapterAssoc', id: string, affinityGroupId: string } | null> | null, interestList?: Array<{ __typename?: 'ChapterInterest', affinityGroupId: string } | null> | null } | null };
export type UpdateChaptersV1Mutation = { __typename?: 'Mutation', updateChaptersV1?: { __typename?: 'Member', employeeId: number, preferredEmail: string, workLocation?: string | null, chapterList?: Array<{ __typename?: 'MemberChapterAssoc', id: string, affinityGroupId: string } | null> | null, interestList?: Array<{ __typename?: 'ChapterInterest', affinityGroupId: string } | null> | null } | null };


export type UpdateChaptersInterestMutationVariables = Exact<{
  input: MembershipInterestListInput;
}>;

export type UpdateChaptersInterestV1MutationVariables = Exact<{
  input: MembershipInterestListInput;
}>;

export type UpdateChaptersInterestMutation = { __typename?: 'Mutation', updateChaptersInterest?: { __typename?: 'Member', employeeId: number, preferredEmail: string, workLocation?: string | null, chapterList?: Array<{ __typename?: 'MemberChapterAssoc', id: string, affinityGroupId: string } | null> | null, interestList?: Array<{ __typename?: 'ChapterInterest', affinityGroupId: string } | null> | null } | null };
export type UpdateChaptersInterestV1Mutation = { __typename?: 'Mutation', updateChaptersInterestV1?: { __typename?: 'Member', employeeId: number, preferredEmail: string, workLocation?: string | null, chapterList?: Array<{ __typename?: 'MemberChapterAssoc', id: string, affinityGroupId: string } | null> | null, interestList?: Array<{ __typename?: 'ChapterInterest', affinityGroupId: string } | null> | null } | null };


export type GetChapterForSiteV1QueryVariables = Exact<{
  affinityGroupId?: InputMaybe<Scalars['String']>;
  workLocation?: InputMaybe<Scalars['String']>;
}>;


export type GetChapterForSiteV1Query = { __typename?: 'Query', getChapterForSiteV1?: { __typename?: 'Chapter', affinityGroupId: string, active?: boolean | null, chapterId: string, chapterName: string, chapterType: ChapterType, teamLDAP?: string | null, founders?: Array<string | null> | null, workLocation: string, chapterLocation: { __typename?: 'ChapterLocation', area: string, areaType?: AreaType | null, country: string } } | null };

export type GetMemberQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMemberQuery = { __typename?: 'Query', getMember?: { __typename?: 'Member', employeeId: number, preferredEmail: string, workLocation?: string | null, chapterList?: Array<{ __typename?: 'MemberChapterAssoc', id: string, affinityGroupId: string } | null> | null, interestList?: Array<{ __typename?: 'ChapterInterest', affinityGroupId: string } | null> | null } | null };

export type GetMemberV1QueryVariables = Exact<{ [key: string]: never; }>;


export type GetMemberV1Query = { __typename?: 'Query', getMemberV1?: { __typename?: 'Member', employeeId: number, preferredEmail: string, workLocation?: string | null, chapterList?: Array<{ __typename?: 'MemberChapterAssoc', id: string, affinityGroupId: string } | null> | null, interestList?: Array<{ __typename?: 'ChapterInterest', affinityGroupId: string } | null> | null } | null };

export type FetchOrCreateMemberV1QueryVariables = Exact<{ [key: string]: never; }>;

export type FetchOrCreateMemberV1Query = { __typename?: 'Query', fetchOrCreateMemberV1?: {
  __typename?: 'Member',
  employeeId: number,
  preferredEmail: string,
  workLocation?: string | null,
  chapterList?: Array<{ __typename?: 'MemberChapterAssoc', id: string, affinityGroupId: string } | null> | null,
  interestList?: Array<{ __typename?: 'ChapterInterest', affinityGroupId: string } | null> | null,
  isSiteAllowed: boolean | null;
  personId: string,
  firstName: string,
  lastName: string,
  jobProfileId: string,
  jobProfileName: string,
  tenureDays: string
  }
}

export type GetPapiEmployeeInfoV1QueryVariables = Exact<{ [key: string]: never; }>;


export type GetPapiEmployeeInfoV1Query = { __typename?: 'Query', getPapiEmployeeInfoV1?: { __typename?: 'Member', isSiteAllowed?: boolean | null } | null };

export type GetContentByTermAndLangQueryVariables = Exact<{
  language?: InputMaybe<Language>;
  term?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['String']>;
}>;


export type GetContentByTermAndLangQuery = { __typename?: 'Query', getContentByTermAndLang: { __typename?: 'OSSearchResult', total?: number | null, content: Array<{ __typename?: 'SearchResultContent', score: number, id: string, title: string, description: string, language?: Language | null, publishLocation: { __typename?: 'PublishLocation', title: string, url: string } }> } };

export type CreateSurveyMutationVariables = Exact<{
  userAnswers: UserSurveyInput;
}>;

export type CreateSurveyV1MutationVariables = Exact<{
  userAnswers: UserSurveyInput;
}>;


export type CreateSurveyMutation = { __typename?: 'Mutation', createSurvey?: { __typename?: 'MutationResponse', details?: string | null, mutationStatus: MutationStatus } | null };

export type CreateSurveyV1Mutation = { __typename?: 'Mutation', createSurveyV1?: { __typename?: 'MutationResponse', details?: string | null, mutationStatus: MutationStatus } | null };


export type GetSurveyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSurveyQuery = { __typename?: 'Query', getSurvey?: { __typename?: 'Survey', surveyId: string, questions?: Array<{ __typename?: 'Question', questionId: string, description: string, multiselect: boolean, answers: Array<string> }> | null } | null };


export const GetContentByPublishLocationAndLangDocument = gql`
    query getContentByPublishLocationAndLang($language: Language, $publishLocation: String) {
  getContentByPublishLocationAndLang(
    language: $language
    publishLocation: $publishLocation
  ) {
    id
    author
    blocks {
      id
      order
      text
      altText
      url
      type
      transcription
    }
    publishLocation {
      order
      title
      url
    }
    title
    description
    language
    tags {
      description
      language
      text
    }
    status
    version
    createdAt
    updatedAt
    contentOrder
  }
}
    `;

/**
 * __useGetContentByPublishLocationAndLangQuery__
 *
 * To run a query within a React component, call `useGetContentByPublishLocationAndLangQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentByPublishLocationAndLangQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentByPublishLocationAndLangQuery({
 *   variables: {
 *      language: // value for 'language'
 *      publishLocation: // value for 'publishLocation'
 *   },
 * });
 */
export function useGetContentByPublishLocationAndLangQuery(baseOptions?: Apollo.QueryHookOptions<GetContentByPublishLocationAndLangQuery, GetContentByPublishLocationAndLangQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContentByPublishLocationAndLangQuery, GetContentByPublishLocationAndLangQueryVariables>(GetContentByPublishLocationAndLangDocument, options);
      }
export function useGetContentByPublishLocationAndLangLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentByPublishLocationAndLangQuery, GetContentByPublishLocationAndLangQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContentByPublishLocationAndLangQuery, GetContentByPublishLocationAndLangQueryVariables>(GetContentByPublishLocationAndLangDocument, options);
        }
export type GetContentByPublishLocationAndLangQueryHookResult = ReturnType<typeof useGetContentByPublishLocationAndLangQuery>;
export type GetContentByPublishLocationAndLangLazyQueryHookResult = ReturnType<typeof useGetContentByPublishLocationAndLangLazyQuery>;
export type GetContentByPublishLocationAndLangQueryResult = Apollo.QueryResult<GetContentByPublishLocationAndLangQuery, GetContentByPublishLocationAndLangQueryVariables>;
export const GetContentByPublishLocationAndLangV1Document = gql`
    query getContentByPublishLocationAndLangV1($language: Language, $publishLocation: String) {
  getContentByPublishLocationAndLangV1(
    language: $language
    publishLocation: $publishLocation
  ) {
    id
    author
    blocks {
      id
      order
      text
      altText
      url
      type
      transcription
    }
    publishLocation {
      order
      title
      url
    }
    title
    description
    language
    tags {
      description
      language
      text
    }
    status
    version
    createdAt
    updatedAt
    contentOrder
  }
}
    `;

/**
 * __useGetContentByPublishLocationAndLangV1Query__
 *
 * To run a query within a React component, call `useGetContentByPublishLocationAndLangV1Query` and pass it any options that fit your needs.
 * When your component renders, `useGetContentByPublishLocationAndLangV1Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentByPublishLocationAndLangV1Query({
 *   variables: {
 *      language: // value for 'language'
 *      publishLocation: // value for 'publishLocation'
 *   },
 * });
 */
export function useGetContentByPublishLocationAndLangV1Query(baseOptions?: Apollo.QueryHookOptions<GetContentByPublishLocationAndLangV1Query, GetContentByPublishLocationAndLangV1QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContentByPublishLocationAndLangV1Query, GetContentByPublishLocationAndLangV1QueryVariables>(GetContentByPublishLocationAndLangV1Document, options);
      }
export function useGetContentByPublishLocationAndLangV1LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentByPublishLocationAndLangV1Query, GetContentByPublishLocationAndLangV1QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContentByPublishLocationAndLangV1Query, GetContentByPublishLocationAndLangV1QueryVariables>(GetContentByPublishLocationAndLangV1Document, options);
        }
export type GetContentByPublishLocationAndLangV1QueryHookResult = ReturnType<typeof useGetContentByPublishLocationAndLangV1Query>;
export type GetContentByPublishLocationAndLangV1LazyQueryHookResult = ReturnType<typeof useGetContentByPublishLocationAndLangV1LazyQuery>;
export type GetContentByPublishLocationAndLangV1QueryResult = Apollo.QueryResult<GetContentByPublishLocationAndLangV1Query, GetContentByPublishLocationAndLangV1QueryVariables>;
export const ListAffinityGroupsV1Document = gql`
    query listAffinityGroupsV1 {
  listAffinityGroupsV1 {
    active
    description
    descriptionTranslationKey
    displayOrder
    id
    link
    logo
    logoAltText
    logoAltTextTranslationKey
    name
    nameTranslationKey
  }
}
    `;

/**
 * __useListAffinityGroupsV1Query__
 *
 * To run a query within a React component, call `useListAffinityGroupsV1Query` and pass it any options that fit your needs.
 * When your component renders, `useListAffinityGroupsV1Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAffinityGroupsV1Query({
 *   variables: {
 *   },
 * });
 */
export function useListAffinityGroupsV1Query(baseOptions?: Apollo.QueryHookOptions<ListAffinityGroupsV1Query, ListAffinityGroupsV1QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAffinityGroupsV1Query, ListAffinityGroupsV1QueryVariables>(ListAffinityGroupsV1Document, options);
      }
export function useListAffinityGroupsV1LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAffinityGroupsV1Query, ListAffinityGroupsV1QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAffinityGroupsV1Query, ListAffinityGroupsV1QueryVariables>(ListAffinityGroupsV1Document, options);
        }
export type ListAffinityGroupsV1QueryHookResult = ReturnType<typeof useListAffinityGroupsV1Query>;
export type ListAffinityGroupsV1LazyQueryHookResult = ReturnType<typeof useListAffinityGroupsV1LazyQuery>;
export type ListAffinityGroupsV1QueryResult = Apollo.QueryResult<ListAffinityGroupsV1Query, ListAffinityGroupsV1QueryVariables>;
export const CreateFeedbackDocument = gql`
    mutation createFeedback($feedback: FeedbackInput!) {
  createFeedback(input: $feedback) {
    contentId
    feedbackType
    feedbackValue
    timestamp
  }
}
    `;

export const CreateFeedbackV1Document = gql`
    mutation createFeedbackV1($feedback: FeedbackInput!) {
  createFeedbackV1(input: $feedback) {
    contentId
    feedbackType
    feedbackValue
    timestamp
  }
}
    `;
export type CreateFeedbackMutationFn = Apollo.MutationFunction<CreateFeedbackMutation, CreateFeedbackMutationVariables>;
export type CreateFeedbackV1MutationFn = Apollo.MutationFunction<CreateFeedbackV1Mutation, CreateFeedbackV1MutationVariables>;


/**
 * __useCreateFeedbackMutation__
 *
 * To run a mutation, you first call `useCreateFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedbackMutation, { data, loading, error }] = useCreateFeedbackMutation({
 *   variables: {
 *      feedback: // value for 'feedback'
 *   },
 * });
 */
export function useCreateFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<CreateFeedbackMutation, CreateFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFeedbackMutation, CreateFeedbackMutationVariables>(CreateFeedbackDocument, options);
      }
export function useCreateFeedbackV1Mutation(baseOptions?: Apollo.MutationHookOptions<CreateFeedbackV1Mutation, CreateFeedbackV1MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFeedbackV1Mutation, CreateFeedbackV1MutationVariables>(CreateFeedbackV1Document, options);
      }
export type CreateFeedbackMutationHookResult = ReturnType<typeof useCreateFeedbackMutation>;
export type CreateFeedbackV1MutationHookResult = ReturnType<typeof useCreateFeedbackV1Mutation>;

export type CreateFeedbackMutationResult = Apollo.MutationResult<CreateFeedbackMutation>;
export type CreateFeedbackV1MutationResult = Apollo.MutationResult<CreateFeedbackV1Mutation>;

export type CreateFeedbackMutationOptions = Apollo.BaseMutationOptions<CreateFeedbackMutation, CreateFeedbackMutationVariables>;
export type CreateFeedbackV1MutationOptions = Apollo.BaseMutationOptions<CreateFeedbackV1Mutation, CreateFeedbackV1MutationVariables>;

export const DeleteFeedbackDocument = gql`
    mutation deleteFeedback($contentId: String!) {
  deleteFeedback(contentId: $contentId) {
    details
    mutationStatus
  }
}
    `;
export const DeleteFeedbackV1Document = gql`
    mutation deleteFeedbackV1($contentId: String!) {
  deleteFeedbackV1(contentId: $contentId) {
    details
    mutationStatus
  }
}
    `;
export type DeleteFeedbackMutationFn = Apollo.MutationFunction<DeleteFeedbackMutation, DeleteFeedbackMutationVariables>;
export type DeleteFeedbackV1MutationFn = Apollo.MutationFunction<DeleteFeedbackV1Mutation, DeleteFeedbackV1MutationVariables>;

/**
 * __useDeleteFeedbackMutation__
 *
 * To run a mutation, you first call `useDeleteFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFeedbackMutation, { data, loading, error }] = useDeleteFeedbackMutation({
 *   variables: {
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useDeleteFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFeedbackMutation, DeleteFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFeedbackMutation, DeleteFeedbackMutationVariables>(DeleteFeedbackDocument, options);
      }

export function useDeleteFeedbackV1Mutation(baseOptions?: Apollo.MutationHookOptions<DeleteFeedbackV1Mutation, DeleteFeedbackV1MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFeedbackV1Mutation, DeleteFeedbackV1MutationVariables>(DeleteFeedbackV1Document, options);
      }

export type DeleteFeedbackMutationHookResult = ReturnType<typeof useDeleteFeedbackMutation>;
export type DeleteFeedbackV1MutationHookResult = ReturnType<typeof useDeleteFeedbackV1Mutation>;

export type DeleteFeedbackMutationResult = Apollo.MutationResult<DeleteFeedbackMutation>;
export type DeleteFeedbackV1MutationResult = Apollo.MutationResult<DeleteFeedbackV1Mutation>;

export type DeleteFeedbackMutationOptions = Apollo.BaseMutationOptions<DeleteFeedbackMutation, DeleteFeedbackMutationVariables>;
export type DeleteFeedbackV1MutationOptions = Apollo.BaseMutationOptions<DeleteFeedbackV1Mutation, DeleteFeedbackV1MutationVariables>;

export const BatchGetFeedbackDocument = gql`
    query batchGetFeedback($ids: [String]) {
  batchGetFeedback(ids: $ids) {
    contentId
    employeeId
    feedbackType
    feedbackValue
    timestamp
  }
}
    `;

export const BatchGetFeedbackV1Document = gql`
    query batchGetFeedbackV1($ids: [String]) {
  batchGetFeedbackV1(ids: $ids) {
    contentId
    employeeId
    feedbackType
    feedbackValue
    timestamp
  }
}
    `;

/**
 * __useBatchGetFeedbackQuery__
 *
 * To run a query within a React component, call `useBatchGetFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useBatchGetFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBatchGetFeedbackQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useBatchGetFeedbackQuery(baseOptions?: Apollo.QueryHookOptions<BatchGetFeedbackQuery, BatchGetFeedbackQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BatchGetFeedbackQuery, BatchGetFeedbackQueryVariables>(BatchGetFeedbackDocument, options);
      }
export function useBatchGetFeedbackV1Query(baseOptions?: Apollo.QueryHookOptions<BatchGetFeedbackV1Query, BatchGetFeedbackV1QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BatchGetFeedbackV1Query, BatchGetFeedbackV1QueryVariables>(BatchGetFeedbackV1Document, options);
      }

export function useBatchGetFeedbackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BatchGetFeedbackQuery, BatchGetFeedbackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BatchGetFeedbackQuery, BatchGetFeedbackQueryVariables>(BatchGetFeedbackDocument, options);
        }

export function useBatchGetFeedbackV1LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BatchGetFeedbackV1Query, BatchGetFeedbackV1QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BatchGetFeedbackV1Query, BatchGetFeedbackV1QueryVariables>(BatchGetFeedbackV1Document, options);
        }
export type BatchGetFeedbackQueryHookResult = ReturnType<typeof useBatchGetFeedbackQuery>;
export type BatchGetFeedbackV1QueryHookResult = ReturnType<typeof useBatchGetFeedbackV1Query>;

export type BatchGetFeedbackLazyQueryHookResult = ReturnType<typeof useBatchGetFeedbackLazyQuery>;
export type BatchGetFeedbackV1LazyQueryHookResult = ReturnType<typeof useBatchGetFeedbackV1LazyQuery>;

export type BatchGetFeedbackQueryResult = Apollo.QueryResult<BatchGetFeedbackQuery, BatchGetFeedbackQueryVariables>;
export type BatchGetFeedbackV1QueryResult = Apollo.QueryResult<BatchGetFeedbackV1Query, BatchGetFeedbackV1QueryVariables>;

export const GetContentByIdAndLangDocument = gql`
    query getContentByIdAndLang($id: String, $language: Language) {
  getContentByIdAndLang(id: $id, language: $language) {
    id
    author
    language
    title
    description
    blocks {
      id
      order
      text
      altText
      url
      type
      transcription
    }
    tags {
      description
      language
      text
    }
    contentOrder
  }
}
    `;

/**
 * __useGetContentByIdAndLangQuery__
 *
 * To run a query within a React component, call `useGetContentByIdAndLangQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentByIdAndLangQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentByIdAndLangQuery({
 *   variables: {
 *      id: // value for 'id'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetContentByIdAndLangQuery(baseOptions?: Apollo.QueryHookOptions<GetContentByIdAndLangQuery, GetContentByIdAndLangQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContentByIdAndLangQuery, GetContentByIdAndLangQueryVariables>(GetContentByIdAndLangDocument, options);
      }
export function useGetContentByIdAndLangLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentByIdAndLangQuery, GetContentByIdAndLangQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContentByIdAndLangQuery, GetContentByIdAndLangQueryVariables>(GetContentByIdAndLangDocument, options);
        }
export type GetContentByIdAndLangQueryHookResult = ReturnType<typeof useGetContentByIdAndLangQuery>;
export type GetContentByIdAndLangLazyQueryHookResult = ReturnType<typeof useGetContentByIdAndLangLazyQuery>;
export type GetContentByIdAndLangQueryResult = Apollo.QueryResult<GetContentByIdAndLangQuery, GetContentByIdAndLangQueryVariables>;
export const GetContentByIdAndLangV1Document = gql`
    query getContentByIdAndLangV1($id: String, $language: Language) {
  getContentByIdAndLangV1(id: $id, language: $language) {
    id
    author
    language
    title
    description
    blocks {
      id
      order
      text
      altText
      url
      type
      transcription
    }
    tags {
      description
      language
      text
    }
    contentOrder
  }
}
    `;

/**
 * __useGetContentByIdAndLangV1Query__
 *
 * To run a query within a React component, call `useGetContentByIdAndLangV1Query` and pass it any options that fit your needs.
 * When your component renders, `useGetContentByIdAndLangV1Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentByIdAndLangV1Query({
 *   variables: {
 *      id: // value for 'id'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetContentByIdAndLangV1Query(baseOptions?: Apollo.QueryHookOptions<GetContentByIdAndLangV1Query, GetContentByIdAndLangV1QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContentByIdAndLangV1Query, GetContentByIdAndLangV1QueryVariables>(GetContentByIdAndLangV1Document, options);
      }
export function useGetContentByIdAndLangV1LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentByIdAndLangV1Query, GetContentByIdAndLangV1QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContentByIdAndLangV1Query, GetContentByIdAndLangV1QueryVariables>(GetContentByIdAndLangV1Document, options);
        }
export type GetContentByIdAndLangV1QueryHookResult = ReturnType<typeof useGetContentByIdAndLangV1Query>;
export type GetContentByIdAndLangV1LazyQueryHookResult = ReturnType<typeof useGetContentByIdAndLangV1LazyQuery>;
export type GetContentByIdAndLangV1QueryResult = Apollo.QueryResult<GetContentByIdAndLangV1Query, GetContentByIdAndLangV1QueryVariables>;
export const CreateMemberDocument = gql`
    mutation createMember($input: MemberInput!) {
  createMember(input: $input) {
    chapterList {
      affinityGroupId
      id
    }
    employeeId
    interestList {
      affinityGroupId
    }
    preferredEmail
    workLocation
  }
}
    `;
export type CreateMemberMutationFn = Apollo.MutationFunction<CreateMemberMutation, CreateMemberMutationVariables>;

/**
 * __useCreateMemberMutation__
 *
 * To run a mutation, you first call `useCreateMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberMutation, { data, loading, error }] = useCreateMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMemberMutation(baseOptions?: Apollo.MutationHookOptions<CreateMemberMutation, CreateMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMemberMutation, CreateMemberMutationVariables>(CreateMemberDocument, options);
      }
export type CreateMemberMutationHookResult = ReturnType<typeof useCreateMemberMutation>;
export type CreateMemberMutationResult = Apollo.MutationResult<CreateMemberMutation>;
export type CreateMemberMutationOptions = Apollo.BaseMutationOptions<CreateMemberMutation, CreateMemberMutationVariables>;
export const CreateMemberV1Document = gql`
    mutation createMemberV1($input: MemberInput!) {
  createMemberV1(input: $input) {
    chapterList {
      affinityGroupId
      id
    }
    employeeId
    interestList {
      affinityGroupId
    }
    preferredEmail
    workLocation
  }
}
    `;
export type CreateMemberV1MutationFn = Apollo.MutationFunction<CreateMemberV1Mutation, CreateMemberV1MutationVariables>;

/**
 * __useCreateMemberV1Mutation__
 *
 * To run a mutation, you first call `useCreateMemberV1Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberV1Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberV1Mutation, { data, loading, error }] = useCreateMemberV1Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMemberV1Mutation(baseOptions?: Apollo.MutationHookOptions<CreateMemberV1Mutation, CreateMemberV1MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMemberV1Mutation, CreateMemberV1MutationVariables>(CreateMemberV1Document, options);
      }
export type CreateMemberV1MutationHookResult = ReturnType<typeof useCreateMemberV1Mutation>;
export type CreateMemberV1MutationResult = Apollo.MutationResult<CreateMemberV1Mutation>;
export type CreateMemberV1MutationOptions = Apollo.BaseMutationOptions<CreateMemberV1Mutation, CreateMemberV1MutationVariables>;
export const DeleteMemberDocument = gql`
    mutation deleteMember {
  deleteMember {
    result
  }
}
    `;
export type DeleteMemberMutationFn = Apollo.MutationFunction<DeleteMemberMutation, DeleteMemberMutationVariables>;

/**
 * __useDeleteMemberMutation__
 *
 * To run a mutation, you first call `useDeleteMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMemberMutation, { data, loading, error }] = useDeleteMemberMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteMemberMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMemberMutation, DeleteMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMemberMutation, DeleteMemberMutationVariables>(DeleteMemberDocument, options);
      }
export type DeleteMemberMutationHookResult = ReturnType<typeof useDeleteMemberMutation>;
export type DeleteMemberMutationResult = Apollo.MutationResult<DeleteMemberMutation>;
export type DeleteMemberMutationOptions = Apollo.BaseMutationOptions<DeleteMemberMutation, DeleteMemberMutationVariables>;
export const UpdatePreferredEmailDocument = gql`
    mutation updatePreferredEmail($input: MembershipPreferredEmailInput!) {
  updatePreferredEmail(input: $input) {
    employeeId
    preferredEmail
    workLocation
    chapterList {
      id
      affinityGroupId
    }
    interestList {
      affinityGroupId
    }
  }
}
    `;

export const UpdatePreferredEmailV1Document = gql`
    mutation updatePreferredEmailV1($input: MembershipPreferredEmailInput!) {
  updatePreferredEmailV1(input: $input) {
    employeeId
    preferredEmail
    workLocation
    chapterList {
      id
      affinityGroupId
    }
    interestList {
      affinityGroupId
    }
  }
}
    `;


export type UpdatePreferredEmailMutationFn = Apollo.MutationFunction<UpdatePreferredEmailMutation, UpdatePreferredEmailMutationVariables>;
export type UpdatePreferredEmailV1MutationFn = Apollo.MutationFunction<UpdatePreferredEmailV1Mutation, UpdatePreferredEmailV1MutationVariables>;

/**
 * __useUpdatePreferredEmailMutation__
 *
 * To run a mutation, you first call `useUpdatePreferredEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePreferredEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePreferredEmailMutation, { data, loading, error }] = useUpdatePreferredEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePreferredEmailMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePreferredEmailMutation, UpdatePreferredEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePreferredEmailMutation, UpdatePreferredEmailMutationVariables>(UpdatePreferredEmailDocument, options);
      }

export function useUpdatePreferredEmailV1Mutation(baseOptions?: Apollo.MutationHookOptions<UpdatePreferredEmailV1Mutation, UpdatePreferredEmailV1MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePreferredEmailV1Mutation, UpdatePreferredEmailV1MutationVariables>(UpdatePreferredEmailV1Document, options);
      }
      
export type UpdatePreferredEmailMutationHookResult = ReturnType<typeof useUpdatePreferredEmailMutation>;
export type UpdatePreferredEmailV1MutationHookResult = ReturnType<typeof useUpdatePreferredEmailV1Mutation>;

export type UpdatePreferredEmailMutationResult = Apollo.MutationResult<UpdatePreferredEmailMutation>;
export type UpdatePreferredEmailV1MutationResult = Apollo.MutationResult<UpdatePreferredEmailV1Mutation>;

export type UpdatePreferredEmailMutationOptions = Apollo.BaseMutationOptions<UpdatePreferredEmailMutation, UpdatePreferredEmailMutationVariables>;
export type UpdatePreferredEmailV1MutationOptions = Apollo.BaseMutationOptions<UpdatePreferredEmailV1Mutation, UpdatePreferredEmailV1MutationVariables>;

export const UpdateChaptersDocument = gql`
    mutation updateChapters($input: MembershipChapterListInput!) {
  updateChapters(input: $input) {
    employeeId
    preferredEmail
    workLocation
    chapterList {
      id
      affinityGroupId
    }
    interestList {
      affinityGroupId
    }
  }
}
    `;

export const UpdateChaptersV1Document = gql`
    mutation updateChaptersV1($input: MembershipChapterListInput!) {
  updateChaptersV1(input: $input) {
    employeeId
    preferredEmail
    workLocation
    chapterList {
      id
      affinityGroupId
    }
    interestList {
      affinityGroupId
    }
  }
}
    `;

export type UpdateChaptersMutationFn = Apollo.MutationFunction<UpdateChaptersMutation, UpdateChaptersMutationVariables>;
export type UpdateChaptersV1MutationFn = Apollo.MutationFunction<UpdateChaptersV1Mutation, UpdateChaptersV1MutationVariables>;

/**
 * __useUpdateChaptersMutation__
 *
 * To run a mutation, you first call `useUpdateChaptersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChaptersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChaptersMutation, { data, loading, error }] = useUpdateChaptersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChaptersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChaptersMutation, UpdateChaptersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChaptersMutation, UpdateChaptersMutationVariables>(UpdateChaptersDocument, options);
      }

export function useUpdateChaptersV1Mutation(baseOptions?: Apollo.MutationHookOptions<UpdateChaptersV1Mutation, UpdateChaptersV1MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChaptersV1Mutation, UpdateChaptersV1MutationVariables>(UpdateChaptersV1Document, options);
      }

export type UpdateChaptersMutationHookResult = ReturnType<typeof useUpdateChaptersMutation>;
export type UpdateChaptersV1MutationHookResult = ReturnType<typeof useUpdateChaptersV1Mutation>;

export type UpdateChaptersMutationResult = Apollo.MutationResult<UpdateChaptersMutation>;
export type UpdateChaptersV1MutationResult = Apollo.MutationResult<UpdateChaptersV1Mutation>;

export type UpdateChaptersMutationOptions = Apollo.BaseMutationOptions<UpdateChaptersMutation, UpdateChaptersMutationVariables>;
export type UpdateChaptersV1MutationOptions = Apollo.BaseMutationOptions<UpdateChaptersV1Mutation, UpdateChaptersV1MutationVariables>;

export const UpdateChaptersInterestDocument = gql`
    mutation updateChaptersInterest($input: MembershipInterestListInput!) {
  updateChaptersInterest(input: $input) {
    employeeId
    preferredEmail
    workLocation
    chapterList {
      id
      affinityGroupId
    }
    interestList {
      affinityGroupId
    }
  }
}
    `;

export const UpdateChaptersInterestV1Document = gql`
    mutation updateChaptersInterestV1($input: MembershipInterestListInput!) {
  updateChaptersInterestV1(input: $input) {
    employeeId
    preferredEmail
    workLocation
    chapterList {
      id
      affinityGroupId
    }
    interestList {
      affinityGroupId
    }
  }
}
    `;

export type UpdateChaptersInterestMutationFn = Apollo.MutationFunction<UpdateChaptersInterestMutation, UpdateChaptersInterestMutationVariables>;
export type UpdateChaptersInterestV1MutationFn = Apollo.MutationFunction<UpdateChaptersInterestV1Mutation, UpdateChaptersInterestV1MutationVariables>;

/**
 * __useUpdateChaptersInterestMutation__
 *
 * To run a mutation, you first call `useUpdateChaptersInterestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChaptersInterestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChaptersInterestMutation, { data, loading, error }] = useUpdateChaptersInterestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChaptersInterestMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChaptersInterestMutation, UpdateChaptersInterestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChaptersInterestMutation, UpdateChaptersInterestMutationVariables>(UpdateChaptersInterestDocument, options);
      }
export function useUpdateChaptersInterestV1Mutation(baseOptions?: Apollo.MutationHookOptions<UpdateChaptersInterestV1Mutation, UpdateChaptersInterestV1MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChaptersInterestV1Mutation, UpdateChaptersInterestV1MutationVariables>(UpdateChaptersInterestV1Document, options);
      }

export type UpdateChaptersInterestMutationHookResult = ReturnType<typeof useUpdateChaptersInterestMutation>;
export type UpdateChaptersInterestV1MutationHookResult = ReturnType<typeof useUpdateChaptersInterestV1Mutation>;

export type UpdateChaptersInterestMutationResult = Apollo.MutationResult<UpdateChaptersInterestMutation>;
export type UpdateChaptersInterestV1MutationResult = Apollo.MutationResult<UpdateChaptersInterestV1Mutation>;

export type UpdateChaptersInterestMutationOptions = Apollo.BaseMutationOptions<UpdateChaptersInterestMutation, UpdateChaptersInterestMutationVariables>;
export type UpdateChaptersInterestV1MutationOptions = Apollo.BaseMutationOptions<UpdateChaptersInterestV1Mutation, UpdateChaptersInterestV1MutationVariables>;

export const GetChapterForSiteV1Document = gql`
    query getChapterForSiteV1($affinityGroupId: String, $workLocation: String) {
  getChapterForSiteV1(
    affinityGroupId: $affinityGroupId
    workLocation: $workLocation
  ) {
    affinityGroupId
    active
    chapterId
    chapterName
    chapterType
    teamLDAP
    founders
    workLocation
    chapterLocation {
      area
      areaType
      country
    }
  }
}
    `;

/**
 * __useGetChapterForSiteV1Query__
 *
 * To run a query within a React component, call `useGetChapterForSiteV1Query` and pass it any options that fit your needs.
 * When your component renders, `useGetChapterForSiteV1Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChapterForSiteV1Query({
 *   variables: {
 *      affinityGroupId: // value for 'affinityGroupId'
 *      workLocation: // value for 'workLocation'
 *   },
 * });
 */
export function useGetChapterForSiteV1Query(baseOptions?: Apollo.QueryHookOptions<GetChapterForSiteV1Query, GetChapterForSiteV1QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChapterForSiteV1Query, GetChapterForSiteV1QueryVariables>(GetChapterForSiteV1Document, options);
      }
export function useGetChapterForSiteV1LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChapterForSiteV1Query, GetChapterForSiteV1QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChapterForSiteV1Query, GetChapterForSiteV1QueryVariables>(GetChapterForSiteV1Document, options);
        }
export type GetChapterForSiteV1QueryHookResult = ReturnType<typeof useGetChapterForSiteV1Query>;
export type GetChapterForSiteV1LazyQueryHookResult = ReturnType<typeof useGetChapterForSiteV1LazyQuery>;
export type GetChapterForSiteV1QueryResult = Apollo.QueryResult<GetChapterForSiteV1Query, GetChapterForSiteV1QueryVariables>;
export const GetMemberDocument = gql`
    query getMember {
  getMember {
    employeeId
    preferredEmail
    workLocation
    chapterList {
      id
      affinityGroupId
    }
    interestList {
      affinityGroupId
    }
  }
}
    `;

/**
 * __useGetMemberQuery__
 *
 * To run a query within a React component, call `useGetMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberQuery(baseOptions?: Apollo.QueryHookOptions<GetMemberQuery, GetMemberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMemberQuery, GetMemberQueryVariables>(GetMemberDocument, options);
      }
export function useGetMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemberQuery, GetMemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMemberQuery, GetMemberQueryVariables>(GetMemberDocument, options);
        }
export type GetMemberQueryHookResult = ReturnType<typeof useGetMemberQuery>;
export type GetMemberLazyQueryHookResult = ReturnType<typeof useGetMemberLazyQuery>;
export type GetMemberQueryResult = Apollo.QueryResult<GetMemberQuery, GetMemberQueryVariables>;
export const GetMemberV1Document = gql`
    query getMemberV1 {
  getMemberV1 {
    employeeId
    preferredEmail
    workLocation
    chapterList {
      id
      affinityGroupId
    }
    interestList {
      affinityGroupId
    }
  }
}
    `;

/**
 * __useGetMemberV1Query__
 *
 * To run a query within a React component, call `useGetMemberV1Query` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberV1Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberV1Query({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberV1Query(baseOptions?: Apollo.QueryHookOptions<GetMemberV1Query, GetMemberV1QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMemberV1Query, GetMemberV1QueryVariables>(GetMemberV1Document, options);
      }
export function useGetMemberV1LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemberV1Query, GetMemberV1QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMemberV1Query, GetMemberV1QueryVariables>(GetMemberV1Document, options);
        }
export type GetMemberV1QueryHookResult = ReturnType<typeof useGetMemberV1Query>;
export type GetMemberV1LazyQueryHookResult = ReturnType<typeof useGetMemberV1LazyQuery>;
export type GetMemberV1QueryResult = Apollo.QueryResult<GetMemberV1Query, GetMemberV1QueryVariables>;

export const FetchOrCreateMemberV1Document = gql`
    query fetchOrCreateMemberV1 {
      fetchOrCreateMemberV1 {
        employeeId
        preferredEmail
        workLocation
        chapterList {
          id
          affinityGroupId
        }
        interestList {
          affinityGroupId
        }
        isSiteAllowed
      }
    }
`;

export function useFetchOrCreateMemberV1Query(baseOptions?: Apollo.QueryHookOptions<FetchOrCreateMemberV1Query, FetchOrCreateMemberV1QueryVariables>) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<FetchOrCreateMemberV1Query, FetchOrCreateMemberV1QueryVariables>(FetchOrCreateMemberV1Document, options);
}

export function useFetchOrCreateMemberV1LazyQuery(baseOptions?: Apollo.QueryHookOptions<FetchOrCreateMemberV1Query, FetchOrCreateMemberV1QueryVariables>) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<FetchOrCreateMemberV1Query,FetchOrCreateMemberV1QueryVariables>(FetchOrCreateMemberV1Document, options);
}
export type FetchOrCreateMemberV1QueryHookResult = ReturnType<typeof useFetchOrCreateMemberV1Query>;
export type FetchOrCreateMemberV1LazyQueryResult = ReturnType<typeof useFetchOrCreateMemberV1LazyQuery>;
export type FetchOrCreateMemberV1QueryResult = Apollo.QueryResult<FetchOrCreateMemberV1Query, FetchOrCreateMemberV1QueryVariables>;

export const GetPapiEmployeeInfoV1Document = gql`
    query getPapiEmployeeInfoV1 {
  getPapiEmployeeInfoV1 {
    isSiteAllowed
  }
}
    `;

/**
 * __useGetPapiEmployeeInfoV1Query__
 *
 * To run a query within a React component, call `useGetPapiEmployeeInfoV1Query` and pass it any options that fit your needs.
 * When your component renders, `useGetPapiEmployeeInfoV1Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPapiEmployeeInfoV1Query({
 *   variables: {
 *   },
 * });
 */
export function useGetPapiEmployeeInfoV1Query(baseOptions?: Apollo.QueryHookOptions<GetPapiEmployeeInfoV1Query, GetPapiEmployeeInfoV1QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPapiEmployeeInfoV1Query, GetPapiEmployeeInfoV1QueryVariables>(GetPapiEmployeeInfoV1Document, options);
      }
export function useGetPapiEmployeeInfoV1LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPapiEmployeeInfoV1Query, GetPapiEmployeeInfoV1QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPapiEmployeeInfoV1Query, GetPapiEmployeeInfoV1QueryVariables>(GetPapiEmployeeInfoV1Document, options);
        }
export type GetPapiEmployeeInfoV1QueryHookResult = ReturnType<typeof useGetPapiEmployeeInfoV1Query>;
export type GetPapiEmployeeInfoV1LazyQueryHookResult = ReturnType<typeof useGetPapiEmployeeInfoV1LazyQuery>;
export type GetPapiEmployeeInfoV1QueryResult = Apollo.QueryResult<GetPapiEmployeeInfoV1Query, GetPapiEmployeeInfoV1QueryVariables>;
export const GetContentByTermAndLangDocument = gql`
    query getContentByTermAndLang($language: Language, $term: String, $from: String, $size: String) {
  getContentByTermAndLang(
    language: $language
    term: $term
    from: $from
    size: $size
  ) {
    content {
      score
      id
      title
      description
      publishLocation {
        title
        url
      }
      language
    }
    total
  }
}
    `;

/**
 * __useGetContentByTermAndLangQuery__
 *
 * To run a query within a React component, call `useGetContentByTermAndLangQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentByTermAndLangQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentByTermAndLangQuery({
 *   variables: {
 *      language: // value for 'language'
 *      term: // value for 'term'
 *      from: // value for 'from'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useGetContentByTermAndLangQuery(baseOptions?: Apollo.QueryHookOptions<GetContentByTermAndLangQuery, GetContentByTermAndLangQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContentByTermAndLangQuery, GetContentByTermAndLangQueryVariables>(GetContentByTermAndLangDocument, options);
      }
export function useGetContentByTermAndLangLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentByTermAndLangQuery, GetContentByTermAndLangQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContentByTermAndLangQuery, GetContentByTermAndLangQueryVariables>(GetContentByTermAndLangDocument, options);
        }
export type GetContentByTermAndLangQueryHookResult = ReturnType<typeof useGetContentByTermAndLangQuery>;
export type GetContentByTermAndLangLazyQueryHookResult = ReturnType<typeof useGetContentByTermAndLangLazyQuery>;
export type GetContentByTermAndLangQueryResult = Apollo.QueryResult<GetContentByTermAndLangQuery, GetContentByTermAndLangQueryVariables>;
export const CreateSurveyDocument = gql`
    mutation createSurvey($userAnswers: UserSurveyInput!) {
  createSurvey(input: $userAnswers) {
    details
    mutationStatus
  }
}
    `;

export const CreateSurveyV1Document = gql`
mutation createSurveyV1($userAnswers: UserSurveyInput!) {
createSurveyV1(input: $userAnswers) {
details
mutationStatus
}
}
`;

export type CreateSurveyMutationFn = Apollo.MutationFunction<CreateSurveyMutation, CreateSurveyMutationVariables>;
export type CreateSurveyV1MutationFn = Apollo.MutationFunction<CreateSurveyV1Mutation, CreateSurveyV1MutationVariables>;


/**
 * __useCreateSurveyMutation__
 *
 * To run a mutation, you first call `useCreateSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSurveyMutation, { data, loading, error }] = useCreateSurveyMutation({
 *   variables: {
 *      userAnswers: // value for 'userAnswers'
 *   },
 * });
 */
export function useCreateSurveyMutation(baseOptions?: Apollo.MutationHookOptions<CreateSurveyMutation, CreateSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSurveyMutation, CreateSurveyMutationVariables>(CreateSurveyDocument, options);
      }
export type CreateSurveyMutationHookResult = ReturnType<typeof useCreateSurveyMutation>;
export type CreateSurveyMutationResult = Apollo.MutationResult<CreateSurveyMutation>;
export type CreateSurveyMutationOptions = Apollo.BaseMutationOptions<CreateSurveyMutation, CreateSurveyMutationVariables>;
export const GetSurveyDocument = gql`
    query getSurvey {
  getSurvey {
    surveyId
    questions {
      questionId
      description
      multiselect
      answers
    }
  }
}
    `;

export function useCreateSurveyV1Mutation(baseOptions?: Apollo.MutationHookOptions<CreateSurveyV1Mutation, CreateSurveyV1MutationVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<CreateSurveyV1Mutation, CreateSurveyV1MutationVariables>(CreateSurveyV1Document, options);
}
export type CreateSurveyV1MutationHookResult = ReturnType<typeof useCreateSurveyV1Mutation>;
export type CreateSurveyV1MutationResult = Apollo.MutationResult<CreateSurveyV1Mutation>;
export type CreateSurveyV1MutationOptions = Apollo.BaseMutationOptions<CreateSurveyV1Mutation, CreateSurveyV1MutationVariables>;
export const GetSurveyV1Document = gql`
query getSurveyV1 {
getSurvey {
surveyId
questions {
questionId
description
multiselect
answers
}
}
}
`;

/**
 * __useGetSurveyQuery__
 *
 * To run a query within a React component, call `useGetSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSurveyQuery(baseOptions?: Apollo.QueryHookOptions<GetSurveyQuery, GetSurveyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSurveyQuery, GetSurveyQueryVariables>(GetSurveyDocument, options);
      }
export function useGetSurveyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSurveyQuery, GetSurveyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSurveyQuery, GetSurveyQueryVariables>(GetSurveyDocument, options);
        }
export type GetSurveyQueryHookResult = ReturnType<typeof useGetSurveyQuery>;
export type GetSurveyLazyQueryHookResult = ReturnType<typeof useGetSurveyLazyQuery>;
export type GetSurveyQueryResult = Apollo.QueryResult<GetSurveyQuery, GetSurveyQueryVariables>;