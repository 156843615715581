import React from 'react';
import { useTranslation } from 'react-i18next';
import { SectionTitle } from '../../affinity-groups/components/section-title';
import { Flex, View } from '@amzn/stencil-react-components/layout';
import { Card } from '@amzn/stencil-react-components/card';
import { IconChevronRight } from '@amzn/stencil-react-components/icons';
import { Link as StencilLink } from '@amzn/stencil-react-components/link';
import { styledWithTheme } from '@amzn/stencil-react-components/context';
import { Hr } from '@amzn/stencil-react-components/layout';
import { ItemsDropdown } from './items-dropdown';
import { Link } from 'react-router-dom';
import { AffinityGroup } from 'src/types/graphql';

type EncompassProfileProps = {
    affinityGroups?: AffinityGroup[];
    interestLists?: AffinityGroup[];
    email?: string;
};

const StyledCard = styledWithTheme(Card)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    border: 'none!important',
}));

export const EncompassProfile = ({
    affinityGroups,
    interestLists,
    email,
}: EncompassProfileProps) => {
    const { t } = useTranslation();
    const sectionTitle = t('encompass_home_text4');
    const linkTitle = t('encompass_home_text22');
    const affinityGroupsTitle = t('encompass_home_text19');
    const interestsListTitle = t('encompass_home_text20');
    const link = 'profile';

    return (
        <Flex
            data-test="encompassProfile"
            flexDirection="column"
            padding="S100"
            rowGap="S200"
            backgroundColor="white"
        >
            <SectionTitle title={sectionTitle} />
            {affinityGroups && affinityGroups[0] !== undefined && affinityGroups.length > 0 && (
                <>
                    <Hr />
                    <ItemsDropdown
                        title={affinityGroupsTitle}
                        itemsArray={affinityGroups}
                        shouldExpandOnMount
                    />
                </>
            )}
            {interestLists && interestLists.length > 0 && (
                <>
                    <Hr />
                    <ItemsDropdown
                        title={interestsListTitle}
                        itemsArray={interestLists}
                        shouldExpandOnMount
                    />
                </>
            )}
            <Hr />
            <Link to={link} style={{ textDecoration: 'none', height: '60px' }}>
                <StyledCard>
                    <StencilLink
                        data-test="editProfile"
                        textDecoration="none"
                        style={{ fontSize: '16px', lineHeight: '24px', fontWeight: 400 }}
                    >
                        {linkTitle}
                    </StencilLink>
                    <View display="flex" alignItems="center">
                        <IconChevronRight />
                    </View>
                </StyledCard>
            </Link>
        </Flex>
    );
};
