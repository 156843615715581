import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import { getApolloClient } from '../../../apollo';
import {
    CreateMemberV1MutationVariables,
    CreateMemberV1Document,
    GetChapterForSiteV1Document,
    GetChapterForSiteV1QueryVariables,
    GetMemberV1Document,
    GetPapiEmployeeInfoV1Document,
    Query,
    UpdateChaptersV1Document,
    UpdateChaptersInterestV1Document,
    UpdateChaptersInterestV1MutationVariables,
    UpdateChaptersV1MutationVariables,
    UpdatePreferredEmailV1Document,
    UpdatePreferredEmailV1MutationVariables,
    FetchOrCreateMemberV1Document,
} from '../../../types/graphql';
import {
    isCreatingMemberOnBackend,
    isGettingChapterForSiteFromBackend,
    isGettingMemberFromBackend,
    isGettingPapiEmployeeInfoFromBackend,
    isUpdatingChaptersInterestOnBackend,
    isUpdatingChaptersOnBackend,
    isUpdatingPreferredEmailOnBackend,
} from '../../../flags/index';
import { getUniqArrBy } from '../../../shared/functions/getUniqArrBy';
import { ApolloError } from '@apollo/client';

interface ProfileState {
    alias: string;
    chaptersForSite: Query['getChapterForSiteV1'][];
    email: string;
    loading: boolean;
    userMember: Query['fetchOrCreateMemberV1'];
    isSiteAllowed: boolean;
}

const initialState: ProfileState = {
    email: '',
    alias: '',
    userMember: {
        employeeId: 0,
        preferredEmail: '',
        chapterList: [],
        interestList: [],
        workLocation: '',
    },
    chaptersForSite: [],
    loading: false,
    isSiteAllowed: false,
};

export const updatePreferredEmailV1 = createAsyncThunk(
    'profile/updatePreferredEmail',
    async (variables: UpdatePreferredEmailV1MutationVariables) => {
        const apolloClient = getApolloClient({
            isBetaDirectClient: isUpdatingPreferredEmailOnBackend(),
        });
        try {
            const { data } = await apolloClient.mutate({
                mutation: UpdatePreferredEmailV1Document,
                variables,
            });
            return Promise.resolve(data.updatePreferredEmailV1);
        } catch (error) {
            console.error('Error retrieving data', error);
        }
    }
);

export const updateChaptersInterestV1 = createAsyncThunk(
    'profile/updateChaptersInterest',
    async (variables: UpdateChaptersInterestV1MutationVariables) => {
        const apolloClient = getApolloClient({
            isBetaDirectClient: isUpdatingChaptersInterestOnBackend(),
        });
        try {
            const { data } = await apolloClient.mutate({
                mutation: UpdateChaptersInterestV1Document,
                variables,
            });
            return Promise.resolve(data.updateChaptersInterestV1);
        } catch (error) {
            console.error('Error retrieving data', error);
        }
    }
);

export const updateChaptersV1 = createAsyncThunk(
    'profile/updateChapters',
    async (variables: UpdateChaptersV1MutationVariables) => {
        const apolloClient = getApolloClient({
            isBetaDirectClient: isUpdatingChaptersOnBackend(),
        });
        try {
            const { data } = await apolloClient.mutate({
                mutation: UpdateChaptersV1Document,
                variables,
            });
            return Promise.resolve(data.updateChaptersV1);
        } catch (error) {
            console.error('Error retrieving data', error);
        }
    }
);

export const getChapterForSiteV1 = createAsyncThunk(
    'profile/getChapterForSiteV1',
    async (variables: GetChapterForSiteV1QueryVariables) => {
        const apolloClient = getApolloClient({
            isBetaDirectClient: isGettingChapterForSiteFromBackend(),
        });
        try {
            const { data } = await apolloClient.query({
                query: GetChapterForSiteV1Document,
                variables,
            });
            return Promise.resolve(data.getChapterForSiteV1);
        } catch (error) {
            console.error('Error retrieving data', error);
        }
    }
);

export const getMemberV1 = createAsyncThunk('profile/getMemberV1', async () => {
    const apolloClient = getApolloClient({
        isBetaDirectClient: isGettingMemberFromBackend(),
    });
    try {
        const { data } = await apolloClient.query({
            query: GetMemberV1Document,
        });
        return Promise.resolve(data.getMemberV1);
    } catch (error) {
        console.error('Error retrieving data', error);
    }
});

export const fetchOrCreateMemberV1 = createAsyncThunk('profile/fetchOrCreateMemberV1', async () => {
    const apolloClient = getApolloClient({
        isBetaDirectClient: isGettingMemberFromBackend(),
    });
    try {
        const { data } = await apolloClient.query({
            query: FetchOrCreateMemberV1Document,
        });
        return Promise.resolve(data.fetchOrCreateMemberV1);
    } catch (error) {
        console.error('Error retrieving data', error);
    }
});

export const getPapiEmployeeInfo = createAsyncThunk('profile/getPapiEmployeeInfo', async () => {
    const apolloClient = getApolloClient({
        isBetaDirectClient: isGettingPapiEmployeeInfoFromBackend(),
    });
    try {
        const { data, error } = await apolloClient.query({
            query: GetPapiEmployeeInfoV1Document,
        });

        return Promise.resolve(data.getPapiEmployeeInfoV1);
    } catch (error) {
        console.error('Error retrieving data', error);
        const apolloError = error as ApolloError;
    }
});

export const createMemberV1 = createAsyncThunk(
    'profile/createMemberV1',
    async (variables: CreateMemberV1MutationVariables) => {
        const apolloClient = getApolloClient({
            isBetaDirectClient: isCreatingMemberOnBackend(),
        });
        try {
            const { data } = await apolloClient.query({
                query: CreateMemberV1Document,
                variables: variables,
            });
            return Promise.resolve(data.createMemberV1);
        } catch (error) {
            console.error('Error retrieving data', error);
        }
    }
);

export const profileSlice = createSlice({
    name: 'profile',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createMemberV1.pending, (state) => {
                state.loading = true;
            })
            .addCase(createMemberV1.fulfilled, (state, action) => {
                state.loading = false;
                if (!action.payload) return;
                const {
                    payload: { __typename, ...userMember },
                } = action;

                state.userMember = userMember;
            })
            .addCase(createMemberV1.rejected, () => {
                console.log('fetch failed');
            });
        builder
            .addCase(updatePreferredEmailV1.pending, (state) => {
                state.loading = true;
            })
            .addCase(updatePreferredEmailV1.fulfilled, (state, action) => {
                state.loading = false;
                if (!action.payload) return;
                const {
                    payload: { __typename, ...userMember },
                } = action;
                state.userMember!.preferredEmail = userMember.preferredEmail;
                state.email = userMember.preferredEmail;
            })
            .addCase(updatePreferredEmailV1.rejected, () => {
                console.log('fetch failed');
            });
        builder
            .addCase(updateChaptersInterestV1.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateChaptersInterestV1.fulfilled, (state, action) => {
                state.loading = false;
                if (!action.payload) return;

                const {
                    payload: { __typename, ...userMember },
                } = action;

                state.userMember = userMember;
            })
            .addCase(updateChaptersInterestV1.rejected, () => {
                console.log('fetch failed');
            });
        builder
            .addCase(updateChaptersV1.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateChaptersV1.fulfilled, (state, action) => {
                state.loading = false;
                if (!action.payload) return;

                const {
                    payload: { __typename, ...userMember },
                } = action;

                state.userMember = state.userMember
                    ? { ...state.userMember, chapterList: userMember.chapterList }
                    : initialState.userMember;
            })
            .addCase(updateChaptersV1.rejected, () => {
                console.log('fetch failed');
            });
        builder
            .addCase(getChapterForSiteV1.pending, (state) => {
                state.loading = true;
            })
            .addCase(getChapterForSiteV1.fulfilled, (state, action) => {
                state.loading = false;
                if (!action.payload) return;

                state.chaptersForSite = getUniqArrBy(
                    ['affinityGroupId'],
                    [...state.chaptersForSite, action.payload]
                );
            })
            .addCase(getChapterForSiteV1.rejected, () => {
                console.log('fetch failed');
            });
        builder
            .addCase(getMemberV1.pending, (state) => {
                state.loading = true;
            })
            .addCase(getMemberV1.fulfilled, (state, action) => {
                state.loading = false;
                if (!action.payload) return;

                const {
                    payload: { __typename, ...userMember },
                } = action;
                state.userMember = userMember;
            })
            .addCase(getMemberV1.rejected, () => {
                console.log('fetch failed');
            });
        builder
            .addCase(fetchOrCreateMemberV1.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchOrCreateMemberV1.fulfilled, (state, action) => {
                state.loading = false;
                if (!action.payload) return;

                const {
                    payload: { __typename, ...userMember },
                } = action;
                state.userMember = userMember;
            })
            .addCase(fetchOrCreateMemberV1.rejected, () => {
                console.log('fetch failed');
            });
        builder
            .addCase(getPapiEmployeeInfo.pending, (state) => {
                state.loading = true;
            })
            .addCase(getPapiEmployeeInfo.fulfilled, (state, action) => {
                state.loading = false;
                if (!action.payload) return;

                const {
                    payload: { __typename, ...userMember },
                } = action;
                state.isSiteAllowed = userMember.isSiteAllowed;
            })
            .addCase(getPapiEmployeeInfo.rejected, () => {
                console.log('fetch failed');
            });
    },
});

export const profileReducer = profileSlice.reducer;
