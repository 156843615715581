import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PageContainer } from '../../shared/components/page-container';
import { Flex, View } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import { AffinityGroupDropdown } from './components/affinity-group-dropdown';
import { EmailSection } from './components/email-section';
import { AffinityGroup } from '../../types/graphql';
import { Spinner } from 'src/shared/components/spinner';
import { PageNames, WebAnalytics } from 'src/util/WebAnalytics';
import { logger } from '../../util/logger';
import { METRIC_NAME, PAGE_NAME } from 'src/util/types';

type ProfileProps = {
    loading: boolean;
    preferredEmail: string;
    userAffinityGroups: AffinityGroup[];
    userInterestLists: AffinityGroup[];
    handleChaptersInterestLeaveConfirmation: (affinityGroup: AffinityGroup) => void;
    handleEmailChangeConfirmation: (preferredEmailIn: string) => void;
    handleChaptersLeaveConfirmation: (affinityGroup: AffinityGroup) => void;
};

export const Profile = ({
    loading,
    preferredEmail,
    userAffinityGroups,
    userInterestLists,
    handleEmailChangeConfirmation,
    handleChaptersInterestLeaveConfirmation,
    handleChaptersLeaveConfirmation,
}: ProfileProps) => {
    const { t } = useTranslation();
    const encompassTitle = t('encompass_profile_text1');
    const affinityGroupsTitle = t('encompass_home_text19');
    const interestsListTitle = t('encompass_home_text20');

    WebAnalytics.registerPageView(PageNames.PROFILE_PAGE);

    if (loading) {
        return <Spinner />;
    }
    return (
        <PageContainer>
            <Flex flexDirection="column" rowGap="S200" width="100%">
                <View backgroundColor="white" padding="S300" style={{ padding: '16px' }}>
                    <Text style={{ fontSize: '22px', lineHeight: '32px', fontWeight: '500' }}>
                        {encompassTitle}
                    </Text>
                </View>
                <EmailSection email={preferredEmail} onSubmit={handleEmailChangeConfirmation} />
                <View>
                    {userAffinityGroups && userAffinityGroups.length > 0 && (
                        <View backgroundColor="white" padding="S200">
                            <AffinityGroupDropdown
                                title={affinityGroupsTitle}
                                itemsArray={userAffinityGroups}
                                onSubmit={handleChaptersLeaveConfirmation}
                            />
                        </View>
                    )}
                    {userInterestLists && userInterestLists.length > 0 && (
                        <View backgroundColor="white" padding="S200">
                            <AffinityGroupDropdown
                                title={interestsListTitle}
                                itemsArray={userInterestLists}
                                onSubmit={handleChaptersInterestLeaveConfirmation}
                            />
                        </View>
                    )}
                </View>
            </Flex>
        </PageContainer>
    );
};
