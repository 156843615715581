import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getApolloClient } from '../../../apollo';
import { ListAffinityGroupsV1Document, AffinityGroup } from '../../../types/graphql';
import { isGettingAffinityGroupsFromBackend } from '../../../flags/index';
export interface AffinityGroupsState {
    affinityGroups: AffinityGroup[];
    loading: boolean;
}

const initialState: AffinityGroupsState = {
    affinityGroups: [],
    loading: false,
};

export const selectAffinityGroup = createAction('affinityGroups/select');

export const loadAffinityGroups = createAsyncThunk(
    'affinityGroups/loadAffinityGroups',
    async () => {
        const apolloClient = getApolloClient({
            isBetaDirectClient: isGettingAffinityGroupsFromBackend(),
        });
        try {
            const { data } = await apolloClient.query({
                query: ListAffinityGroupsV1Document,
            });
            return Promise.resolve(data.listAffinityGroupsV1);
        } catch (error) {
            console.error('Error retrieving data', error);
        }
    }
);

export const affinityGroupsSlice = createSlice({
    name: 'affinityGroups',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loadAffinityGroups.pending, (state) => {
                state.loading = true;
            })
            .addCase(loadAffinityGroups.fulfilled, (state, action) => {
                state.loading = false;
                if (!action.payload) return;

                const payloadNext: AffinityGroup[] = action.payload
                    .sort(
                        (aAffinityGroup: AffinityGroup, bAffinityGroup: AffinityGroup) =>
                            aAffinityGroup.displayOrder - bAffinityGroup.displayOrder
                    )
                    .map((affinityGroup: AffinityGroup) => {
                        const logoNext = affinityGroup.logo.split('.')[0];

                        const linkNext = affinityGroup.link
                            ? affinityGroup.link
                            : `/affinity-groups/${affinityGroup.id}`;

                        return {
                            ...affinityGroup,
                            logo: logoNext,
                            link: linkNext,
                        };
                    });
                const sortedAgs = [...payloadNext];
                state.affinityGroups = sortedAgs;
            })
            .addCase(loadAffinityGroups.rejected, () => {
                console.log('fetch failed');
            });
    },
});

export const affinityGroupsReducer = affinityGroupsSlice.reducer;
