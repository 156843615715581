import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { SignUp } from './sign-up.view';
import { useParams } from 'react-router-dom';

import { Spinner } from '../../shared/components/spinner';
import {
    UpdateChaptersInterestV1MutationVariables,
    UpdateChaptersV1MutationVariables,
    Member,
    Query,
    CreateMemberV1MutationVariables,
    UpdatePreferredEmailV1MutationVariables,
} from '../../types/graphql';
import { getArrObjectsNoTypeName } from '../../shared/functions/getArrObjectsNoTypeName';
import {
    getGroupWithExtraProps,
    AffinityGroupExtended,
} from '../../shared/functions/getGroupWithExtraProps';
import {
    updateChaptersInterestV1,
    updateChaptersV1,
    getChapterForSiteV1,
    updatePreferredEmailV1,
} from '../profile/slices/profile.slice';
import { getMetadata } from '../../shared/functions/getMetadata';
import { getNotSupportedLanguageDetected } from '../../shared/functions/getNotSupportedLanguageDetected';
import { useTranslation } from 'react-i18next';
import { METADATA_DEFAULT } from '../../../configuration/metadataDefault';
import { loadLocale } from 'src/assets/locales/json/loadLocale';

export interface HandlerToAffinityGroupArg {
    chapterList2?: Member['chapterList'];
    chaptersForSite2?: Query['getChapterForSiteV1'][];
    interestList2?: Member['interestList'];
    groupId2: string | undefined;
    email2: string | undefined;
}

export const SignUpContainer = () => {
    const [signed, setSigned] = useState(false);
    const dispatch = useDispatch();
    const { groupId } = useParams();
    const { i18n } = useTranslation();

    const {
        profile: { chaptersForSite, userMember, loading },
        affinityGroups: { affinityGroups },
    } = useSelector((state: RootState) => state);
    const interestList = userMember?.interestList;
    const chapterList = userMember?.chapterList;
    const workLocation = userMember?.workLocation;
    const email = userMember?.preferredEmail;

    function setEmailHandler() {}

    const affinityGroup: AffinityGroupExtended = getGroupWithExtraProps({
        groups: affinityGroups,
        id: groupId,
        list: chapterList,
        listIdName: 'affinityGroupId',
        chaptersForSite,
    });

    useEffect(() => {
        loadLocale();
        getNotSupportedLanguageDetected(i18n);
        const hasJoined = chapterList && !!chapterList.find((chapter) => chapter?.id === groupId);
        const isLoadedChapterForSite = chaptersForSite.find(
            (chapter2) => chapter2 && chapter2.affinityGroupId === groupId
        );
        if (isLoadedChapterForSite || hasJoined) return;

        let workLocationNext = workLocation;
        if (!workLocation) {
            const metadata = getMetadata(METADATA_DEFAULT);
            workLocationNext = metadata?.workLocation;
        }

        const variables = {
            affinityGroupId: groupId,
            workLocation: workLocationNext,
        };

        dispatch && dispatch(getChapterForSiteV1(variables));
    }, []);

    const signUpToAffinityGroup = ({
        chapterList2,
        chaptersForSite2,
        groupId2,
        email2,
    }: HandlerToAffinityGroupArg) => {
        const chapterForSite =
            chaptersForSite2 &&
            chaptersForSite2.find((chapter: any) => chapter.affinityGroupId === groupId2);

        if (!chapterForSite) return;

        if (email && email.length > 3) {
        const variables: UpdateChaptersV1MutationVariables = {
            input: {
                chapterList: [
                    ...getArrObjectsNoTypeName(chapterList2),
                    {
                        affinityGroupId: groupId,
                        id: chapterForSite && chapterForSite.chapterId,
                    },
                ],
            },
        };
        dispatch && dispatch(updateChaptersV1(variables));
        } else {
            const variables2: UpdatePreferredEmailV1MutationVariables = {
                input: { preferredEmail: email2 || '' },
            };
            dispatch && dispatch(updatePreferredEmailV1(variables2));
        }
    };

    const expressInterestToAffinityGroup = ({
        interestList2,
        groupId2,
        email2,
    }: HandlerToAffinityGroupArg) => {
        const variables: UpdateChaptersInterestV1MutationVariables = {
            input: {
                chapterInterestList: [
                    ...getArrObjectsNoTypeName(interestList2),
                    {
                        affinityGroupId: groupId2,
                    },
                ],
            },
        };
        dispatch && dispatch(updateChaptersInterestV1(variables));

        if (!email) {
            const variables2: UpdatePreferredEmailV1MutationVariables = {
                input: { preferredEmail: email2 || '' },
            };
            dispatch && dispatch(updatePreferredEmailV1(variables2));
        }
    };

    const handlerToAffinityGroupFn = (affinityGroup2: AffinityGroupExtended) => {
        if (affinityGroup2?.hasChapterForSite) {
            return signUpToAffinityGroup;
        } else {
            return expressInterestToAffinityGroup;
        }
    };

    const handlerToAffinityGroupArg = {
        chapterList2: chapterList,
        chaptersForSite2: chaptersForSite,
        interestList2: interestList,
        groupId2: groupId,
        email2: email,
    };

    const handlerToAffinityGroup = handlerToAffinityGroupFn(affinityGroup);

    if (loading) {
        return <Spinner />;
    } else {
        return (
            <SignUp
                handlerToAffinityGroupArg={handlerToAffinityGroupArg}
                logoAltText={affinityGroup?.logoAltText || ''}
                hasChapterForSite={affinityGroup?.hasChapterForSite || false}
                link={affinityGroup?.link || ''}
                logo={affinityGroup?.logo || ''}
                handlerToAffinityGroup={handlerToAffinityGroup}
                setMailHandler={setEmailHandler}
                signed={signed}
                setSigned={setSigned}
            />
        );
    }
};
