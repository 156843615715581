import React, { useMemo, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Flex, View } from '@amzn/stencil-react-components/layout';
import { Label, Text } from '@amzn/stencil-react-components/text';
import { Input, InputFooter } from '@amzn/stencil-react-components/form';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { IconPencil, IconCheck } from '@amzn/stencil-react-components/icons';

interface FormInput {
    email: string;
}

type EmailInputProps = {
    title: string;
    placeholder?: string;
    validationMessage: string;
    btnLabel?: string;
    email: string;
    btnIcon?: boolean;
    editOnly?: boolean;
    profileEmailInput?: boolean;
    submitHandler: (email: string) => void;
    changeHandler: (e: string) => void;
    saveHandler?: (e: string) => void;
};

export const EmailInput = ({
    submitHandler,
    changeHandler,
    saveHandler,
    email,
    placeholder,
    title,
    btnLabel,
    validationMessage,
    btnIcon,
    editOnly,
    profileEmailInput,
}: EmailInputProps) => {
    const [editing, setEditing] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            email,
        },
    });

    useEffect(() => {
        if (editing && document.getElementById('email')) {
            (document.getElementById('email') as HTMLInputElement).value = email;
        }
    }, [editing]);

    function onSubmit(data: FormInput) {
        submitHandler(data.email);
        setEditing(false);
        if (saveHandler) {
            saveHandler(data.email);
        }
    }

    const setEditMode = () => {
        setEditing(true);
    };

    const getIconBtn = useMemo(() => {
        if (editing) {
            return (
                <Button
                    data-test="submitEmailBtn"
                    type="submit"
                    icon={<IconCheck title="Save email edits" />}
                />
            );
        } else {
            return (
                <Button
                    data-test="editEmailBtn"
                    onClick={setEditMode}
                    icon={<IconPencil title="Edit email" />}
                />
            );
        }
    }, [editing]);

    return (
        <Flex
            data-test="emailInput"
            flexDirection="column"
            padding="S300"
            rowGap="S300"
            display="flex"
            justifyContent="center"
            minWidth="auto"
        >
            {profileEmailInput ? (
                <Text style={{ fontSize: '18px', lineHeight: '28px', fontWeight: '700' }}>
                    {title}
                </Text>
            ) : (
                <Text style={{ fontSize: '12px', lineHeight: '20px', fontWeight: '400' }}>
                    {title}
                </Text>
            )}
            {editOnly || editing ? (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Flex
                        rowGap="S200"
                        flexDirection={btnIcon ? 'row' : 'column'}
                        alignItems="center"
                        justifyContent="left"
                    >
                        <Col style={{ width: '100%' }}>
                            {/* {placeholder && email.length > 0 && (
              )} */}
                            <Label
                                htmlFor="email"
                                style={{ fontSize: '16px', lineHeight: '24px', fontWeight: '400' }}
                            >
                                {placeholder}
                            </Label>
                            <Input
                                id="email"
                                value={email}
                                aria-describedby="email-footer"
                                aria-invalid={errors.email ? true : false}
                                aria-label="E-mail"
                                type="email"
                                width="100%"
                                // @ts-ignore
                                enterKeyHint=""
                                minWidth="0"
                                autoFocus
                                placeholder={placeholder}
                                style={{ fontSize: '16px', lineHeight: '24px', fontWeight: '400' }}
                                error={errors.email ? true : false}
                                {...register('email', {
                                    required: {
                                        value: true,
                                        message: validationMessage,
                                    },
                                    pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: validationMessage,
                                    },
                                    onChange: (e) => {
                                        changeHandler(e.target.value);
                                        if (saveHandler) {
                                            saveHandler(e.target.value);
                                        }
                                    },
                                })}
                            />
                            {errors.email && (
                                <InputFooter
                                    id="email-footer"
                                    error
                                    width="100%"
                                    style={{
                                        fontSize: '14px !important',
                                        lineHeight: '22px',
                                        fontWeight: '400',
                                    }}
                                >
                                    {validationMessage}
                                </InputFooter>
                            )}
                        </Col>
                        {btnLabel && (
                            <Button
                                data-test="emailInputButton"
                                type="submit"
                                variant={ButtonVariant.Primary}
                                style={{ width: '100%' }}
                            >
                                <Text
                                    style={{
                                        fontSize: '16px',
                                        lineHeight: '24px',
                                        fontWeight: '500',
                                    }}
                                >
                                    {btnLabel}
                                </Text>
                            </Button>
                        )}
                        {btnIcon && <View margin="S100">{getIconBtn}</View>}
                    </Flex>
                </form>
            ) : (
                <Flex flexDirection="row" alignItems="center" justifyContent="left">
                    <Text
                        data-test="email"
                        style={{
                            wordBreak: 'break-all',
                            width: '100%',
                            maxWidth: '300px',
                            fontSize: '16px',
                            lineHeight: '24px',
                            fontWeight: '400',
                        }}
                    >
                        {email}
                    </Text>
                    {btnIcon && <View>{getIconBtn}</View>}
                </Flex>
            )}
        </Flex>
    );
};
