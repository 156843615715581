import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { Spinner } from '../../shared/components/spinner';
import { useGetContentByPublishLocationAndLangV1Query } from '../../types/graphql';
import { loadLocale } from '../../assets/locales/json/loadLocale';
import { getLanguage } from '../../shared/functions/getLanguage';
import { getNotSupportedLanguageDetected } from '../../shared/functions/getNotSupportedLanguageDetected';
import { useTranslation } from 'react-i18next';
import { ContentList, ContentListProps } from 'src/features/content-list/content-list.view';
import { Text } from '@amzn/stencil-react-components/text';

export const ContentListContainer = (): JSX.Element => {
    const {
        profile: { loading },
    } = useSelector((state: RootState) => state);
    const { i18n, t } = useTranslation();
    const noContentFound = t('no_content_found');

    const { data } = useGetContentByPublishLocationAndLangV1Query({
        variables: {
            publishLocation: window.location.pathname,
            language: getLanguage(),
        },
    });

    useEffect(() => {
        loadLocale();
        getNotSupportedLanguageDetected(i18n);
    }, []);

    const contentListProps: ContentListProps | null = data?.getContentByPublishLocationAndLangV1
        ? {
              cards: data.getContentByPublishLocationAndLangV1?.sort(
                  (a, b) => a.contentOrder! - b.contentOrder!
              ),
          }
        : null;

    if (loading || data === undefined) {
        return <Spinner />;
    } else if (!loading && contentListProps) {
        return <ContentList {...contentListProps} />;
    } else {
        return (
            <Text style={{ fontSize: '16px', fontWeight: '400', lineHeight: '24px' }}>
                {noContentFound}
            </Text>
        );
    }
};
