/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HomePageContainer } from '../features/homepage/home-page.container';
import { AffinityGroupsContainer } from '../features/affinity-groups/affinity-groups.container';
import { SignUpContainer } from 'src/features/sign-up/sign-up.container';
import { ProfileContainer } from 'src/features/profile/profile.container';
import { SurveyContainer } from 'src/features/survey/survey.container';
import { ContentContainer } from 'src/features/content/content.container';
import ScrollToTop from 'src/shared/components/scroll-to-top';
import { ContentListContainer } from 'src/features/content-list/content-list.container';
import { AFFINITY_GROUPS_ADDITIONAL_PATH } from '../../configuration/affinityGroupsAdditional';
import { AffinityGroupSeriesContainer } from 'src/features/affinity-group/affinity-group-series.container';
import { logger } from 'src/util/logger';
import { PAGE_NAME, METRIC_NAME } from 'src/util/types';

export const App = (): JSX.Element => {
    useEffect(() => {
        logger.emitLatencyMetric({
            pageName: PAGE_NAME.ENCOMPASS_PAGE,
            metricName: METRIC_NAME.APP_LATENCY_CF,
            metricValue: window?.performance?.now(),
        });
    }, []);

    return (
        <Router basename="/encompass">
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<HomePageContainer />} />
                <Route
                    path="/affinity-groups/affinity-group-faqs"
                    element={<ContentListContainer />}
                />
                <Route
                    path="/affinity-groups/affinity-group-faqs/:contentId"
                    element={<ContentContainer />}
                />
                <Route path="/affinity-groups" element={<AffinityGroupsContainer />} />
                <Route
                    path={`/affinity-groups/${AFFINITY_GROUPS_ADDITIONAL_PATH}`}
                    element={<AffinityGroupSeriesContainer />}
                />
                <Route
                    path="/affinity-groups/:groupId"
                    element={<AffinityGroupSeriesContainer />}
                />
                <Route path="/affinity-groups/:groupId/sign-up" element={<SignUpContainer />} />
                <Route path="/affinity-groups/:groupId/:contentId" element={<ContentContainer />} />
                <Route path="/inclusion" element={<ContentListContainer />} />
                <Route path="/inclusion/:contentId" element={<ContentContainer />} />
                <Route path="/resources" element={<ContentListContainer />} />
                <Route path="/resources/:contentId" element={<ContentContainer />} />
                <Route path="/our-workplace" element={<ContentListContainer />} />
                <Route path="/our-workplace/:contentId" element={<ContentContainer />} />
                <Route path="/diversity-calendar" element={<ContentListContainer />} />
                <Route path="/diversity-calendar/:contentId" element={<ContentContainer />} />
                <Route path="/profile" element={<ProfileContainer />} />
                <Route path="/survey/complete" element={<SurveyContainer />} />
                <Route path="/survey/:number" element={<SurveyContainer />} />
            </Routes>
        </Router>
    );
};

export default App;
