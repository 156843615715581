import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import { Spinner } from 'src/shared/components/spinner';
import { PageContainer } from 'src/shared/components/page-container';
import { Flex } from '@amzn/stencil-react-components/layout';
import { SurveyPage } from './components/survey-page';
import { SubmitPage } from './components/submit-page';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useCreateSurveyV1Mutation } from '../../types/graphql';
import { resetSurvey } from './slices/survey.slice';
import { isSubmittingSurveyToBackend } from '../../flags/index';
import { getApolloClient } from '../../apollo/index';
import { PageNames, WebAnalytics } from 'src/util/WebAnalytics';
import { logger } from '../../util/logger';
import { METRIC_NAME, PAGE_NAME } from 'src/util/types';

export const Survey = () => {
    const dispatch = useDispatch();
    const { number } = useParams();
    const { loading, questions } = useSelector((state: RootState) => state.survey);

    const apolloClientOptions = isSubmittingSurveyToBackend()
        ? {
              client: getApolloClient({
                  isBetaDirectClient: isSubmittingSurveyToBackend(),
              }),
          }
        : {};

    const { question, answer } = useSelector((state: RootState) => {
        if (number === undefined) {
            return {};
        }
        const question = state.survey.questions[number];
        return {
            question,
            answer: state.survey.answers[question?.questionId!],
        };
    });
    const answers = useSelector((state: RootState) => {
        return state.survey.answers;
    });
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [createSurveyV1Mutation] = useCreateSurveyV1Mutation(apolloClientOptions);

    const continueBtnLabel = t('encompass_sign_up_affinity_group_text5');
    const submitBtnLabel = t('encompass_survey_text108');

    const btnLabel = question
        ? Object.keys(questions).length === Number(number)
            ? submitBtnLabel
            : continueBtnLabel
        : '';

    const submitPageTitle = t('encompass_survey_text109');
    const submitPageDescription = t('encompass_survey_text110');
    const submitPageButton = t('encompass_survey_text17');

    const handleSubmit = async () => {
        if (Number(number) < Object.keys(questions).length) {
            navigate(`/survey/${Number(number) + 1}`);
        } else {
            const surveyId = 'Encompass-Home';
            const surveyAnswer = Object.keys(answers).map((item) => {
                return {
                    questionDescription: answers[item].description,
                    questionID: answers[item].questionId,
                    userSelection: answers[item].answer,
                };
            });

            const variables = {
                userAnswers: {
                    surveyId,
                    surveyAnswer,
                },
            };

            createSurveyV1Mutation({ variables }).then(() => {
                dispatch(resetSurvey());
                navigate('/survey/complete');
            });
        }
    };

    function handleClose() {
        navigate('/');
    }

    if (loading) {
        return <Spinner />;
    }

    WebAnalytics.registerPageView(PageNames.SURVEY_PAGE);

    if (question) {
        return (
            <PageContainer questions={Object.keys(questions).length} isSurvey={true}>
                <Flex flexDirection="column" backgroundColor="white" style={{ padding: '16px' }}>
                    <SurveyPage
                        question={question}
                        // @ts-ignore
                        answer={answer}
                        submitHandler={handleSubmit}
                        buttonLabel={btnLabel}
                    />
                </Flex>
            </PageContainer>
        );
    }

    if (!question && !loading) {
        return (
            <PageContainer>
                <Flex flexDirection="column" backgroundColor="white" style={{ padding: '16px' }}>
                    <SubmitPage
                        title={submitPageTitle}
                        content={submitPageDescription}
                        btnLabel={submitPageButton}
                        closeHandler={handleClose}
                    />
                </Flex>
            </PageContainer>
        );
    }

    return (
        <PageContainer>
            <></>
        </PageContainer>
    );
};
