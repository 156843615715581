import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Flex, Row } from '@amzn/stencil-react-components/layout';
import { IconSearch } from '@amzn/stencil-react-components/icons';
import { Input, InputWrapper } from '@amzn/stencil-react-components/form';
import { styledWithTheme } from '@amzn/stencil-react-components/context';
import { useTranslation } from 'react-i18next';
import '../../styles/app.scss';

type SearchFieldProps = {
    placeholder: string;
    onSubmitFunction: (query: string) => void;
    value: string;
    minLength?: number;
    label?: string;
};

interface IFormInput {
    query: string;
}

const BorderedInputView = styledWithTheme('div')(() => ({
    backgroundColor: '#FFFFFF',
    borderColor: '#8B96A3',
    width: '100%',
}));

const IconView = styledWithTheme('div')(({ theme }: any) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: '#0077D9',
    width: '46px',
    height: '44px',
}));

export const StyledSearchInput = styledWithTheme(Input)(() => ({
    paddingLeft: '10px',
    width: '100%',
    fontSize: 16,
}));

const StyledSearchButton = styledWithTheme('button')(() => ({
    background: 'transparent',
    border: 'none',
    padding: 0,
}));

export const SearchInput = ({
    placeholder,
    onSubmitFunction,
    value,
    minLength = 2,
    label,
}: SearchFieldProps) => {
    const [query, setQuery] = useState(value ? value : '');
    const { t } = useTranslation();
    const notEnoughLetters = t('encompass_home_text18');

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<IFormInput>({
        defaultValues: {
            query: value,
        },
    });

    function submit() {
        onSubmitFunction(query);
    }

    return (
        <form onSubmit={handleSubmit(submit)}>
            <Col>
                <InputWrapper labelText={label ?? ''} id="searchForm">
                    {() => (
                        <Row width="100%" gridGap="S300">
                            <Flex flexDirection="row" flex={1}>
                                <BorderedInputView>
                                    <StyledSearchInput
                                        id="search"
                                        type="text"
                                        aria-describedby="not-enough-letters"
                                        aria-label="Search Encompass"
                                        placeholder={placeholder}
                                        value={query}
                                        error={errors.query ? true : false}
                                        {...register('query', {
                                            minLength: {
                                                value: minLength,
                                                message: notEnoughLetters,
                                            },
                                            required: {
                                                value: true,
                                                message: notEnoughLetters,
                                            },
                                            onChange: (e) => setQuery(e.target.value),
                                        })}
                                    />
                                </BorderedInputView>
                            </Flex>

                            <StyledSearchButton
                                type="submit"
                                aria-label="Submit search"
                                data-test="searchSubmit"
                            >
                                <IconView>
                                    <IconSearch title="Search" color="white" />
                                </IconView>
                            </StyledSearchButton>
                        </Row>
                    )}
                </InputWrapper>
            </Col>
            <p id="not-enough-letters" style={{ height: '18px' }}>
                {errors.query?.message}
            </p>
        </form>
    );
};
