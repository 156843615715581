import { nanoid } from 'nanoid';
import KatalLogger, { Level, LoggerConfig } from '@katal/logger';
import { Metric, ReportHandler } from 'web-vitals';
import { METRIC_UNIT, DIMENSION_VALUES, EMFMetric, MetaDataMetric, METRIC_NAME } from './types';

export const atozClientRequestId = nanoid();

const loggerConfig: LoggerConfig = {
    url: 'https://5qyod25mxh.execute-api.us-west-2.amazonaws.com/prod/v1/log',
    logThreshold: Level.INFO,
    maxLogLineSize: 10001,
    logToConsole: true,
    context: { 'x-atoz-client-request-id': atozClientRequestId },
};

class AdpReferenceAppLogger extends KatalLogger {
    constructor(config: LoggerConfig) {
        super(config);
    }

    /**
     * Given metric/dimension values, creates a EMF payload in the expected
     * AWS syntax.
     *
     * More Info: https://docs.aws.amazon.com/AmazonCloudWatch/latest/monitoring/CloudWatch_Embedded_Metric_Format_Specification.html
     */
    private createEmfLog = ({
        pageName,
        metricName,
        metricValue,
        metricUnit,
        dimensions,
        namespace = 'AtoZEncompassApp',
    }: MetaDataMetric): EMFMetric => {
        // Add the page name dimensions to all incoming dimensions
        const allDimensions: DIMENSION_VALUES = {
            PageName: pageName,
            ...dimensions,
        };
        const emfLog = {
            _aws: {
                Timestamp: Date.now(),
                CloudWatchMetrics: [
                    {
                        Namespace: namespace,
                        Dimensions: Object.keys(allDimensions).map((d) => [d]),
                        Metrics: [
                            {
                                Name: metricName,
                                Unit: metricUnit,
                            },
                        ],
                    },
                ],
            },
            ...allDimensions,
            [metricName]: metricValue,
        };

        return emfLog;
    };

    /**
     * Reports individual web-vitals
     */
    private reportWebVital = (pageName: string) => {
        return ({ name, value }: Metric) => {
            this.logEmfMetric({
                pageName: pageName,
                metricName: name,
                metricValue: value,
                metricUnit: name !== 'CLS' ? METRIC_UNIT.MILLISECONDS : METRIC_UNIT.NONE,
            });
        };
    };

    /**
     * Reports all the web vitals
     * https://github.com/GoogleChrome/web-vitals
     */
    public reportWebVitals = async (pageName: string) => {
        const { getFID, getFCP, getLCP, getTTFB } = await import('web-vitals');
        getFID(this.reportWebVital(pageName));
        getFCP(this.reportWebVital(pageName));
        getLCP(this.reportWebVital(pageName));
        getTTFB(this.reportWebVital(pageName));
    };

    /**
     * Logs an EMF metric using the Katal logger (with EMF payload added to the log)
     */
    public logEmfMetric = (metricPayload: MetaDataMetric): void => {
        const emfLog = this.createEmfLog(metricPayload);
        void this.info(metricPayload.metricName, { emfLog: emfLog });
    };

    /**
     * Helper method to log count metric with count = 1
     */
    public emitCountMetric = ({
        pageName,
        metricName,
        dimensions,
    }: Omit<MetaDataMetric, 'metricValue' | 'metricUnit'>): void => {
        this.logEmfMetric({
            pageName,
            metricName,
            metricValue: 1,
            metricUnit: METRIC_UNIT.COUNT,
            dimensions,
        });
    };

    /**
     * Helper method to log latency metric (in milliseconds)
     */
    public emitLatencyMetric = ({
        pageName,
        metricName,
        metricValue,
        dimensions,
    }: Omit<MetaDataMetric, 'metricUnit'>): void => {
        this.logEmfMetric({
            pageName,
            metricName,
            metricValue: metricValue,
            metricUnit: METRIC_UNIT.MILLISECONDS,
            dimensions,
        });
    };
}

export const logger = new AdpReferenceAppLogger(loggerConfig);

/**
 * Katal Global error listener.
 * More Info: https://katal.corp.amazon.com/#/logger
 */
logger.addErrorListener(() => {
    // We are only emitting the error count metric. Since the helper method is returning true,
    // Katal listener will alreay log this to the logs.
    logger.emitCountMetric({
        pageName: 'AtoZEncompassApp',
        metricName: METRIC_NAME.APP_ERROR_UNKNOWN,
    });
    return true;
});
