import React from 'react';
import { Modal, ModalContent } from '@amzn/stencil-react-components/modal';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { useTranslation } from 'react-i18next';
import { SearchResultsContainer } from '../../search-results/search-results.container';
import { styles } from './styles';

type SearchResultsModal = {
    initialQuery: string;
    isOpen: boolean;
    onClose: () => void;
};

export const SearchResultsModal = ({
    initialQuery,
    isOpen,
    onClose,
}: SearchResultsModal): JSX.Element => {
    const { t } = useTranslation();

    const closeBtnLabel = t('navigation_close');

    return (
        <Modal
            isOpen={isOpen}
            isScrollable={false}
            shouldCloseOnClickOutside={false}
            close={onClose}
        >
            <div data-test="searchResultsModal" className="custom-modal">
                <ModalContent titleText="">
                    <Button
                        onClick={onClose}
                        variant={ButtonVariant.Tertiary}
                        aria-label="Close"
                        style={styles.searchResultsModalClose}
                    >
                        {closeBtnLabel}
                    </Button>

                    <SearchResultsContainer initialQuery={initialQuery} />
                </ModalContent>
            </div>
        </Modal>
    );
};
