import React from 'react';
import { Expander, ExpanderTitleRendererParams } from '@amzn/stencil-react-components/expander';
import { Row, View } from '@amzn/stencil-react-components/layout';
import { AffinityGroup } from 'src/types/graphql';
import { styledWithTheme } from '@amzn/stencil-react-components/context';
import { H4 } from '@amzn/stencil-react-components/text';
import { AFFINITY_GROUP_IMAGES } from '../../../../configuration/affinityGroupImages';

type ItemsListProps = {
    title: string;
    itemsArray: AffinityGroup[];
    shouldExpandOnMount?: boolean;
};

const ClickableH4 = styledWithTheme(H4)(({ theme }) => ({
    cursor: 'pointer',
    color: theme.selectors.color('neutral100'),
    ':hover': {
        color: theme.selectors.color('neutral100'),
    },
}));

const renderTitle = ({ titleText, toggle }: ExpanderTitleRendererParams) => (
    <ClickableH4
        data-test="homeInterestLists"
        onClick={toggle}
        style={{ fontSize: '14px', fontWeight: '700', lineHeight: '22px' }}
    >
        {titleText}
    </ClickableH4>
);

export const ItemsDropdown = ({
    title,
    itemsArray,
    shouldExpandOnMount,
}: ItemsListProps): JSX.Element => {
    return (
        <View data-test="itemsDropdown">
            <Expander
                contentSpacing="S100"
                renderTitle={renderTitle}
                titleText={title}
                shouldExpandOnMount={shouldExpandOnMount}
            >
                <Row display="flex" flexWrap="wrap" alignItems="center">
                    {itemsArray.map((item) => (
                        <View
                            display="flex"
                            justifyContent="center"
                            key={item.id}
                            padding="S100"
                            margin="S100"
                            style={{ flexBasis: '100px' }}
                        >
                            <img
                                data-test="img"
                                className="profileAgLogo"
                                src={AFFINITY_GROUP_IMAGES[item.logo]}
                                alt={item.logoAltText}
                            />
                        </View>
                    ))}
                </Row>
            </Expander>
        </View>
    );
};
