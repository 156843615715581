import React from 'react';
import { View } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import { Link } from 'react-router-dom';

type AffinityGroupFAQsProps = {
    title: string;
    desc: String;
};

export const AffinityGroupFAQs = ({ title, desc }: AffinityGroupFAQsProps) => {
    return (
        <Link style={{ width: '100%' }} to="/affinity-groups/affinity-group-faqs">
            <View
                display="flex"
                flexDirection="column"
                border="none!important"
                color="black!important"
                style={{
                    boxShadow: '0 0 1px gray',
                    padding: '16px',
                    margin: '16px 16px 8px 16px',
                }}
                backgroundColor="white"
            >
                <View
                    display="flex"
                    justifyContent="center"
                    padding="S100"
                    style={{
                        width: '200px',
                        height: '65px',
                        maxWidth: '200px',
                        minWidth: '100px',
                    }}
                    alignItems="center"
                    alignSelf="center"
                >
                    <Text fontWeight="bold" fontSize="T500" textAlign="center">
                        {title}
                    </Text>
                </View>
                <Text
                    color="black"
                    textAlign="center"
                    style={{
                        wordBreak: 'break-all',
                        fontSize: '14px',
                        lineHeight: '22px',
                        fontWeight: '400',
                    }}
                >
                    {desc}
                </Text>
            </View>
        </Link>
    );
};
