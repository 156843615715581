import React from 'react';
import { Card } from '@amzn/stencil-react-components/card';
import { View } from '@amzn/stencil-react-components/layout';
import { IconChevronRight } from '@amzn/stencil-react-components/icons';
import { Text } from '@amzn/stencil-react-components/text';
import { styledWithTheme } from '@amzn/stencil-react-components/context';
import { Link } from 'react-router-dom';
import { styles } from './styles';

export type CardProps = {
    id: string;
    link: string;
    title: string;
    content: string;
};

const Linkable = styledWithTheme(Link)(() => ({
    '&:focus-visible': {
        outline: '1px solid -webkit-focus-ring-color',
    },
}));

const StyledCard = styledWithTheme(Card)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    border: 'none!important',
    color: 'black!important',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
}));

export const Category = ({ id, link, title, content }: CardProps): JSX.Element => {
    return (
        <Linkable data-test="contentCard" to={link} tabIndex={0}>
            <StyledCard>
                <View>
                    <Text style={styles.categoryTitle}>{title}</Text>
                    <Text style={styles.categoryContent}>{content}</Text>
                </View>
                <View display="flex" alignItems="center">
                    <IconChevronRight />
                </View>
            </StyledCard>
        </Linkable>
    );
};
