import React from 'react';
import { Logo } from '../../shared/components/logo';
import { Description } from '../../shared/components/description';
import { SignUpButton } from './components/sign-up-button';
import { NavList } from '../../shared/components/nav-list';
import { Flex, View } from '@amzn/stencil-react-components/layout';
import { Content } from '../../types/graphql';
import { useTranslation } from 'react-i18next';
import { AFFINITY_GROUP_IMAGES } from '../../../configuration/affinityGroupImages';
import { WebAnalytics } from 'src/util/WebAnalytics';
import { PageContainer } from 'src/shared/components/page-container';

export type AffinityGroupProps = {
    active: boolean;
    cards: Content[] | any[];
    descriptionTranslationKey: string;
    hasJoined: boolean;
    isInterested: boolean;
    link: string;
    logo: string;
    logoAltTextTranslationKey: string;
    name: string;
    isActionButton: boolean;
    isSiteAllowed: boolean;
};

export const AffinityGroup = ({
    active,
    logoAltTextTranslationKey,
    cards,
    descriptionTranslationKey,
    hasJoined,
    isInterested,
    link,
    logo,
    name,
    isActionButton,
    isSiteAllowed,
}: AffinityGroupProps): JSX.Element => {
    const { t } = useTranslation();
    const cardsToPass = cards?.map((card) => {
        const linkNext = card.publishLocation.url.split('/encompass')[1];

        return {
            id: card.id,
            link: `${linkNext}/${card.id}`,
            title: card.title,
            content: card.description,
        };
    });
    const logoNext = AFFINITY_GROUP_IMAGES[logo];
    const logoAlternativeText = t(logoAltTextTranslationKey);
    const descriptionContent = t(descriptionTranslationKey);
    console.log(isSiteAllowed);

    WebAnalytics.registerAGPageView(name);

    return (
        <PageContainer>
            <Flex flexDirection="column" backgroundColor="white" style={{ padding: '16px' }}>
                <Logo logo={logoNext} logoAltText={logoAlternativeText} />
                <Description title={name} content={descriptionContent} />
                {isActionButton && !hasJoined && !isInterested && isSiteAllowed && (
                    <View display="flex" justifyContent="center" style={{ marginTop: '16px' }}>
                        <SignUpButton link={link} active={active} />
                    </View>
                )}
            </Flex>
            {cards && (
                <Flex flexDirection="column">
                    <NavList cards={cardsToPass} />
                </Flex>
            )}
        </PageContainer>
    );
};
