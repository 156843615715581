import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageContainer } from '../../shared/components/page-container';
import { Flex } from '@amzn/stencil-react-components/layout';
import { Logo } from '../../shared/components/logo';
import { Description } from '../../shared/components/description';
import { EmailInputSection } from './components/email-input-section';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { AFFINITY_GROUP_IMAGES } from '../../../configuration/affinityGroupImages';
import { PageNames, WebAnalytics } from 'src/util/WebAnalytics';
import { HandlerToAffinityGroupArg } from './sign-up.container';
import { logger } from '../../util/logger';
import { METRIC_NAME, PAGE_NAME } from 'src/util/types';

type SignUpProps = {
    handlerToAffinityGroupArg: HandlerToAffinityGroupArg;
    logoAltText: string;
    hasChapterForSite: boolean;
    link: string;
    logo: string;
    handlerToAffinityGroup: any;
    setMailHandler: (mail: string) => void;
    signed: boolean;
    setSigned: any;
};

export const SignUp = ({
    handlerToAffinityGroupArg,
    logo,
    logoAltText,
    link,
    hasChapterForSite,
    handlerToAffinityGroup,
    setMailHandler,
    signed,
    setSigned,
}: SignUpProps) => {
    const { t } = useTranslation();
    const { email2: email } = handlerToAffinityGroupArg;
    const [useCase, setUseCase] = React.useState<any>({
        name: 'unknown',
        title: 'unknown',
        content: 'unknown',
    });
    const mainContentArea = useRef<any>(null);

    useEffect(() => {
        if (!hasChapterForSite && !signed && !email) {
            setUseCase({
                name: 'isExpressingInterestNoEmail',
                title: t('encompass_sign_up_affinity_group_text15'),
                content: `${t('encompass_sign_up_affinity_group_text2')} ${t(
                    'encompass_sign_up_affinity_group_text10'
                )}`,
            });
        } else if (hasChapterForSite && !signed && !email) {
            setUseCase({
                name: 'isSigningUpNoEmail',
                title: t('encompass_sign_up_affinity_group_text1'),
                content: t('encompass_sign_up_affinity_group_text9'),
            });
        } else if (!hasChapterForSite && !signed && !!email) {
            setUseCase({
                name: 'isEspressingInterestHasEmail',
                title: t('encompass_sign_up_affinity_group_text15'),
                content: `${t('encompass_sign_up_affinity_group_text2')} ${t(
                    'encompass_sign_up_affinity_group_text10'
                )}`,
            });
        } else if (hasChapterForSite && !signed && !!email) {
            setUseCase({
                name: 'isSigningUpHasEmail',
                title: t('encompass_sign_up_affinity_group_text6'),
                content: t('encompass_sign_up_affinity_group_text7'),
            });
        } else if (hasChapterForSite && signed && !!email) {
            setUseCase({
                name: 'isReturningBackFromSignUp',
                title: t('encompass_sign_up_affinity_group_text6'),
                content: t('encompass_sign_up_affinity_group_text7'),
            });
        } else if (!hasChapterForSite && signed) {
            mainContentArea.current.focus();
            setUseCase({
                name: 'isReturningBackFromExpressInterest',
                title: t('encompass_sign_up_affinity_group_text12'),
                content: t('encompass_sign_up_affinity_group_text13'),
            });
        }
    }, []);

    // useCase, focusOnMainContent, contentAreaRef, hasChapterForSite, signed
    function handleCloseAndReturn() {
        hasChapterForSite && handlerToAffinityGroup(handlerToAffinityGroupArg);
        history.back();
    }

    const handleSignUpOrExpressInterestWithEmail = () => {
        setSigned(true);
        handlerToAffinityGroup(handlerToAffinityGroupArg);
    };

    const expressInterestBtn = t('encompass_sign_up_affinity_group_text15');
    const closeBtn = t('encompass_survey_text108');

    const logoNext = AFFINITY_GROUP_IMAGES[logo];

    WebAnalytics.registerPageView(PageNames.AG_SIGN_UP_PAGE);

    return (
        <PageContainer signed={signed}>
            <Flex
                ref={mainContentArea}
                className="mainContentArea"
                flexDirection="column"
                backgroundColor="white"
                style={{ padding: '16px' }}
                tabIndex={-1}
            >
                <Logo logo={logoNext} logoAltText={logoAltText} />
                <Description title={''} content={useCase.content} />
                {(useCase.name === 'isExpressingInterestNoEmail' ||
                    useCase.name === 'isSigningUpNoEmail') && (
                    <EmailInputSection
                        handlerToAffinityGroupArg={handlerToAffinityGroupArg}
                        handlerToAffinityGroup={handlerToAffinityGroup}
                        setSigned={setSigned}
                        setNewMail={setMailHandler}
                    />
                )}

                <Flex flexDirection="column" alignItems="center" style={{ marginTop: '16px' }}>
                    {useCase.name === 'isEspressingInterestHasEmail' && (
                        <Button
                            data-test="expressInterestBtn"
                            variant={ButtonVariant.Primary}
                            style={{
                                width: '100%',
                                whiteSpace: 'normal',
                                paddingTop: '10px',
                                fontSize: '16px',
                                lineHeight: '24px',
                                fontWeight: '500',
                            }}
                            onClick={handleSignUpOrExpressInterestWithEmail}
                            aria-label="Express Interest"
                        >
                            {expressInterestBtn}
                        </Button>
                    )}
                    {(useCase.name === 'isSigningUpHasEmail' ||
                        useCase.name === 'isReturningBackFromExpressInterest' ||
                        useCase.name === 'isReturningBackFromSignUp') && (
                        <Button
                            data-test="submit"
                            variant={ButtonVariant.Primary}
                            style={{
                                width: '100%',
                                whiteSpace: 'normal',
                                paddingTop: '10px',
                                fontSize: '16px',
                                lineHeight: '24px',
                                fontWeight: '500',
                            }}
                            onClick={handleCloseAndReturn}
                            aria-label="Return to Affinity Group Page"
                        >
                            {closeBtn}
                        </Button>
                    )}
                </Flex>
            </Flex>
        </PageContainer>
    );
};
