import { AffinityGroup as AffinityGroupType, Member, Query } from '../../types/graphql';

export type AffinityGroupExtended =
    | (AffinityGroupType & { hasJoined: boolean; hasChapterForSite: boolean })
    | null;
interface GetGroupWithExtraProps {
    (args: {
        groups: AffinityGroupType[];
        id: string | undefined;
        list: Member['chapterList'];
        listIdName: string;
        chaptersForSite: Query['getChapterForSiteV1'][];
    }): AffinityGroupExtended;
}

export const getGroupWithExtraProps: GetGroupWithExtraProps = ({
    groups,
    id,
    list,
    listIdName,
    chaptersForSite,
}) => {
    let hasJoined: boolean = false;
    let hasChapterForSite: boolean = false;

    if (list && list.find((item: any) => item[listIdName] === id)) hasJoined = true;

    if (
        chaptersForSite &&
        chaptersForSite.find(
            (chapter) =>
                chapter &&
                chapter.affinityGroupId === id &&
                (chapter.active || chapter.active === null)
        )
    )
        hasChapterForSite = true;

    const group = groups.find((item) => item?.id === id);
    const output = group ? { ...group, hasJoined, hasChapterForSite } : null;

    return output;
};
