import { CSSProperties } from 'react';

export const styles: Record<string, CSSProperties> = {
    categoryTitle: {
        fontSize: '16px',
        lineHeight: '22px',
        fontWeight: '400',
    },
    categoryContent: {
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: '400',
    },
    searchResultsModalClose: {
        fontSize: '16px',
        fontWeight: '500',
    },
};
