import React, { ReactNode } from 'react';
import { Container, Flex, View } from '@amzn/stencil-react-components/layout';
import { Navigation } from './navigation';
import { BreadCrumbs } from './breadcrumbs';

type PageContainerProps = {
    children: ReactNode;
    signed?: boolean;
    questions?: number;
    isMobile?: boolean;
    isSurvey?: boolean;
    contentTitle?: string;
};

export const isMobile = () =>
    /Android|webOS|iPhone|iPad|iPod|Opera Mini/.test(window.navigator.userAgent);

export const PageContainer = ({
    children,
    signed,
    questions,
    contentTitle,
    isSurvey = false,
}: PageContainerProps) => {
    return (
        <Container backgroundColor="#F2F2F2" display="flex" justifyContent="center" width="100%">
            <Flex
                flexDirection="column"
                justifyContent="center"
                rowGap="S200"
                width="100%"
                style={{ maxWidth: '600px', margin: '0 auto', minWidth: '210px' }}
            >
                {/* <Navigation
            signed={signed || undefined}
            questions={questions || 0}
            contentTitle={contentTitle || undefined}
          /> */}
                {!isMobile() && (
                    <Flex flexDirection="row" backgroundColor="#E8EAEE">
                        <View
                            style={{
                                marginLeft: '5px',
                                marginTop: '5px',
                                fontSize: '14px',
                                lineHeight: '22px',
                                fontWeight: '500',
                            }}
                        >
                            <BreadCrumbs contentTitle={contentTitle || undefined} />
                        </View>
                    </Flex>
                )}
                <>{children}</>
            </Flex>
        </Container>
    );
};
