import { View, Flex, Hr } from '@amzn/stencil-react-components/layout';
import React, { useState } from 'react';
import { Text, H3 } from '@amzn/stencil-react-components/text';
import DOMPurify from 'dompurify';
import { ContentType, FeedbackType, FeedbackValue, FeedbackInput } from '../../../types/graphql';
import { useTranslation } from 'react-i18next';
import { RatingHeartComponent } from './rating-heart-component';
import { WebAnalytics } from 'src/util/WebAnalytics';
import { Expander, ExpanderTitleRendererParams } from '@amzn/stencil-react-components/expander';

type ContentElementProps = {
    contentId: string;
    type: string;
    text: string;
    url: string;
    description: string;
    transcription: string;
    feedback: FeedbackInput;
    rateHandler: (
        articleId: string,
        feedbackType: FeedbackType,
        feedbackValue: FeedbackValue
    ) => void;
    rateDeleteHandler: (articleId: string) => void;
};

export const ContentElement = ({
    contentId,
    type,
    text,
    url,
    description,
    transcription,
    feedback,
    rateHandler,
    rateDeleteHandler,
}: ContentElementProps) => {
    const [playingTime, setPlayingTime] = useState(new Date());
    const { t } = useTranslation();
    const translationLabel = t('transcription');
    function onPlay() {
        setPlayingTime(new Date());
    }

    function onPause() {
        const currentTime = new Date();
        const duration = (currentTime.getTime() - playingTime.getTime()) / 1000;
        WebAnalytics.registerVideoDuration(contentId, text, duration);
    }

    const renderTitle = ({ titleText, toggle }: ExpanderTitleRendererParams) => (
        <H3
            data-test="profileDropdown"
            onClick={toggle}
            style={{ fontSize: '16px', fontWeight: '400', lineHeight: '24px' }}
        >
            {titleText}
        </H3>
    );

    return (
        <View display="flex" justifyContent="center">
            {type === ContentType.Image && (
                <View display="flex" justifyContent="center" padding="S100">
                    <img src={url} alt={text} width="60%" />
                </View>
            )}
            {type === ContentType.Text && (
                <View padding="S100">
                    <Text
                        style={{ fontSize: '16px', fontWeight: '400', lineHeight: '24px' }}
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
                    />
                </View>
            )}
            {type === ContentType.Video && (
                <Flex
                    className="video-block"
                    flexDirection="column"
                    style={{ padding: '16px' }}
                    backgroundColor="white"
                    rowGap="S100"
                    justifyContent="center"
                    maxWidth="700px"
                    width="100%"
                >
                    <View display="flex" justifyContent="center" alignItems="center">
                        <video
                            id="video-player"
                            crossOrigin="anonymous"
                            style={{ maxWidth: '700px', width: '100%' }}
                            controls
                            controlsList="nodownload"
                            disablePictureInPicture
                            onPlay={onPlay}
                            onPause={onPause}
                            title={text}
                        >
                            <source src={url} type="video/mp4" />
                            <track
                                src={`${url}.vtt`}
                                kind="subtitles"
                                srcLang="en"
                                label="English"
                            />
                            Your browser does not support the video tag.
                        </video>
                    </View>
                    {transcription.length > 0 && (
                        <>
                            <View padding="S100">
                                <Expander titleText={translationLabel} renderTitle={renderTitle}>
                                    <Flex flexDirection="column">
                                        <Text
                                            style={{
                                                fontSize: '14px',
                                                fontWeight: '400',
                                                lineHeight: '22px',
                                            }}
                                        >
                                            {transcription}
                                        </Text>
                                    </Flex>
                                </Expander>
                            </View>
                            <Hr />
                        </>
                    )}
                    <Text style={{ fontSize: '16px', fontWeight: '700', lineHeight: '24px' }}>
                        {text}
                    </Text>
                    <View padding="S100" flexWrap="wrap">
                        <Text
                            style={{ fontSize: '14px', fontWeight: '400', lineHeight: '22px' }}
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
                        />
                    </View>

                    <RatingHeartComponent
                        contentId={contentId}
                        feedback={feedback}
                        rateHandler={rateHandler}
                        rateDeleteHandler={rateDeleteHandler}
                    />
                </Flex>
            )}
        </View>
    );
};
