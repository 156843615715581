import { AdobeOmnitureBuilder, OmnitureHelper } from './OmnitureHelper';

/**
 * These PageNames correspond to the different pages of your application.
 * Set by agreement of the Tenant Team and the AtoZ BI Team and are unique to your Use-Case and App Design.
 *
 * Replace this with the Page Names from your experience.
 */
export enum PageNames {
    HOME_PAGE = 'Encompass-Home-Page',
    AG_SIGN_UP_PAGE = 'Encompass-AG-Signup-Page',
    SEARCH_RESULTS_PAGE = 'Encompass-Search-Results-Page',
    CONTENT_PAGE = 'Encompass-Content-Page',
    PROFILE_PAGE = 'Encompass-Profile-Page',
    SURVEY_PAGE = 'Encompass-Survey-Page',
    AG_PAGE = 'Encompass-AG-Page',
}

/**
 * These PageActions correspond to different PageActions to collect Adobe Omniture Metrics.
 * They are set by agreement between the ADP Tenant Team and the AtoZ BI Team, and they are unique for your use-case.
 *
 * Replace this with the Page Actions from your experience.
 */
export enum PageActions {
    CANCEL_AG_SIGN_UP = 'cancel_ag_sign_up',
    SEARCH_KEYWORD = 'search_keyword',
    SEARCH_KEYWORD_NOT_FOUND = 'search_keyword_not_found',
    VIDEO_DURATION = 'video_duration',
}

export class WebAnalytics {
    /**
     * User lands on AtoZEncompass page views
     * @param pageName
     */
    public static registerPageView(pageName: PageNames) {
        OmnitureHelper.setOmniturePageInfo(
            new AdobeOmnitureBuilder(pageName).withHitType('1').build()
        );
    }

    /**
     * User lands on AtoZEncompass page views
     * @param agName
     */
    public static registerAGPageView(agName: string) {
        OmnitureHelper.setOmniturePageInfo(
            new AdobeOmnitureBuilder(PageNames.AG_PAGE)
                .withPageConst(
                    JSON.stringify({
                        agName,
                    })
                )
                .withHitType('1')
                .build()
        );
    }

    /**
     * User presses back on AG signup page.
     */
    public static registerCancelAgSignup(agId: string) {
        OmnitureHelper.setOmniturePageInfo(
            new AdobeOmnitureBuilder(PageNames.AG_SIGN_UP_PAGE)
                .withPageAction(PageActions.CANCEL_AG_SIGN_UP)
                .withHitType('3')
                .withPageConst(
                    JSON.stringify({
                        agId,
                    })
                )
                .build()
        );
    }

    /**
     * Register keywords that are found in the search results
     */
    public static registerSearchKeywords(empId: number, keyword: string) {
        OmnitureHelper.setOmniturePageInfo(
            new AdobeOmnitureBuilder(PageNames.SEARCH_RESULTS_PAGE)
                .withPageAction(PageActions.SEARCH_KEYWORD)
                .withHitType('3')
                .withPageConst(
                    JSON.stringify({
                        empId,
                        keyword,
                    })
                )
                .build()
        );
    }

    /**
     * Register keywords that are not found in the search results
     */
    public static registerSearchKeywordsNotFound(empId: number, keyword: string) {
        OmnitureHelper.setOmniturePageInfo(
            new AdobeOmnitureBuilder(PageNames.SEARCH_RESULTS_PAGE)
                .withPageAction(PageActions.SEARCH_KEYWORD_NOT_FOUND)
                .withHitType('3')
                .withPageConst(
                    JSON.stringify({
                        empId,
                        keyword,
                    })
                )
                .build()
        );
    }

    /**
     * Register duration of video watched in the content page.
     */
    public static registerVideoDuration(videoId: string, videoTitle: string, duration: number) {
        OmnitureHelper.setOmniturePageInfo(
            new AdobeOmnitureBuilder(PageNames.CONTENT_PAGE)
                .withPageAction(PageActions.VIDEO_DURATION)
                .withHitType('3')
                .withPageConst(
                    JSON.stringify({
                        videoId,
                        videoTitle,
                        duration,
                    })
                )
                .build()
        );
    }
}
