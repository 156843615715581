import {
    ApolloClient,
    ApolloLink,
    from,
    InMemoryCache,
    NormalizedCacheObject,
    HttpLink,
    DefaultOptions,
} from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import Cookies from 'js-cookie';
import { Stage } from '../../configuration/stage.enum';
import { STAGE_URL } from '../../configuration/stageUrl';
import { GraphQLError } from 'graphql';

// Line to paste here your employee id for beta testing from localhost
const DEV_X_API_KEY = 'Line to paste here your api key for beta testing from localhost';
// Line to paste here your api key for beta testing from localhost
const DEV_ATOZ_OUATH_TOKEN =
    'Line to paste here your atoz-oauth-token for gamma testing from localhost';
// Line to: paste here your atoz-oauth-token for gamma testing from localhost

export interface ClientApolloGammaProps {
    readonly clientId: string;
    readonly employeeId: string;
    readonly stage: Stage;
}

export class ClientApollo {
    private clientId: string;
    private employeeId: string;
    private stage: Stage;

    constructor(props: ClientApolloGammaProps) {
        this.clientId = props.clientId;
        this.employeeId = props.employeeId;
        this.stage = props.stage;
    }

    getApiUrl = (stage: string): string => {
        return STAGE_URL[stage.toLowerCase() as Stage].api;
    };

    getAuthorization = (): string => {
        const atozOauthToken = Cookies.get('atoz-oauth-token');
        const AUTH_BEARER = atozOauthToken && `Bearer ${atozOauthToken}`;
        return AUTH_BEARER || DEV_ATOZ_OUATH_TOKEN;
    };

    getApiGatewayHeaders = (
        clientId: string,
        employeeId: string,
        stage: Stage
    ): Record<string, string> => {
        let headers: Record<string, string>;
        try {
            if (stage === Stage.dev || stage === Stage.beta)
                headers = {
                    'x-api-key': DEV_X_API_KEY,
                    'x-atoz-employee-id': employeeId,
                };
            else {
                headers = {
                    'x-atoz-client-id': clientId,
                    authorization: this.getAuthorization(),
                };
            }
        } catch (error: any) {
            headers = { errorMessage: error.message };
        }
        return headers;
    };

    createHttpLink = (): HttpLink => {
        return new HttpLink({
            uri: `${this.getApiUrl(this.stage)}?employeeId=${this.employeeId}`,
            headers: this.getApiGatewayHeaders(this.clientId, this.employeeId, this.stage),
            credentials:
                this.stage === Stage.dev || this.stage === Stage.beta ? 'same-origin' : 'include', // "omit", "include", "same-origin",
        });
    };

    createRetryLink = (): RetryLink => {
        return new RetryLink({
            attempts: {
                max: 3,
            },
        });
    };

    defaultOptions: DefaultOptions = {
        watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore',
        },
        query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        },
    };

    public createApolloClient = (): ApolloClient<NormalizedCacheObject> => {
        const httpLink = this.createHttpLink();
        const retryLink = this.createRetryLink();
        const errorLink = onError(({ graphQLErrors, networkError, forward, operation }) => {
            if (graphQLErrors) {
                graphQLErrors.forEach(({ message, locations, path }) => {
                    console.log(
                        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                    );

                    // Retry the request, returning the new observable
                    forward(operation);
                });
            }
        });

        const additiveLinks = from([retryLink, errorLink, httpLink]);

        return new ApolloClient({
            link: additiveLinks,
            cache: new InMemoryCache(),
            connectToDevTools: true,
            defaultOptions: this.defaultOptions,
        });
    };
}
