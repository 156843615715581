import { Stage } from './stage.enum';

export type STAGE_URL_TYPE = {
    [key in Stage]: { api: string; app: string[] };
};

export const STAGE_URL: STAGE_URL_TYPE = {
    dev: {
        api: 'http://localhost:4000/graphql',
        app: ['http://localhost:4321', 'http://0.0.0.0:4321'],
    },
    beta: {
        api: 'https://api.beta.encompass.gdei.amazon.dev/graphql',
        app: ['https://d2efflmbq32ggi.cloudfront.net'],
    },
    gamma: {
        api: 'https://atoz-api.integ.amazon.com/graphql',
        app: ['https://essportal.integ.amazon.com', 'https://atoz.integ.amazon.com'],
    },
    prod: {
        api: 'https://atoz-api.amazon.work/graphql',
        app: ['https://atoz.amazon.work'],
    },
};
