import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AffinityGroups } from './affinity-groups.view';
import { getNotSupportedLanguageDetected } from '../../shared/functions/getNotSupportedLanguageDetected';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../app/store';
import { Spinner } from 'src/shared/components/spinner';
import { PageContainer } from '../../shared/components/page-container';
import { AffinityGroup as AffinityGroupType } from '../../types/graphql';
import {
    AFFINITY_GROUPS_ADDITIONAL,
    AffinityGroupsAdditional,
} from '../../../configuration/affinityGroupsAdditional';

export const AffinityGroupsContainer = () => {
    const { affinityGroups, loading } = useSelector((state: RootState) => ({
        affinityGroups: state.affinityGroups.affinityGroups,
        loading: state.affinityGroups.loading,
    }));

    const { i18n } = useTranslation();

    useEffect(() => {
        getNotSupportedLanguageDetected(i18n);
    }, []);

    const affinityGroupsNext = affinityGroups.filter((affinityGroup: AffinityGroupType) => {
        let output = true;
        AFFINITY_GROUPS_ADDITIONAL.forEach((item: AffinityGroupsAdditional) => {
            if (item.id === affinityGroup.id) output = false;
        });
        return output;
    });

    if (loading) return <Spinner />;
    return (
        <PageContainer>
            <AffinityGroups affinityGroups={affinityGroupsNext} />
        </PageContainer>
    );
};
