import { View } from '@amzn/stencil-react-components/layout';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmailInput } from 'src/shared/components/email-input';
import { ConfirmModal } from './confirm-modal';

interface EmailSection {
    email: string;
    onSubmit: (email: string) => void;
}

export const EmailSection = ({ email, onSubmit }: EmailSection): JSX.Element => {
    const previousMail = email;
    const [mail, setMail] = useState(email);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const { t } = useTranslation();
    const validationMessage = t('encompass_profile_text2');
    const changeEmailModalTitle = t('encompass_profile_text8');
    const changeEmailModalContent = t('encompass_profile_text7');

    function handleSubmit() {
        onSubmit(mail);
    }

    function handleCancel() {
        setMail(previousMail);
        setShowEmailModal(false);
    }

    return (
        <View backgroundColor="white">
            <EmailInput
                title={'Email'}
                validationMessage={validationMessage}
                email={mail}
                btnIcon
                profileEmailInput={true}
                submitHandler={() => {
                    if (mail !== previousMail) {
                        setShowEmailModal(true);
                    }
                }}
                changeHandler={setMail}
            />

            {showEmailModal && (
                <ConfirmModal
                    titleText={changeEmailModalTitle}
                    content={changeEmailModalContent}
                    leaveAg={false}
                    confirmHandler={handleSubmit}
                    open={true}
                    closeHandler={handleCancel}
                />
            )}
        </View>
    );
};
