import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getApolloClient } from '../../../apollo';
import { GetSurveyDocument, Question } from '../../../types/graphql';
import { getSurveyObjIntoArray } from '../../../shared/functions/getSurveyObjIntoArray';
import surveyAuestions from './survey-questions.json';

interface QuestionState {
    questionId: string;
    description: string;
    multiselect: boolean;
    answers: string[];
}

export type Questions = Record<string, QuestionState>;

export interface AnswerState {
    questionId: string;
    description: string;
    answer: string[];
}
export interface SurveyState {
    questions: Questions;
    loading: boolean;
    answers: Record<string, AnswerState>;
}

const initialState: SurveyState = {
    questions: getSurveyObjIntoArray(surveyAuestions),
    loading: false,
    answers: {},
};

export const changeAnswer = createAction<{
    questionId: string;
    description: string;
    answer: string[];
}>('survey/changeAnswer');

export const resetSurvey = createAction('survey/resetSurvey');

export const loadSurvey = createAsyncThunk('survey/loadSurvey', async () => {
    const apolloClient = getApolloClient({});

    try {
        const { data } = await apolloClient.query({
            query: GetSurveyDocument,
        });
        return Promise.resolve(data.getSurvey);
    } catch (error) {
        console.error('Error retrieving data', error);
    }
});

export const surveySlice = createSlice({
    name: 'survey',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(changeAnswer, (state, action) => {
                state.answers[action.payload.questionId] = {
                    description: action.payload.description,
                    questionId: action.payload.questionId,
                    answer: action.payload.answer,
                };
            })
            .addCase(resetSurvey, (state) => {
                state.answers = {};
            });
    },
});

export const surveyReducer = surveySlice.reducer;
