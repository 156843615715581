import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Flex, View } from '@amzn/stencil-react-components/layout';
import { Logo } from '../../shared/components/logo';
import { Description } from '../../shared/components/description';
import { AffinityGroup } from './components/affinity-group';
import AffinityGroupsLogo from '../../images/affinity-groups-logo.svg';
import { useTranslation } from 'react-i18next';
import { AffinityGroup as AffinityGroupType } from '../../types/graphql';
import { loadLocale } from '../../assets/locales/json/loadLocale';
import { AffinityGroupFAQs } from './components/affinity-group-faqs';
import { AFFINITY_GROUPS_ADDITIONAL_PATH } from '../../../configuration/affinityGroupsAdditional';
import { Text } from '@amzn/stencil-react-components/text';
import { logger } from '../../util/logger';
import { METRIC_NAME, PAGE_NAME } from 'src/util/types';
import '../../styles/app.scss';

export const AffinityGroups = ({ affinityGroups }: { affinityGroups: AffinityGroupType[] }) => {
    const { t } = useTranslation();

    useEffect(() => {
        loadLocale();
    }, []);

    const logoAlternativeText = t('encompass_affinity_groups_text1');
    const descriptionTitle = t('encompass_affinity_groups_text2');
    const descriptionContent = t('encompass_affinity_groups_text3');
    const noAffinityGroupsFound = t('affinity_groups_not_found');
    const agFaqTitle = t('encompass_affinity_groups_text4');
    const agFaqDesc = t('encompass_affinity_groups_text5');
    const additionalGroupsTitle = t('encompass_affinity_groups_text6');

    const getContent = (affinityGroupsIn: AffinityGroupType[]) => {
        if (!affinityGroupsIn || !affinityGroupsIn.length)
            return (
                <Text style={{ fontSize: '16px', fontWeight: '400', lineHeight: '24px' }}>
                    {noAffinityGroupsFound}
                </Text>
            );

        return affinityGroups.map(
            ({
                id,
                link,
                logo,
                logoAltText,
                name,
            }: {
                id: string;
                link: string;
                logo: string;
                logoAltText: string;
                name: string;
            }) => {
                return (
                    <AffinityGroup
                        key={id}
                        link={link}
                        logo={logo}
                        logoAltText={logoAltText}
                        name={name}
                    />
                );
            }
        );
    };

    return (
        <div>
            <Flex flexDirection="column" backgroundColor="white" style={{ padding: '16px' }}>
                <Logo logo={AffinityGroupsLogo} logoAltText={logoAlternativeText} />
                <Description title={descriptionTitle} content={descriptionContent} />
            </Flex>
            <Flex
                data-test="affinity-groups"
                flexDirection="column"
                alignItems="center"
                style={{ margin: '0 auto' }}
            >
                <AffinityGroupFAQs title={agFaqTitle} desc={agFaqDesc} />
                {getContent(affinityGroups)}
                <Link
                    style={{ textDecoration: 'none', width: '100%', margin: '8px 0' }}
                    to={`/affinity-groups/${AFFINITY_GROUPS_ADDITIONAL_PATH}`}
                >
                    <View
                        display="flex"
                        flexDirection="column"
                        border="none!important"
                        style={{
                            boxShadow: '0 0 1px gray',
                            padding: '16px',
                            margin: '8px 16px 8px 16px',
                            height: '119px',
                        }}
                        backgroundColor="white"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Text
                            style={{
                                wordBreak: 'break-all',
                                fontSize: '14px',
                                lineHeight: '22px',
                                fontWeight: '400',
                            }}
                            textAlign="center"
                            color="black"
                        >
                            {additionalGroupsTitle}
                        </Text>
                    </View>
                </Link>
            </Flex>
        </div>
    );
};
